import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { types } from './duck';

const InfoMessage: React.FC<types.InfoMessageProps> = ({
  textPartOne,
  textPartTwo,
  textPartThree,
}) => {
  return (
    <Flex
      justifyContent="center"
      flexDir="column"
      alignItems="center"
      w="100%"
      h="100%"
      bg="primary.700"
      px={6}
      py={8}
      textAlign="center"
    >
      <Text variant="n3" color="white">
        {textPartOne}
      </Text>
      <Text variant="n3" color="white" maxW="420px">
        {textPartTwo}
      </Text>
      <Text variant="n3" color="white" mt={6}>
        {textPartThree}
      </Text>
    </Flex>
  );
};

export default InfoMessage;
