import useAuth from '../use-auth';
import { isEmpty } from 'lodash-es';
import { useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { isDevMode } from '@lon/shared/constants';
import { AclApplication } from '@lon/shared/gql/types';
import {
  getLastApplicationByUserId,
  parseJSON,
  redirectToAvailableApplication,
  redirectToPriorityApplication,
} from '@lon/shared/utils';

const currentPath = window.location.pathname.split('/')?.[1];

export const useForwarder = ({
  acl,
  aclApplicationsLoading = true,
}: {
  aclApplicationsLoading: boolean;
  acl: AclApplication[];
}) => {
  const [auth] = useAuth();
  const applicationSettings = useSelector(
    (state: any) => state.applicationSettings
  );
  const defaultApplication = (
    parseJSON(applicationSettings?.preferences) as any
  )?.defaultApplication;
  const userId = auth?.user?.userId;

  useEffect(() => {
    if (window.location.pathname.includes('/suit/')) {
      const validPath = window.location.pathname.replace('/suit/', '/suite/');
      return window.location.replace(validPath);
    }
  }, []);

  useEffect(() => {
    if (
      currentPath &&
      ![
        'suite',
        'suit',
        'admin',
        'platform-admin',
        'mathnation',
        'login',
      ].includes(currentPath)
    ) {
      window.location.replace(
        isDevMode()
          ? `${process.env['NX_LOGIN_URL']}/not-found`!
          : '/login/not-found'
      );
    }
  }, []);

  useEffect(() => {
    const district = localStorage.getItem('district');

    if (
      !acl.length &&
      !aclApplicationsLoading &&
      (isEmpty(district) || (district && !currentPath))
    ) {
      window.location.replace(
        isDevMode() ? process.env['NX_LOGIN_URL']! : '/login'
      );
    }
  }, [aclApplicationsLoading, acl]);

  useLayoutEffect(() => {
    if (aclApplicationsLoading || currentPath || !acl.length || !userId) return;

    const application =
      getLastApplicationByUserId(userId) || defaultApplication;

    if (userId && application) {
      redirectToPriorityApplication(userId, application);
    } else if (!application) {
      redirectToAvailableApplication(acl);
    }
  }, [aclApplicationsLoading, userId]);
};
