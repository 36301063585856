import { FloridaLogin } from '../pages/florida-login';
import { TexasLogin } from '../pages/texas-login';
import React from 'react';
import { Route, createRoutesFromElements } from 'react-router-dom';
import { isFloridaReviewEnv } from '@lon/shared/constants';
import { ApplicationEnum } from '@lon/shared/enums';
import { ErrorBoundary } from '@lon/shared/pages';

const CurrentLoginPage = isFloridaReviewEnv() ? FloridaLogin : TexasLogin;

const ReviewSiteRouter = createRoutesFromElements(
  <Route errorElement={<ErrorBoundary application={ApplicationEnum.LOGIN} />}>
    <Route path="/login" element={<CurrentLoginPage />} />
    <Route path="*" element={<CurrentLoginPage />} />
  </Route>
);

export default ReviewSiteRouter;
