import { string } from "yup";
import { getValidationMessages } from "./getValidationMessages";
import { specialCharacters } from "@lon/shared/constants";

export const getReviewerPasswordValidation = (isPreview?: boolean) => {
  return string()
    .required(getValidationMessages().required)
    .matches(/^(?!.* )/, getValidationMessages().noSpaces)
    .min(
      isPreview ? 10 : 16,
      getValidationMessages(isPreview ? 10 : 16).passwordLength
    )
    .matches(/\d+/, getValidationMessages().atLeastOneDigit)
    .matches(/[a-z]+/, getValidationMessages().atLeastOneLowerCase)
    .matches(/[A-Z]+/, getValidationMessages().atLeastOneUpperCase)
    .matches(
      specialCharacters,
      getValidationMessages().atLeastOneSpecialCharacter
    );
};
