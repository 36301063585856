import { CreateToastFnReturn } from '@chakra-ui/react';
import { t } from 'i18next';

export const showCopyLinkNotification = (toast: CreateToastFnReturn) => {
  toast({
    title: t('common.linkCopied') as string,
    status: 'success',
    duration: 2000,
    isClosable: true,
  });
};
