import { extendTheme } from '@chakra-ui/react';
import '@fontsource/open-sans/600.css';
import {
  breakpoints,
  colors,
  fontSizes,
  getComponents,
  lineHeights,
  shadows,
} from '@lon/shared/assets';
import { ThemeEnum } from '@lon/shared/enums';

const theme = extendTheme({
  fonts: {
    heading: `'verdana' | 'report' | 'public-sans'`,
    body: `'verdana' | 'report' | 'public-sans'`,
  },
  colors,
  breakpoints,
  fontSizes,
  lineHeights,
  components: getComponents(ThemeEnum.DEFAULT),
  shadows: shadows,
});

export default theme;
