import UnauthorizedPageWrapper from '../unauthorized-page-wrapper';
import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import { Link as ChakraLink } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
import { ErrorMessage } from '@lon/shared/components';
import { isProdCluster } from '@lon/shared/constants';
import { arrDoubleLeftOutlined } from '@lon/shared/icons';
import { types } from './duck';

const UnauthorizedNotFound: React.FC<types.NotFoundProps> = ({
  title,
  description,
  actions,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (window.parent) {
      window.parent.postMessage({ type: 'page-not-found' });
    }
  }, []);

  return (
    <UnauthorizedPageWrapper>
      <ErrorMessage
        bodyWrapperProps={{
          gap: 6,
          padding: 0,
          alignSelf: 'normal',
          zIndex: 1,
        }}
        title={
          <Flex direction="column" gap={4}>
            <Text
              variant="h5"
              as="h1"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {title || <Trans i18nKey="errorMessage.404Body" />}
            </Text>
            {description || (
              <Text variant="n3" as="h2">
                <Text>
                  Sorry, we can not find the page you are looking for.
                </Text>
                <Text>
                  <ChakraLink
                    variant="primary"
                    href={
                      isProdCluster()
                        ? 'https://acceleratelearning.com/contact/'
                        : 'https://dev2019.stemscopes.com/tutorials'
                    }
                  >
                    Contact Support
                  </ChakraLink>{' '}
                  if you have questions.
                </Text>
              </Text>
            )}
          </Flex>
        }
        imageSrc="login/assets/error-404.png"
        actions={
          actions || (
            <Button
              onClick={() => {
                window.location.replace('/');
              }}
              title={t('errorMessage.goToHome')}
              variant="solid"
              leftIcon={<Icon as={arrDoubleLeftOutlined} />}
            >
              <Text as="span" isTruncated>
                {t('errorMessage.goToHome')}
              </Text>
            </Button>
          )
        }
      />
    </UnauthorizedPageWrapper>
  );
};

export default UnauthorizedNotFound;
