export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar type represents time data, represented as an ISO-8601 encoded UTC date string. */
  DateTime: any;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** The `Iterable` scalar type represents an array or a Traversable with any kind of data. */
  Iterable: any;
  JSON: any;
  JSONObject: any;
  /**
   * The `Upload` special type represents a file to be uploaded in the same HTTP request as specified by
   * [graphql-multipart-request-spec](https://github.com/jaydenseric/graphql-multipart-request-spec).
   */
  Upload: any;
  /** The `Void` scalar type represents no value being returned. */
  Void: any;
};

export type AcademicStandardInput = {
  currentSchoolId?: InputMaybe<Scalars['String']>;
  documentUuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  standard?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

export type AcademicStandardsPayload = {
  __typename?: 'AcademicStandardsPayload';
  adaptDate?: Maybe<Scalars['DateTime']>;
  child: Array<AcademicStandardsPayload>;
  description?: Maybe<Scalars['String']>;
  documentId: Scalars['String'];
  id: Scalars['Int'];
  isRoot: Scalars['Boolean'];
  standard: Scalars['String'];
};

export type Access = Node & {
  __typename?: 'Access';
  code: AccessesEnum;
  id: Scalars['ID'];
};

export enum AccessesEnum {
  All = 'ALL',
  CreateStudentAssignment = 'CREATE_STUDENT_ASSIGNMENT',
  GradeStudentAssignments = 'GRADE_STUDENT_ASSIGNMENTS',
  SeeGradebook = 'SEE_GRADEBOOK',
}

export type AccessPolicy = {
  __typename?: 'AccessPolicy';
  applications?: Maybe<Array<Application>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<ScopedPermission>;
};

export type AccessPolicyPageConnection = {
  __typename?: 'AccessPolicyPageConnection';
  collection: Array<AccessPolicy>;
  paginationInfo?: Maybe<AccessPolicyPaginationInfo>;
};

export type AccessPolicyPaginationInfo = {
  __typename?: 'AccessPolicyPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AccessPolicyPayload = {
  __typename?: 'AccessPolicyPayload';
  accessPolicy: AccessPolicy;
};

export type AclApplication = {
  __typename?: 'AclApplication';
  name: Scalars['String'];
  modules: Array<ApplicationModule>;
  schools: Array<ApplicationSchool>;
};

/** Activity params input for status reseting */
export type ActivityParamsInput = {
  activityId: Scalars['String'];
  studentId: Scalars['String'];
};

export enum ActivityTypeEnum {
  Assessment = 'ASSESSMENT',
  AssessmentPackageBox = 'ASSESSMENT_PACKAGE_BOX',
  BankQuestion = 'BANK_QUESTION',
  AssessmentBenchmark = 'ASSESSMENT_BENCHMARK',
}

export type AddLinkedVideoInput = {
  glossaryTerm?: InputMaybe<Scalars['String']>;
  groupIdentifier?: InputMaybe<Scalars['String']>;
  groupType: VideoGroupEnum;
  k5?: Scalars['Boolean'];
  videoId: Scalars['String'];
  videoIndex?: InputMaybe<Scalars['String']>;
};

export type AddPermissionInput = {
  id: Scalars['ID'];
  scopedPermission: ScopedPermissionInput;
};

export type AddQuestionsToVideoInput = {
  videoId: Scalars['String'];
  questionList: Array<Scalars['String']>;
};

/** AddSchoolTos a Student. */
export type AddSchoolToStudentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  schoolId: Scalars['String'];
  studentId: Scalars['String'];
};

/** AddSchoolTos a Student. */
export type AddSchoolToStudentPayload = {
  __typename?: 'addSchoolToStudentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** AddStudentsTos a Class. */
export type AddStudentsToClassInput = {
  classId: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  studentIds: Scalars['Iterable'];
};

/** AddStudentsTos a Class. */
export type AddStudentsToClassPayload = {
  __typename?: 'addStudentsToClassPayload';
  class?: Maybe<Class>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** AddStudentsTos a StudentSubgroup. */
export type AddStudentsToStudentSubgroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  studentIds: Scalars['Iterable'];
  subgroupId: Scalars['String'];
};

/** AddStudentsTos a StudentSubgroup. */
export type AddStudentsToStudentSubgroupPayload = {
  __typename?: 'addStudentsToStudentSubgroupPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  studentSubgroup?: Maybe<AddStudentsToStudentSubgroupPayloadData>;
};

/** AddStudentsTos a StudentSubgroup. */
export type AddStudentsToStudentSubgroupPayloadData = Node & {
  __typename?: 'addStudentsToStudentSubgroupPayloadData';
  _id: Scalars['String'];
  id: Scalars['ID'];
};

/** AddStudentTos a Classes. */
export type AddStudentToClassesInput = {
  classesIds: Scalars['Iterable'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  studentId: Scalars['String'];
};

/** AddStudentTos a Classes. */
export type AddStudentToClassesPayload = {
  __typename?: 'addStudentToClassesPayload';
  classes?: Maybe<AddStudentToClassesPayloadData>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** AddStudentTos a Classes. */
export type AddStudentToClassesPayloadData = Node & {
  __typename?: 'addStudentToClassesPayloadData';
  id: Scalars['ID'];
};

/** AddTeachersTos a StudentSubgroup. */
export type AddTeachersToStudentSubgroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  subgroupId: Scalars['String'];
  teacherIds: Scalars['Iterable'];
};

/** AddTeachersTos a StudentSubgroup. */
export type AddTeachersToStudentSubgroupPayload = {
  __typename?: 'addTeachersToStudentSubgroupPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  studentSubgroup?: Maybe<AddTeachersToStudentSubgroupPayloadData>;
};

/** AddTeachersTos a StudentSubgroup. */
export type AddTeachersToStudentSubgroupPayloadData = Node & {
  __typename?: 'addTeachersToStudentSubgroupPayloadData';
  _id: Scalars['String'];
  id: Scalars['ID'];
};

export type AdminKnowledgeTreeInput = {
  grade?: InputMaybe<Scalars['String']>;
  currentSchoolId?: InputMaybe<Scalars['String']>;
  isPlatformAdmin?: Scalars['Boolean'];
};

export type AdoptionAccount = {
  __typename?: 'AdoptionAccount';
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  beginDate?: Maybe<Scalars['DateTime']>;
  district: District;
  expirationDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  productGroups: Array<ProductGroup>;
  products: Array<Product>;
  registrationToken: Scalars['String'];
  school: School;
  state: StateEnum;
  subscriptionPackage: SubscriptionPackage;
  uiCustomization?: Maybe<Scalars['String']>;
  reviewers: Array<AdoptionReviewer>;
};

export type AdoptionAccountFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<AdoptionAccountOrderInput>;
  productIds?: InputMaybe<Array<Scalars['ID']>>;
  state?: InputMaybe<StateEnum>;
};

export type AdoptionAccountOrderInput = {
  active?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
  name?: InputMaybe<SortEnum>;
  state?: InputMaybe<SortEnum>;
};

export type AdoptionAccountPageConnection = {
  __typename?: 'AdoptionAccountPageConnection';
  collection: Array<AdoptionAccount>;
  paginationInfo?: Maybe<AdoptionAccountPaginationInfo>;
};

export type AdoptionAccountPaginationInfo = {
  __typename?: 'AdoptionAccountPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AdoptionAccountPayload = {
  __typename?: 'AdoptionAccountPayload';
  adoptionAccount: AdoptionAccount;
};

export type AdoptionEvent = {
  __typename?: 'AdoptionEvent';
  active: Scalars['Boolean'];
  beginDate?: Maybe<Scalars['DateTime']>;
  category: AdoptionEventCategoryEnum;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  productGroups: Array<ProductGroup>;
  products: Array<Product>;
  uiCustomization?: Maybe<Scalars['String']>;
  urlCode: Scalars['String'];
};

export enum AdoptionEventCategoryEnum {
  Mathnation = 'mathnation',
  Stemscopes = 'stemscopes',
}

export type AdoptionEventFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<AdoptionEventCategoryEnum>;
  productIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type AdoptionEventOrderInput = {
  active?: InputMaybe<SortEnum>;
  category?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
  name?: InputMaybe<SortEnum>;
};

export type AdoptionEventPageConnection = {
  __typename?: 'AdoptionEventPageConnection';
  collection: Array<AdoptionEvent>;
  paginationInfo?: Maybe<AdoptionEventPaginationInfo>;
};

export type AdoptionEventPaginationInfo = {
  __typename?: 'AdoptionEventPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AdoptionEventPayload = {
  __typename?: 'AdoptionEventPayload';
  adoptionEvent: AdoptionEvent;
};

export type AdoptionReviewer = {
  __typename?: 'AdoptionReviewer';
  adoptionAccount: AdoptionAccount;
  id: Scalars['ID'];
  user: User;
};

export type AdoptionReviewerOrderInput = {
  active?: InputMaybe<SortEnum>;
  adoptionAccountId?: InputMaybe<SortEnum>;
  email?: InputMaybe<SortEnum>;
  firstName?: InputMaybe<SortEnum>;
  lastName?: InputMaybe<SortEnum>;
  role?: InputMaybe<SortEnum>;
  username?: InputMaybe<SortEnum>;
};

export type AdoptionReviewerPageConnection = {
  __typename?: 'AdoptionReviewerPageConnection';
  collection: Array<AdoptionReviewer>;
  paginationInfo?: Maybe<AdoptionReviewerPaginationInfo>;
};

export type AdoptionReviewerPaginationInfo = {
  __typename?: 'AdoptionReviewerPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AdoptionReviewerPayload = {
  __typename?: 'AdoptionReviewerPayload';
  adoptionReviewer: AdoptionReviewer;
};

export enum AdoptionReviewerUserRoleEnum {
  AdoptionContentReviewer = 'adoption_content_reviewer',
  AdoptionReviewer = 'adoption_reviewer',
}

export type AiChatHistoryPayload = {
  __typename?: 'AiChatHistoryPayload';
  history: Array<AiChatMessage>;
};

export type AiChatInput = {
  query: Scalars['String'];
  scopeId?: InputMaybe<Scalars['Int']>;
  edition?: InputMaybe<Scalars['String']>;
  gradeLevel: Scalars['String'];
  clearHistory?: Scalars['Boolean'];
};

export type AiChatMessage = {
  __typename?: 'AiChatMessage';
  userContent: Scalars['String'];
  systemContent: Scalars['String'];
};

export type AiChatPayload = {
  __typename?: 'AiChatPayload';
  answer: Scalars['String'];
  historyContext?: Maybe<Scalars['String']>;
};

export enum ApiModeEnum {
  YearSpecific = 'YEAR_SPECIFIC',
  SharedInstance = 'SHARED_INSTANCE',
}

export type Application = {
  __typename?: 'Application';
  name: Scalars['String'];
  modules: Array<ApplicationModule>;
  split: Scalars['Boolean'];
};

export type ApplicationInput = {
  name: Scalars['String'];
  modules: Array<ApplicationModuleInput>;
  split?: InputMaybe<Scalars['Boolean']>;
};

export type ApplicationModule = {
  __typename?: 'ApplicationModule';
  name: Scalars['String'];
  operations: Array<ApplicationModuleOperation>;
  modules: Array<ApplicationModule>;
};

export type ApplicationModuleInput = {
  name: Scalars['String'];
  operations: Array<ApplicationModuleOperationInput>;
  modules?: InputMaybe<Array<ApplicationModuleInput>>;
};

export type ApplicationModuleOperation = {
  __typename?: 'ApplicationModuleOperation';
  name: Scalars['String'];
  scopes: Array<Scalars['String']>;
};

export type ApplicationModuleOperationInput = {
  name: Scalars['String'];
  scopes?: InputMaybe<Array<PermissionScope>>;
};

export type ApplicationSchool = {
  __typename?: 'ApplicationSchool';
  schoolIds: Array<Scalars['String']>;
  modules: Array<ApplicationModule>;
};

export type ApplicationSettings = Node & {
  __typename?: 'ApplicationSettings';
  id: Scalars['ID'];
  value: Scalars['Iterable'];
};

export enum ArchiveAssignmentAction {
  Archive = 'ARCHIVE',
  Unarchive = 'UNARCHIVE',
}

export enum ArchiveStatuses {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Both = 'BOTH',
}

export type AssessmentInput = {
  edgexlInput?: InputMaybe<EdgexlAssessmentInput>;
  stemscopesInput?: InputMaybe<StemscopesAssessmentInput>;
};

export type AssessmentPackageBoxPayload = {
  __typename?: 'AssessmentPackageBoxPayload';
  stemscopesPayload?: Maybe<StemscopesAssessmentPackageBoxPayload>;
};

export type AssessmentPayload = {
  __typename?: 'AssessmentPayload';
  edgexlPayload?: Maybe<EdgexlAssessmentPayload>;
  platformAdminPayload?: Maybe<PlatformAdminUpdateAssessmentPayload>;
  statusPayload?: Maybe<StatusPayload>;
  stemscopesPayload?: Maybe<StemscopesAssessmentPayload>;
};

export type AssessmentPoolInput = {
  edgexlInput?: InputMaybe<EdgexlAssessmentPoolInput>;
  platformAdminInput?: InputMaybe<PlatformAdminAssessmentPoolInput>;
  stemscopesInput?: InputMaybe<StemscopesAssessmentPoolInput>;
};

export type AssessmentPoolPayload = {
  __typename?: 'AssessmentPoolPayload';
  edgexlPayload?: Maybe<EdgexlAssessmentPoolPayload>;
  platformAdminPayload?: Maybe<PlatformAdminAssessmentPoolPayload>;
  stemscopesPayload?: Maybe<StemscopesAssessmentPoolPayload>;
};

/** Assessment views enum */
export enum AssessmentViewEnum {
  Teacher = 'teacher',
  Student = 'student',
}

/** Assignment Object Type */
export type Assignment = {
  __typename?: 'Assignment';
  archived?: Maybe<Scalars['Boolean']>;
  assessmentId?: Maybe<Scalars['String']>;
  assignmentId: Scalars['String'];
  assignmentListId: Scalars['String'];
  assignmentSource?: Maybe<AssignmentSource>;
  calculator?: Maybe<Calculator>;
  canBePaused?: Maybe<Scalars['Boolean']>;
  classId: Scalars['String'];
  endDate: Scalars['ISO8601Date'];
  immediatelyShareResults?: Maybe<ImmediatelyShareResults>;
  labels?: Maybe<Array<Scalars['String']>>;
  lateTurnIn?: Maybe<Scalars['Boolean']>;
  learnosityReferenceId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  originLearnosityId?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  releaseFeedback?: Maybe<Scalars['Boolean']>;
  schoolId?: Maybe<Scalars['String']>;
  startDate: Scalars['ISO8601Date'];
  status: AssignmentStatus;
  studentAssignments?: Maybe<Array<StudentAssignment>>;
  studentIds?: Maybe<Array<Scalars['String']>>;
  subgroupIds?: Maybe<Array<Scalars['String']>>;
};

/** AssigneesInput */
export type AssignmentAssigneesInput = {
  studentIds?: InputMaybe<Array<Scalars['String']>>;
  subgroups?: InputMaybe<Array<AssignmentSubgroupInput>>;
};

/** Class Input */
export type AssignmentClassInput = {
  id: Scalars['String'];
  studentIds?: InputMaybe<Array<Scalars['String']>>;
  subgroupIds?: InputMaybe<Array<Scalars['String']>>;
};

export type AssignmentDataPayload = {
  __typename?: 'AssignmentDataPayload';
  classId?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  learnosityActivityId: Scalars['String'];
  learnosityAssignmentId: Scalars['String'];
  modified: Scalars['String'];
  sessionUuid: Scalars['String'];
  status: Scalars['String'];
  studentUuid: Scalars['String'];
  teacherUuid: Scalars['String'];
  type: Scalars['String'];
  standard: Scalars['String'];
  displayName: Scalars['String'];
  domainName: Scalars['String'];
};

/** Attributes for filtering assignments by start/end date from/to */
export type AssignmentDateFilterInput = {
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
};

export enum AssignmentLearnosityRenderingType {
  Assess = 'assess',
  Inline = 'inline',
  Unspecified = 'unspecified',
}

export enum AssignmentLearnosityState {
  Initial = 'initial',
  Preview = 'preview',
  Resume = 'resume',
  Review = 'review',
  Unspecified = 'unspecified',
}

export enum AssignmentLearnosityType {
  Feedback = 'feedback',
  LocalPractice = 'local_practice',
  SubmitPractice = 'submit_practice',
  Unspecified = 'unspecified',
}

/** Assignments Object Type */
export type Assignments = {
  __typename?: 'Assignments';
  collection?: Maybe<Array<Assignment>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AssignmentsFilter = {
  assignmentListId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<Scalars['String']>>;
};

/** AssignmentSource Object Type */
export type AssignmentSource = {
  __typename?: 'AssignmentSource';
  assessment?: Maybe<AssignmentSourceAssessment>;
  element?: Maybe<Element>;
};

/** assignment source Assessment */
export type AssignmentSourceAssessment = {
  __typename?: 'AssignmentSourceAssessment';
  assessmentId: Scalars['String'];
};

/** assignment source AssessmentInput */
export type AssignmentSourceAssessmentInput = {
  assessmentId: Scalars['String'];
};

/** AssignmentSourceInput */
export type AssignmentSourceInput = {
  assessment?: InputMaybe<AssignmentSourceAssessmentInput>;
  element?: InputMaybe<ElementInput>;
  /** Use for all types of MN assignments */
  mnContentId?: InputMaybe<Scalars['String']>;
};

export enum AssignmentStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Paused = 'PAUSED',
}

/** SubgroupInput */
export type AssignmentSubgroupInput = {
  studentIds: Array<Scalars['String']>;
  subgroupId: Scalars['String'];
};

/** Assignment Union */
export type AssignmentUnion =
  | DistrictAssignmentType
  | EdgexlAssignmentType
  | LtiEdgexlAssignmentType
  | StemscopesAssignment;

export type AthenaDistrict = Node & {
  __typename?: 'athenaDistrict';
  _id: Scalars['String'];
  city: Scalars['String'];
  externalId: Scalars['String'];
  externalSystem: Scalars['String'];
  globalName: Scalars['String'];
  id: Scalars['ID'];
  mathnationId?: Maybe<Scalars['String']>;
  source: DistrictSourceEnum;
  state: Scalars['String'];
};

/** Page connection for athenaDistrict. */
export type AthenaDistrictPageConnection = {
  __typename?: 'athenaDistrictPageConnection';
  collection?: Maybe<Array<Maybe<AthenaDistrict>>>;
  paginationInfo: AthenaDistrictPaginationInfo;
};

/** Information about the pagination. */
export type AthenaDistrictPaginationInfo = {
  __typename?: 'athenaDistrictPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type AttachQuestionContainerInput = {
  platformAdminInput?: InputMaybe<PlatformAdminAttachQuestionContainerInput>;
};

export enum Audience {
  Admin = 'ADMIN',
  Leader = 'LEADER',
  Student = 'STUDENT',
  Teacher = 'TEACHER',
}

export enum AudienceEnum {
  Student = 'STUDENT',
  Teacher = 'TEACHER',
}

export type AuthorSignRequestServiceInput = {
  /** JSON string */
  config?: InputMaybe<Scalars['String']>;
};

export type BaseScope = {
  __typename?: 'BaseScope';
  contentGroups: Array<Scalars['String']>;
  displayName: Scalars['String'];
  editionName: Scalars['String'];
  elementTitles: Array<Scalars['String']>;
  id: Scalars['String'];
  image: Scalars['String'];
  internalName: Scalars['String'];
  jsonUrl: Scalars['String'];
  metadata: Scalars['JSON'];
  positionNumber: Scalars['Int'];
  projectId: Scalars['String'];
  projectType: Scalars['String'];
  sections: Array<Scalars['JSON']>;
  state: Scalars['String'];
  subscriptionContentGroups?: Maybe<Array<Scalars['String']>>;
  taxonomy: Array<Scalars['JSON']>;
};

export type BenchmarkInput = {
  currentSchoolId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

export type BenchmarkObjectsPayload = {
  __typename?: 'BenchmarkObjectsPayload';
  benchmarks: Array<BenchmarkPayload>;
};

export type BenchmarkPayload = {
  __typename?: 'BenchmarkPayload';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  standard: Scalars['String'];
  state: Scalars['String'];
  subject: Scalars['String'];
};

export type BulkUpdateContentsInput = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  projectIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Calculator Object Type */
export type Calculator = {
  __typename?: 'Calculator';
  fourFunction?: Maybe<Scalars['Boolean']>;
  grading?: Maybe<Scalars['Boolean']>;
  scientific?: Maybe<Scalars['Boolean']>;
};

/** Calculator Input */
export type CalculatorInput = {
  fourFunction?: InputMaybe<Scalars['Boolean']>;
  grading?: InputMaybe<Scalars['Boolean']>;
  scientific?: InputMaybe<Scalars['Boolean']>;
};

export type Calendar = Node & {
  __typename?: 'Calendar';
  _id: Scalars['String'];
  calendarEntries?: Maybe<EntryPageConnection>;
  calendarId?: Maybe<Scalars['String']>;
  calendarTemplateId?: Maybe<Scalars['String']>;
  calendarType?: Maybe<CalendarTypeEnum>;
  createTime?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  editable?: Maybe<Scalars['Boolean']>;
  eventId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pacingGuideId?: Maybe<Scalars['String']>;
  pacingGuideTemplateId?: Maybe<Scalars['String']>;
  pacingGuideType?: Maybe<PacingGuideTypeEnum>;
  pk: Scalars['String'];
  schoolIds?: Maybe<Scalars['Iterable']>;
  scopePlanId?: Maybe<Scalars['String']>;
  scopePlanType?: Maybe<ScopePlanTypeEnum>;
  searchName?: Maybe<Scalars['String']>;
  sharedBy?: Maybe<Scalars['String']>;
  sk: Scalars['String'];
  updateTime?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userRoleCode?: Maybe<Scalars['String']>;
  userType?: Maybe<UsersTypeEnum>;
  version?: Maybe<Scalars['Int']>;
};

export type CalendarCalendarEntriesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  entryType?: InputMaybe<EntryTypeEnum>;
  filter?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
};

export type CalendarFilter = {
  calendarId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  calendarType?: InputMaybe<CalendarTypeEnum>;
  endDate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startDate?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CalendarFilter_Order = {
  name?: InputMaybe<SortEnum>;
};

/** Page connection for Calendar. */
export type CalendarPageConnection = {
  __typename?: 'CalendarPageConnection';
  collection?: Maybe<Array<Maybe<Calendar>>>;
  paginationInfo: CalendarPaginationInfo;
};

/** Information about the pagination. */
export type CalendarPaginationInfo = {
  __typename?: 'CalendarPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type CalendarTemplateFilter = {
  calendarType?: InputMaybe<CalendarTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCode?: InputMaybe<Scalars['String']>;
};

/** Calendar type: Individual / Team / Template */
export enum CalendarTypeEnum {
  /** Individual Type */
  Individual = 'Individual',
  /** Team Type */
  Team = 'Team',
  /** Template Type */
  Template = 'Template',
}

/** Cancels a Impersonation. */
export type CancelImpersonationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Cancels a Impersonation. */
export type CancelImpersonationPayload = {
  __typename?: 'cancelImpersonationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  impersonation?: Maybe<CancelImpersonationPayloadData>;
};

/** Cancels a Impersonation. */
export type CancelImpersonationPayloadData = Node & {
  __typename?: 'cancelImpersonationPayloadData';
  id: Scalars['ID'];
  user?: Maybe<User>;
};

export type CanvasConfig = {
  __typename?: 'CanvasConfig';
  jsonEntry: Scalars['String'];
  redirectUrl: Scalars['String'];
};

export type CanvasSsoConfig = {
  __typename?: 'CanvasSsoConfig';
  xml: Scalars['String'];
};

export type ChatbotResponse = {
  __typename?: 'ChatbotResponse';
  answer?: Maybe<Scalars['String']>;
  threadId?: Maybe<Scalars['String']>;
};

export type ChatGptDistrictPayload = {
  __typename?: 'ChatGPTDistrictPayload';
  districtId?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  id: Scalars['String'];
};

export type Class = Node & {
  __typename?: 'Class';
  _id: Scalars['String'];
  archived: Scalars['Boolean'];
  classStudents?: Maybe<ClassStudentPageConnection>;
  classTeachers?: Maybe<ClassTeacherPageConnection>;
  curriculumArea?: Maybe<CurriculumAreaEnum>;
  gradeOrCourse?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  importId?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  lmsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  school: School;
  /** @deprecated Use the `school` property instead. */
  schoolId: Scalars['String'];
  status?: Maybe<StatusEnum>;
  studentCount: Scalars['Int'];
  subgroups?: Maybe<StudentSubgroupPageConnection>;
};

export type ClassClassStudentsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
};

export type ClassClassTeachersArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
};

export type ClassSubgroupsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

export type ClassFilter = {
  archived?: InputMaybe<Scalars['Boolean']>;
  hasLmsId?: InputMaybe<Scalars['Boolean']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ClassFilter_Order>>>;
  schoolId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<StatusEnum>;
  studentsId?: InputMaybe<Scalars['String']>;
  teachersId?: InputMaybe<Scalars['String']>;
};

export type ClassFilter_Order = {
  archived?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
  name?: InputMaybe<SortEnum>;
};

/** Attributes for creating stemscopes class other assignment */
export type ClassOtherAssignment = {
  classId: Scalars['String'];
  otherAssignment: OtherAssignmentInput;
};

/** Page connection for Class. */
export type ClassPageConnection = {
  __typename?: 'ClassPageConnection';
  collection?: Maybe<Array<Maybe<Class>>>;
  paginationInfo: ClassPaginationInfo;
};

/** Information about the pagination. */
export type ClassPaginationInfo = {
  __typename?: 'ClassPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type ClassStudent = Node & {
  __typename?: 'ClassStudent';
  _id: Scalars['String'];
  id: Scalars['ID'];
  student: Student;
  /** @deprecated Use the student property instead */
  studentId: Scalars['String'];
  subgroups?: Maybe<StudentSubgroupPageConnection>;
};

export type ClassStudentSubgroupsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
};

/** Page connection for ClassStudent. */
export type ClassStudentPageConnection = {
  __typename?: 'ClassStudentPageConnection';
  collection?: Maybe<Array<Maybe<ClassStudent>>>;
  paginationInfo: ClassStudentPaginationInfo;
};

/** Information about the pagination. */
export type ClassStudentPaginationInfo = {
  __typename?: 'ClassStudentPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type ClassTeacher = Node & {
  __typename?: 'ClassTeacher';
  _id: Scalars['String'];
  accesses: Scalars['Iterable'];
  id: Scalars['ID'];
  mainTeacher: Scalars['Boolean'];
  orderListStudents: Scalars['String'];
  subgroups?: Maybe<StudentSubgroupPageConnection>;
  teacher: Teacher;
  /** @deprecated Use the teacher property instead */
  teacherId: Scalars['String'];
};

export type ClassTeacherSubgroupsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
};

/** Page connection for ClassTeacher. */
export type ClassTeacherPageConnection = {
  __typename?: 'ClassTeacherPageConnection';
  collection?: Maybe<Array<Maybe<ClassTeacher>>>;
  paginationInfo: ClassTeacherPaginationInfo;
};

/** Information about the pagination. */
export type ClassTeacherPaginationInfo = {
  __typename?: 'ClassTeacherPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type CleverSchool = {
  __typename?: 'CleverSchool';
  id: Scalars['String'];
  name: Scalars['String'];
  number: Scalars['Int'];
  stateId?: Maybe<Scalars['String']>;
};

/** Completes a DistrictSetupStatus. */
export type CompleteDistrictSetupStatusInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Completes a DistrictSetupStatus. */
export type CompleteDistrictSetupStatusPayload = {
  __typename?: 'completeDistrictSetupStatusPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  districtSetupStatus?: Maybe<CompleteDistrictSetupStatusPayloadData>;
};

/** Completes a DistrictSetupStatus. */
export type CompleteDistrictSetupStatusPayloadData = Node & {
  __typename?: 'completeDistrictSetupStatusPayloadData';
  id: Scalars['ID'];
  status: SetupStatusEnum;
};

export enum ConsumerEnum {
  MathNation = 'MathNation',
  StemScopes = 'StemScopes',
}

export type ContainersVisibilityPayload = {
  __typename?: 'ContainersVisibilityPayload';
  visibilityPayload?: Maybe<VisibilityPayload>;
};

export type Content = {
  __typename?: 'Content';
  active: Scalars['Boolean'];
  grade?: Maybe<Scalars['String']>;
  editionName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['String']>;
  projectType: ProjectTypeEnum;
};

export type ContentGroup = {
  __typename?: 'ContentGroup';
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  contents: Array<Content>;
  /** Use only for merging data! */
  _id: Scalars['ID'];
};

export type ContentGroupFilterInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  id?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  contentIds?: InputMaybe<Array<Scalars['ID']>>;
  productType?: InputMaybe<ProductTypeEnum>;
};

export type ContentGroupMapping = {
  contentGroup: Scalars['String'];
  grade: Scalars['String'];
};

export type ContentGroupOrderInput = {
  active?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
};

export type ContentGroupPageConnection = {
  __typename?: 'ContentGroupPageConnection';
  collection: Array<ContentGroup>;
  paginationInfo?: Maybe<ContentGroupPaginationInfo>;
};

export type ContentGroupPaginationInfo = {
  __typename?: 'ContentGroupPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ContentGroupPayload = {
  __typename?: 'ContentGroupPayload';
  contentGroup: ContentGroup;
};

export type ContentGroupWithSchoolPayload = {
  __typename?: 'ContentGroupWithSchoolPayload';
  schoolId: Scalars['String'];
  contentGroups?: Maybe<Array<Scalars['String']>>;
};

export type ContentIdInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  projectId: Scalars['ID'];
};

/** Must be used for STEMscopes LTI 1.3 */
export type ContentItem = {
  elementId: Scalars['String'];
  elementView: ElementViewEnum;
  projectId: Scalars['String'];
  scopeId: Scalars['String'];
  sectionId: Scalars['String'];
  viewId: Scalars['String'];
};

export type ContentItemLinkData = {
  __typename?: 'ContentItemLinkData';
  fields: GpbJwt;
  ltiVersion: Scalars['String'];
  url: Scalars['String'];
};

export type ContentPageConnection = {
  __typename?: 'ContentPageConnection';
  collection: Array<Content>;
  paginationInfo?: Maybe<ContentPaginationInfo>;
};

export type ContentPaginationInfo = {
  __typename?: 'ContentPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ContentPayload = {
  __typename?: 'ContentPayload';
  content: Content;
};

export enum ControlActionEnum {
  Show = 'SHOW',
  Hide = 'HIDE',
}

export type ControlAssessmentInput = {
  platformAdminInput?: InputMaybe<PlatformAdminControlAssessmentInput>;
};

export type ControlContainerInput = {
  platformAdminInput?: InputMaybe<PlatformAdminControlContainerInput>;
};

export type CopyAssessmentInput = {
  edgexlInput?: InputMaybe<EdgexlCopyAssessmentInput>;
  stemscopesInput?: InputMaybe<StemscopesCopyAssessmentInput>;
};

export type CreateAccessPolicyInput = {
  applications?: InputMaybe<Array<ApplicationInput>>;
  name: Scalars['String'];
  permissions: Array<ScopedPermissionInput>;
};

export type CreateAdoptionAccountInput = {
  active: Scalars['Boolean'];
  beginDate?: InputMaybe<Scalars['DateTime']>;
  expirationDate?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  state: StateEnum;
  productGroupIds: Array<Scalars['ID']>;
  productIds: Array<Scalars['ID']>;
};

export type CreateAdoptionEventInput = {
  active: Scalars['Boolean'];
  beginDate?: InputMaybe<Scalars['DateTime']>;
  category: AdoptionEventCategoryEnum;
  expirationDate?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  uiCustomization?: InputMaybe<Scalars['String']>;
  urlCode: Scalars['String'];
  productGroupIds: Array<Scalars['ID']>;
  productIds: Array<Scalars['ID']>;
};

export type CreateAdoptionReviewerInput = {
  active: Scalars['Boolean'];
  adoptionAccountId: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  plainPassword: Scalars['String'];
  role: AdoptionReviewerUserRoleEnum;
  username: Scalars['String'];
};

export type CreateAssessmentInput = {
  edgexlInput?: InputMaybe<EdgexlCreateAssessmentInput>;
  stemscopesInput?: InputMaybe<StemscopesCreateAssessmentInput>;
};

export type CreateAssessmentPackageBoxInput = {
  stemscopesInput?: InputMaybe<StemscopesCreateAssessmentPackageBoxInput>;
};

/** Attributes for creating assignment */
export type CreateAssignmentInput = {
  assessmentId?: InputMaybe<Scalars['String']>;
  assignmentSource?: InputMaybe<AssignmentSourceInput>;
  calculator?: InputMaybe<CalculatorInput>;
  canBePaused?: InputMaybe<Scalars['Boolean']>;
  classes: Array<AssignmentClassInput>;
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  immediatelyShareResults?: InputMaybe<ImmediatelyShareResults>;
  labels: Array<Scalars['String']>;
  lateTurnIn: Scalars['Boolean'];
  learnosityReferenceId: Scalars['String'];
  name: Scalars['String'];
  notes: Scalars['String'];
  releaseFeedback: Scalars['Boolean'];
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
};

/** Creates a Calendar. */
export type CreateCalendarInput = {
  calendarId?: InputMaybe<Scalars['String']>;
  calendarTemplateId?: InputMaybe<Scalars['String']>;
  calendarType?: InputMaybe<CalendarTypeEnum>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  firstDayOfSchool?: InputMaybe<Scalars['Iterable']>;
  gradingPeriods?: InputMaybe<Scalars['Iterable']>;
  holidays?: InputMaybe<Scalars['Iterable']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  lastDayOfSchool?: InputMaybe<Scalars['Iterable']>;
  name?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  teamId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

/** Creates a Calendar. */
export type CreateCalendarPayload = {
  __typename?: 'createCalendarPayload';
  calendar?: Maybe<Calendar>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a Class. */
export type CreateClassInput = {
  archived?: Scalars['Boolean'];
  classTeachers?: InputMaybe<Array<InputMaybe<CreateClassTeacherNestedInput>>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  curriculumArea?: InputMaybe<CurriculumAreaEnum>;
  gradeOrCourse?: InputMaybe<Scalars['String']>;
  language?: Scalars['String'];
  lmsId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  schoolId: Scalars['String'];
  status?: InputMaybe<StatusEnum>;
};

/** Creates a Class. */
export type CreateClassPayload = {
  __typename?: 'createClassPayload';
  class?: Maybe<Class>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a ClassTeacher. */
export type CreateClassTeacherInput = {
  _id: Scalars['String'];
  accesses: Scalars['Iterable'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  mainTeacher?: Scalars['Boolean'];
  orderListStudents: Scalars['String'];
};

/** Creates a ClassTeacher. */
export type CreateClassTeacherNestedInput = {
  _id: Scalars['String'];
  accesses: Scalars['Iterable'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  mainTeacher?: Scalars['Boolean'];
  orderListStudents: Scalars['String'];
};

/** Creates a ClassTeacher. */
export type CreateClassTeacherPayload = {
  __typename?: 'createClassTeacherPayload';
  classTeacher?: Maybe<ClassTeacher>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateContentGroupInput = {
  active?: Scalars['Boolean'];
  id: Scalars['ID'];
  contents: Array<ContentIdInput>;
};

/** Creates a District. */
export type CreateDistrictInput = {
  city?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalSystem?: InputMaybe<Scalars['String']>;
  globalName: Scalars['String'];
  localName: Scalars['String'];
  mathNationId?: InputMaybe<Scalars['String']>;
  state: StateEnum;
};

/** Creates a District. */
export type CreateDistrictPayload = {
  __typename?: 'createDistrictPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  district?: Maybe<District>;
};

export type CreateEmailTemplateInput = {
  attachments: Array<InputMaybe<EmailTemplateAttachmentInput>>;
  body: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  fromEmail?: InputMaybe<Scalars['String']>;
  fromName?: InputMaybe<Scalars['String']>;
  source: Scalars['String'];
  subject: Scalars['String'];
  type: EmailTemplateTypeEnum;
};

/** Creates a Entry. */
export type CreateEntryInput = {
  calendarId: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  startDate?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EntryTypeEnum>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

/** Creates a Entry. */
export type CreateEntryPayload = {
  __typename?: 'createEntryPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  entry?: Maybe<Entry>;
};

export type CreateImageCategoryInput = {
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['ID']>;
};

export type CreateImageFileInput = {
  language?: InputMaybe<LanguageEnum>;
  originalFileName: Scalars['String'];
  path: Scalars['String'];
};

export type CreateImageInput = {
  altText?: InputMaybe<Scalars['String']>;
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  files: Array<CreateImageFileInput>;
  keywordIds?: InputMaybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  tagAssociations?: InputMaybe<Array<ImageTagAssociationInput>>;
};

export type CreateImageKeywordInput = {
  name: Scalars['String'];
};

export type CreateImageTagInput = {
  name: Scalars['String'];
};

export type CreateImageTagValueInput = {
  name: Scalars['String'];
  tagId: Scalars['ID'];
};

/** Creates a Impersonation. */
export type CreateImpersonationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

/** Creates a Impersonation. */
export type CreateImpersonationPayload = {
  __typename?: 'createImpersonationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  impersonation?: Maybe<CreateImpersonationPayloadData>;
};

/** Creates a Impersonation. */
export type CreateImpersonationPayloadData = Node & {
  __typename?: 'createImpersonationPayloadData';
  id: Scalars['ID'];
  user?: Maybe<CreateUserNestedPayload>;
};

/** Creates a PacingGuide. */
export type CreatePacingGuideInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  curriculumArea: Scalars['String'];
  grade: Scalars['String'];
  name: Scalars['String'];
  pacingGuideTemplateId?: InputMaybe<Scalars['String']>;
  pacingGuideType: PacingGuideTypeEnum;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  selectedCalendar?: InputMaybe<Scalars['Iterable']>;
  startDate?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

/** Creates a PacingGuide. */
export type CreatePacingGuidePayload = {
  __typename?: 'createPacingGuidePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  pacingGuide?: Maybe<PacingGuide>;
};

/** Creates a PasswordChange. */
export type CreatePasswordChangeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
  repeatedPassword: Scalars['String'];
};

/** Creates a PasswordChange. */
export type CreatePasswordChangePayload = {
  __typename?: 'createPasswordChangePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  passwordChange?: Maybe<CreatePasswordChangePayloadData>;
};

/** Creates a PasswordChange. */
export type CreatePasswordChangePayloadData = Node & {
  __typename?: 'createPasswordChangePayloadData';
  id: Scalars['ID'];
};

export type CreatePdfAssessmentInput = {
  /** Signed request for items to be included in PDF. */
  signedRequest: Scalars['String'];
  /** Assessment title. */
  title: Scalars['String'];
  /** Assessment view: teacher's with keys or student's. */
  view: AssessmentViewEnum;
  /** Print date inside PDF. */
  date?: InputMaybe<Scalars['String']>;
  /** Print variant number inside PDF. */
  variant?: InputMaybe<Scalars['String']>;
  /** Print only one question per page. Default = false. */
  printQuestionPerPage?: InputMaybe<Scalars['Boolean']>;
  /** image access token */
  imagesAccessToken?: InputMaybe<Scalars['String']>;
};

/** Creates a Post. */
export type CreatePostInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  comment: Scalars['String'];
  currentSchoolId: Scalars['String'];
  documentKey?: InputMaybe<Scalars['String']>;
  documentType?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  isSticky?: InputMaybe<Scalars['Boolean']>;
  parentId?: InputMaybe<Scalars['Int']>;
  wallId?: InputMaybe<Scalars['String']>;
};

/** Creates a Post. */
export type CreatePostPayload = {
  __typename?: 'createPostPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
};

export type CreatePreviewAccountInput = {
  activityPeriodEnd: Scalars['DateTime'];
  activityPeriodStart: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  state: StateEnum;
  username: Scalars['String'];
  productGroupIds: Array<Scalars['ID']>;
  productIds: Array<Scalars['ID']>;
};

export type CreatePreviewAccountsCsvPostObjectInput = {
  fileName: Scalars['String'];
};

export type CreateProductGroupInput = {
  name: Scalars['String'];
  productIds: Array<Scalars['ID']>;
};

export type CreateProductInput = {
  contentGroups?: InputMaybe<Array<Scalars['ID']>>;
  description: Scalars['String'];
  productType: ProductTypeEnum;
  scopeGroups?: InputMaybe<Array<Scalars['ID']>>;
  sku: Scalars['String'];
};

export type CreateQrcodeTemplateInput = {
  className?: InputMaybe<Scalars['String']>;
  users: Array<QrcodeUserData>;
  type: QrcodeRenderEnum;
};

export type CreateQuestionContainerInput = {
  platformAdminInput?: InputMaybe<PlatformAdminCreateQuestionContainerInput>;
};

export type CreateReaderLibraryInput = {
  title: Scalars['String'];
  enPdfFile?: InputMaybe<Scalars['Upload']>;
  esPdfFile?: InputMaybe<Scalars['Upload']>;
  description?: InputMaybe<Scalars['String']>;
};

/** Attributes for creating resource */
export type CreateResourceInput = {
  classes: Array<AssignmentClassInput>;
  gradeLevel?: InputMaybe<Scalars['String']>;
  labels: Array<Scalars['String']>;
  learnosityReferenceId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notes: Scalars['String'];
  resourceSource: ResourceSourceInput;
};

export type CreateRoleInput = {
  code?: InputMaybe<RoleCodeEnum>;
  name: Scalars['String'];
  policies: Array<Scalars['String']>;
};

/** Creates a School. */
export type CreateSchoolInput = {
  city?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  districtId: Scalars['String'];
  externalId?: InputMaybe<Scalars['String']>;
  externalSystem?: InputMaybe<Scalars['String']>;
  globalName: Scalars['String'];
  localName: Scalars['String'];
  mathNationId?: InputMaybe<Scalars['String']>;
  sisId?: InputMaybe<Scalars['String']>;
};

/** Creates a School. */
export type CreateSchoolPayload = {
  __typename?: 'createSchoolPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  school?: Maybe<School>;
};

/** Creates a ScopePlan. */
export type CreateScopePlanInput = {
  approachType?: InputMaybe<ScopePlanApproachEnum>;
  classSize?: InputMaybe<Scalars['Int']>;
  classTime?: InputMaybe<Scalars['Int']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  curriculumArea?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pacingGuideId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  scopePlanTemplateId?: InputMaybe<Scalars['String']>;
  scopePlanType: ScopePlanTypeEnum;
  scopes?: InputMaybe<Scalars['Iterable']>;
  teacherExp?: InputMaybe<Scalars['Int']>;
  teamId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

/** Creates a ScopePlan. */
export type CreateScopePlanPayload = {
  __typename?: 'createScopePlanPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  scopePlan?: Maybe<ScopePlan>;
};

export type CreateSignRequestInput = {
  authorService?: InputMaybe<AuthorSignRequestServiceInput>;
  itemsService?: InputMaybe<ItemsSignRequestRequestInput>;
};

export type CreateStemscopesMediaPackInput = {
  title: Scalars['String'];
  area?: StemscopesVideoAreaEnum;
  items: Array<CreateStemscopesMediaPostObjectInput>;
};

export type CreateStemscopesMediaPackPayload = {
  __typename?: 'CreateStemscopesMediaPackPayload';
  pack: StemscopesMediaPack;
  items: Array<StemscopesMediaPostObjectPayload>;
};

export type CreateStemscopesMediaPostObjectInput = {
  forceCreate?: Scalars['Boolean'];
  subtitles?: Scalars['Boolean'];
  subtitlesSrtFormat?: Scalars['Boolean'];
  language?: LanguageEnum;
  mediaType?: MediaTypeEnum;
  quality?: VideoQualityEnum;
  area?: StemscopesVideoAreaEnum;
  title: Scalars['String'];
  uniquePackTitle?: InputMaybe<Scalars['String']>;
};

export type CreateStreamingVideoPostObjectInput = {
  forceCreate?: Scalars['Boolean'];
  duration?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  language?: LanguageEnum;
  mediaType?: MediaTypeEnum;
  quality?: VideoQualityEnum;
  title: Scalars['String'];
};

export type CreateSubscriptionInput = {
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  productIds: Array<Scalars['ID']>;
  quantity: Scalars['Int'];
};

export type CreateSubscriptionPackageInput = {
  districtId?: InputMaybe<Scalars['String']>;
  schoolIds: Array<Scalars['ID']>;
  scope?: InputMaybe<SubscriptionScopeEnum>;
  subscriptionIds: Array<Scalars['ID']>;
};

export type CreateTeamInput = {
  context: TeamContextEnum;
  /** User IDs */
  members: Array<Scalars['ID']>;
  name: Scalars['String'];
};

export type CreateTutorInput = {
  depthScale: Scalars['Int'];
  firstName: Scalars['String'];
  initialTag: LanguageTagEnum;
  mainProfilePhoto: Scalars['Upload'];
  profilePhoto: Scalars['Upload'];
  bioText?: InputMaybe<Scalars['String']>;
  bioVideo?: InputMaybe<Scalars['Upload']>;
  lastName?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** Creates a User. */
export type CreateUserInput = {
  active?: Scalars['Boolean'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  curriculumAreas?: InputMaybe<Scalars['Iterable']>;
  districtUuid?: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalSystemName?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  gradeLevel?: InputMaybe<GradeLevelEnum>;
  language?: LanguageEnum;
  lastName: Scalars['String'];
  lmsId?: InputMaybe<Scalars['String']>;
  manualMaintenance?: InputMaybe<Scalars['Boolean']>;
  middleName?: InputMaybe<Scalars['String']>;
  plainPassword?: InputMaybe<Scalars['String']>;
  preferences?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  sisId?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  type: UserTypeEnum;
  userRoles?: InputMaybe<Array<InputMaybe<CreateUserRoleNestedInput>>>;
  userRolesExplicitDelete?: InputMaybe<
    Array<InputMaybe<CreateUserRoleNestedInput>>
  >;
  username: Scalars['String'];
};

/** Creates a User. */
export type CreateUserNestedPayload = Node & {
  __typename?: 'createUserNestedPayload';
  _id: Scalars['String'];
  active: Scalars['Boolean'];
  authenticationToken?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdByUsername?: Maybe<Scalars['String']>;
  curriculumAreas?: Maybe<Scalars['Iterable']>;
  district?: Maybe<District>;
  /** @deprecated Used from Session User */
  districtUuid: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  externalSystemName?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gradeLevel?: Maybe<GradeLevelEnum>;
  id: Scalars['ID'];
  importId?: Maybe<Scalars['String']>;
  inactivityReason?: Maybe<UserInactivityReasonEnum>;
  language: LanguageEnum;
  lastName: Scalars['String'];
  lmsId?: Maybe<Scalars['String']>;
  manualMaintenance?: Maybe<Scalars['Boolean']>;
  middleName?: Maybe<Scalars['String']>;
  plainPassword?: Maybe<Scalars['String']>;
  preferences?: Maybe<Scalars['String']>;
  sisId?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  type: UserTypeEnum;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedByUsername?: Maybe<Scalars['String']>;
  userRoles?: Maybe<CreateUserRoleNestedPayloadPageConnection>;
  username: Scalars['String'];
};

/** Creates a User. */
export type CreateUserPayload = {
  __typename?: 'createUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Creates a UserRole. */
export type CreateUserRoleInput = {
  allSchoolsAccess?: Scalars['Boolean'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  roleId?: Scalars['String'];
  schoolIds: Scalars['Iterable'];
};

/** Creates a UserRole. */
export type CreateUserRoleNestedInput = {
  allSchoolsAccess?: Scalars['Boolean'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  roleId?: Scalars['String'];
  schoolIds: Scalars['Iterable'];
};

/** Creates a UserRole. */
export type CreateUserRoleNestedPayload = Node & {
  __typename?: 'createUserRoleNestedPayload';
  allSchoolsAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  role: Role;
  /** @deprecated Use the Role property instead */
  roleId: Scalars['String'];
  schoolIds: Scalars['Iterable'];
};

/** Page connection for createUserRoleNestedPayload. */
export type CreateUserRoleNestedPayloadPageConnection = {
  __typename?: 'createUserRoleNestedPayloadPageConnection';
  collection?: Maybe<Array<Maybe<CreateUserRoleNestedPayload>>>;
  paginationInfo: CreateUserRoleNestedPayloadPaginationInfo;
};

/** Information about the pagination. */
export type CreateUserRoleNestedPayloadPaginationInfo = {
  __typename?: 'createUserRoleNestedPayloadPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

/** Creates a UserRole. */
export type CreateUserRolePayload = {
  __typename?: 'createUserRolePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  userRole?: Maybe<UserRole>;
};

export type CreateVideoPostObjectInput = {
  description?: InputMaybe<Scalars['String']>;
  fileName: Scalars['String'];
  forceCreate?: Scalars['Boolean'];
  k5?: Scalars['Boolean'];
  glossaryTerm?: InputMaybe<Scalars['String']>;
  groupIdentifier?: InputMaybe<Scalars['String']>;
  groupType: VideoGroupEnum;
  mediaType?: MediaTypeEnum;
  tutorId?: InputMaybe<Scalars['String']>;
  videoIndex?: InputMaybe<Scalars['String']>;
};

/** Creates a WallAgeControl. */
export type CreateWallAgeControlInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
  isVerified: Scalars['Boolean'];
  studentId: Scalars['String'];
};

/** Creates a WallAgeControl. */
export type CreateWallAgeControlPayload = {
  __typename?: 'createWallAgeControlPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  wallAgeControl?: Maybe<WallAgeControl>;
};

/** Creates a WallPostPin. */
export type CreateWallPostPinInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
  duration?: InputMaybe<Scalars['Int']>;
  isSticky: Scalars['Boolean'];
  postId: Scalars['String'];
};

/** Creates a WallPostPin. */
export type CreateWallPostPinPayload = {
  __typename?: 'createWallPostPinPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  wallPostPin?: Maybe<WallPostPin>;
};

export type CreateWidgetInput = {
  x: Scalars['Int'];
  y: Scalars['Int'];
  w: Scalars['Int'];
  h: Scalars['Int'];
  collapsed: Scalars['Boolean'];
  expandedH: Scalars['Int'];
  expandedW: Scalars['Int'];
  minH: Scalars['Int'];
  minW: Scalars['Int'];
  type: WidgetType;
  config?: InputMaybe<Scalars['JSONObject']>;
};

export enum CurriculumAreaEnum {
  Math = 'Math',
  Science = 'Science',
}

export type CurriculumAreas = {
  __typename?: 'CurriculumAreas';
  curriculumAreas?: Maybe<Array<Scalars['String']>>;
};

export type CyuQuestionContainerInput = {
  category: Scalars['String'];
  grade: Scalars['String'];
  lesson: Scalars['String'];
  unit: Scalars['String'];
};

export enum DaAssignType {
  GradeLevEl = 'GRADE_LEVEl',
  School = 'SCHOOL',
}

/** DefaultElementObject Type */
export type DefaultElement = {
  __typename?: 'DefaultElement';
  id: Scalars['String'];
  name: Scalars['String'];
  scopeId: Scalars['String'];
  studentView: Array<Scalars['JSON']>;
};

export type DeleteAdoptionAccountInput = {
  id: Scalars['ID'];
};

export type DeleteAdoptionEventInput = {
  id: Scalars['ID'];
};

export type DeleteAdoptionReviewerInput = {
  id: Scalars['ID'];
};

export type DeleteAssessmentInput = {
  edgexlInput?: InputMaybe<EdgexlDeleteAssessmentInput>;
  stemscopesInput?: InputMaybe<StemscopesDeleteAssessmentInput>;
};

/** Deletes a Class. */
export type DeleteClassInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a Class. */
export type DeleteClassPayload = {
  __typename?: 'deleteClassPayload';
  class?: Maybe<Class>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Deletes a District. */
export type DeleteDistrictInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a District. */
export type DeleteDistrictPayload = {
  __typename?: 'deleteDistrictPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  district?: Maybe<District>;
};

export type DeleteImageCategoryInput = {
  id: Scalars['ID'];
};

export type DeleteImageInput = {
  id: Scalars['ID'];
};

export type DeleteImageKeywordInput = {
  id: Scalars['ID'];
};

export type DeleteImageTagInput = {
  id: Scalars['ID'];
};

export type DeleteImageTagValueInput = {
  id: Scalars['ID'];
};

export type DeletePreviewAccountInput = {
  id: Scalars['ID'];
};

export type DeleteProductGroupInput = {
  id: Scalars['ID'];
};

export type DeleteProductInput = {
  id: Scalars['ID'];
};

/** Deletes a School. */
export type DeleteSchoolInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a School. */
export type DeleteSchoolPayload = {
  __typename?: 'deleteSchoolPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  school?: Maybe<School>;
};

export type DeleteStemscopesMediaPackPayload = {
  __typename?: 'DeleteStemscopesMediaPackPayload';
  pack: StemscopesMediaPack;
};

export type DeleteSubscriptionInput = {
  id: Scalars['ID'];
};

export type DeleteSubscriptionPackageInput = {
  id: Scalars['ID'];
};

export type DeleteTeacherAssignmentInput = {
  assignmentId: Scalars['Int'];
  currentSchoolId: Scalars['String'];
};

export type DeleteTeacherAssignmentPayload = {
  __typename?: 'DeleteTeacherAssignmentPayload';
  response: Scalars['String'];
};

/** Deletes a UserCalendar. */
export type DeleteUserCalendarInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a UserCalendar. */
export type DeleteUserCalendarPayload = {
  __typename?: 'deleteUserCalendarPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  userCalendar?: Maybe<UserCalendar>;
};

/** Deletes a User. */
export type DeleteUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a User. */
export type DeleteUserPayload = {
  __typename?: 'deleteUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type DeleteVideoInput = {
  groupIdentifier?: InputMaybe<Scalars['String']>;
  groupType: VideoGroupEnum;
  mediaType?: MediaTypeEnum;
  tutorId?: InputMaybe<Scalars['String']>;
  videoIndex?: InputMaybe<Scalars['String']>;
};

export type DeleteWidgetInput = {
  id: Scalars['String'];
};

export enum DeliveryEnum {
  Digital = 'DIGITAL',
  Paper = 'PAPER',
}

export type DigitalNotebookCover = {
  __typename?: 'DigitalNotebookCover';
  curriculumArea: Scalars['String'];
  gradeLevel: Scalars['String'];
  href: Scalars['String'];
  id: Scalars['String'];
  projectId: Scalars['String'];
  title: Scalars['String'];
};

export type DigitalNotebookCoverFilter = {
  curriculumArea?: InputMaybe<Array<Scalars['String']>>;
  gradeLevel?: InputMaybe<Array<Scalars['String']>>;
};

export type DigitalNotebookCovers = {
  __typename?: 'DigitalNotebookCovers';
  collection?: Maybe<Array<DigitalNotebookCover>>;
};

/** Use only for merging data! */
export type District = Node & {
  __typename?: 'District';
  id: Scalars['ID'];
  _id: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  externalSystem?: Maybe<Scalars['String']>;
  globalName: Scalars['String'];
  localName: Scalars['String'];
  mathNationId?: Maybe<Scalars['String']>;
  ncesId?: Maybe<Scalars['String']>;
  state: StateEnum;
};

/** Attributes for filtering district assignments by start/end date from/to */
export type DistrictAssignmentDateFilterInput = {
  from?: InputMaybe<Scalars['ISO8601Date']>;
  to?: InputMaybe<Scalars['ISO8601Date']>;
};

/** DA page info input */
export type DistrictAssignmentPageInfoInput = {
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type DistrictAssignmentsFilter = {
  teacherFilter?: InputMaybe<TeacherDistrictAssignmentsFilter>;
};

/** Params to start District Assignments */
export type DistrictAssignmentSignRequestInput = {
  referenceId: Scalars['String'];
  signItem?: InputMaybe<ItemSignRequestInput>;
};

/** Start District Assignment response type */
export type DistrictAssignmentSignRequestType = {
  __typename?: 'DistrictAssignmentSignRequestType';
  sessionId?: Maybe<Scalars['String']>;
  sign?: Maybe<Scalars['String']>;
};

/** Attributes for District Student Assignments share_results update */
export type DistrictAssignmentsShareResultsInput = {
  assignmentId: Scalars['String'];
  shareResults: ShareResults;
  studentIds: Array<Scalars['String']>;
};

/** District Assignment Object Type */
export type DistrictAssignmentType = {
  __typename?: 'DistrictAssignmentType';
  daId: Scalars['String'];
};

/** District Base Assignments Object Type */
export type DistrictBaseAssignmentsType = {
  __typename?: 'DistrictBaseAssignmentsType';
  collection?: Maybe<Array<DistrictBaseAssignmentType>>;
  pageInfo?: Maybe<PageInfo>;
};

/** District Base Assignment Object Type */
export type DistrictBaseAssignmentType = {
  __typename?: 'DistrictBaseAssignmentType';
  assignType?: Maybe<DaAssignType>;
  daId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  name?: Maybe<Scalars['String']>;
  schoolGradeLevels?: Maybe<Array<SchoolGradeLevels>>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
};

/** District Class Assignments Object Type */
export type DistrictClassAssignmentsType = {
  __typename?: 'DistrictClassAssignmentsType';
  collection: Array<DistrictClassAssignmentType>;
  pageInfo?: Maybe<PageInfo>;
};

/** District Class Assignment Object Type */
export type DistrictClassAssignmentType = {
  __typename?: 'DistrictClassAssignmentType';
  assessmentId: Scalars['String'];
  assignmentId: Scalars['String'];
  assignmentSource?: Maybe<AssignmentSource>;
  availableDate?: Maybe<Scalars['ISO8601Date']>;
  classId: Scalars['String'];
  daId: Scalars['String'];
  districtId: Scalars['String'];
  endDate: Scalars['ISO8601Date'];
  gradeLevel: Scalars['String'];
  name: Scalars['String'];
  originLearnosityId?: Maybe<Scalars['String']>;
  schoolId: Scalars['String'];
  startDate: Scalars['ISO8601Date'];
  studentAssignments?: Maybe<Array<DistrictStudentAssignmentType>>;
  teacherId: Scalars['String'];
};

/** Attributes for creating district assignment */
export type DistrictCreateInput = {
  assessmentId: Scalars['String'];
  assignType?: InputMaybe<DaAssignType>;
  assignmentSource?: InputMaybe<AssignmentSourceInput>;
  districtId: Scalars['String'];
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  name: Scalars['String'];
  schoolGradeLevels: Array<SchoolGradeLevelsInput>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
};

/** Contains string types to be used for filtering. */
export type DistrictFilter = {
  /** Filter by ID */
  id?: InputMaybe<Scalars['String']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Filter by name */
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<DistrictFilter_Order>>>;
  /** Filter by state */
  state?: InputMaybe<Scalars['String']>;
  /** Filter by source */
  source?: InputMaybe<DistrictSourceEnumFilterType>;
};

export type DistrictFilter_Order = {
  city?: InputMaybe<SortEnum>;
  globalName?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
  state?: InputMaybe<SortEnum>;
};

export type DistrictInput = {
  id: Scalars['String'];
};

/** Page connection for District. */
export type DistrictPageConnection = {
  __typename?: 'DistrictPageConnection';
  collection?: Maybe<Array<Maybe<District>>>;
  paginationInfo: DistrictPaginationInfo;
};

/** Information about the pagination. */
export type DistrictPaginationInfo = {
  __typename?: 'DistrictPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type DistrictSettingsInput = {
  teacherCanUsePerryBot: Scalars['Boolean'];
  studentCanUsePerryBot: Scalars['Boolean'];
};

export type DistrictSettingsKnowledgeBase = {
  __typename?: 'DistrictSettingsKnowledgeBase';
  studentCanUsePerryBot: Scalars['Boolean'];
  teacherCanUsePerryBot: Scalars['Boolean'];
};

export type DistrictSettingsLessonPlanner = Node & {
  __typename?: 'DistrictSettingsLessonPlanner';
  id: Scalars['ID'];
  teacherCanUseAiAssistedLessonPlan: Scalars['Boolean'];
};

export type DistrictSettingsRoster = Node & {
  __typename?: 'DistrictSettingsRoster';
  id: Scalars['ID'];
  teacherCanAddAdditionalTeacher: Scalars['Boolean'];
  teacherCanCreateClasses: Scalars['Boolean'];
  teacherCanEditClassRoster: Scalars['Boolean'];
};

export type DistrictSettingsUserManagement = Node & {
  __typename?: 'DistrictSettingsUserManagement';
  id: Scalars['ID'];
  teacherCanCreateStudents: Scalars['Boolean'];
  teacherCanEditStudentPassword: Scalars['Boolean'];
  teacherCanImpersonateStudents: Scalars['Boolean'];
};

export type DistrictSetupStatus = Node & {
  __typename?: 'DistrictSetupStatus';
  id: Scalars['ID'];
  status: SetupStatusEnum;
};

/** DistrictSort type: city / globalName / id / source / state by ASC or DESC */
export type DistrictSort = {
  /** sort by city asc or desc */
  city?: InputMaybe<SortEnum>;
  /** sort by globalName asc or desc */
  globalName?: InputMaybe<SortEnum>;
  /** sort by id asc or desc */
  id?: InputMaybe<SortEnum>;
  /** sort by source asc or desc */
  source?: InputMaybe<SortEnum>;
  /** sort by state asc or desc */
  state?: InputMaybe<SortEnum>;
};

export enum DistrictSourceEnum {
  Manual = 'MANUAL',
  Unspecified = 'UNSPECIFIED',
  OneRosterCsv = 'ONE_ROSTER_CSV',
  Clever = 'CLEVER',
  Classlink = 'CLASSLINK',
  EdFi = 'ED_FI',
  Mathnation = 'MATHNATION',
  Skyward = 'SKYWARD',
}

/** DistrictSourceEnum type: MANUAL, UNSPECIFIED, ONE_ROSTER_CSV, CLEVER, CLASSLINK, ED_FI, MATHNATION, SKYWARD */
export enum DistrictSourceEnumFilterType {
  Manual = 'MANUAL',
  Unspecified = 'UNSPECIFIED',
  OneRosterCsv = 'ONE_ROSTER_CSV',
  Clever = 'CLEVER',
  Classlink = 'CLASSLINK',
  EdFi = 'ED_FI',
  Mathnation = 'MATHNATION',
  Skyward = 'SKYWARD',
}

/** District Student Assignments Object Type */
export type DistrictStudentAssignmentsType = {
  __typename?: 'DistrictStudentAssignmentsType';
  collection: Array<DistrictStudentAssignmentType>;
  pageInfo?: Maybe<PageInfo>;
};

/** District Student Assignment Object Type */
export type DistrictStudentAssignmentType = {
  __typename?: 'DistrictStudentAssignmentType';
  answers?: Maybe<Scalars['JSON']>;
  assessmentId: Scalars['String'];
  assignmentId: Scalars['String'];
  availableDate?: Maybe<Scalars['ISO8601Date']>;
  classId: Scalars['String'];
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  daId: Scalars['String'];
  districtId: Scalars['String'];
  endDate: Scalars['ISO8601Date'];
  grade?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  learnosityReferenceId: Scalars['String'];
  name: Scalars['String'];
  reopenDate?: Maybe<Scalars['ISO8601Date']>;
  schoolId: Scalars['String'];
  shareResults?: Maybe<ShareResults>;
  startDate: Scalars['ISO8601Date'];
  startedAt?: Maybe<Scalars['ISO8601DateTime']>;
  status?: Maybe<StudentAssignmentStatus>;
  studentAvailability: Scalars['String'];
  studentId: Scalars['String'];
  studentNotes?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['ISO8601DateTime']>;
  teacherId: Scalars['String'];
};

export type DownloadTccPackage = {
  __typename?: 'DownloadTccPackage';
  downloadLink: Scalars['String'];
};

export type EdfiGradeLevel = {
  __typename?: 'EdfiGradeLevel';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type EdfiJobRole = {
  __typename?: 'EdfiJobRole';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type EdfiSchool = {
  __typename?: 'EdfiSchool';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type EdgePayload = {
  __typename?: 'EdgePayload';
  from: Scalars['Int'];
  to: Scalars['Int'];
};

export type EdgexlAssessmentInput = {
  field: FieldEnum;
  value: Scalars['String'];
  currentSchoolId: Scalars['String'];
};

export type EdgexlAssessmentItemPayload = {
  __typename?: 'EdgexlAssessmentItemPayload';
  status: Scalars['String'];
  tags: Array<TagPayload>;
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserPayload>;
  items: Array<Scalars['String']>;
};

export type EdgexlAssessmentPayload = {
  __typename?: 'EdgexlAssessmentPayload';
  item?: Maybe<EdgexlAssessmentItemPayload>;
};

export type EdgexlAssessmentPoolInput = {
  library: EdgexlLibraryEnum;
  sort?: InputMaybe<SortInput>;
  category?: InputMaybe<EdgexlCategoryEnum>;
  grade?: InputMaybe<EdgexlGradeEnum>;
  limit?: InputMaybe<Scalars['Int']>;
  units?: InputMaybe<Array<Scalars['String']>>;
  next?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type EdgexlAssessmentPoolPayload = {
  __typename?: 'EdgexlAssessmentPoolPayload';
  list: Array<EdgexlAssessmentItemPayload>;
  next: Scalars['String'];
};

export type EdgexlAssignmentProgressInfoType = {
  __typename?: 'EdgexlAssignmentProgressInfoType';
  activityId?: Maybe<Scalars['String']>;
  maxScore?: Maybe<Scalars['String']>;
  responses?: Maybe<Scalars['JSON']>;
  score?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Settings for edgexl assignment */
export type EdgexlAssignmentSettingsInput = {
  classesSettings: Array<EdgexlClassSettingsInput>;
  settings: EdgexlSettingsInput;
};

/** info about last attemption or all attemptions */
export type EdgexlAssignmentsProgressInfoInput = {
  activityId?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
};

export type EdgexlAssignmentsProgressInfoType = {
  __typename?: 'EdgexlAssignmentsProgressInfoType';
  collection?: Maybe<Array<EdgexlAssignmentProgressInfoType>>;
  pageInfo?: Maybe<PageInfo>;
};

/** Edgexl Assignments Object Type */
export type EdgexlAssignmentsType = {
  __typename?: 'EdgexlAssignmentsType';
  assignments: Array<EdgexlAssignmentType>;
  pageInfo?: Maybe<PageInfo>;
  studentAssignments?: Maybe<Array<EdgexlStudentAssignmentType>>;
};

/** Edgexl Assignment Object Type */
export type EdgexlAssignmentType = {
  __typename?: 'EdgexlAssignmentType';
  assessmentId?: Maybe<Scalars['String']>;
  assignmentId?: Maybe<Scalars['String']>;
  assignmentSettings?: Maybe<EdgexlSettingsType>;
  classesSettings?: Maybe<Scalars['JSON']>;
  schoolId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum EdgexlCategoryEnum {
  Fl = 'FL',
  Im = 'IM',
  Le = 'LE',
  Mf = 'MF',
  On = 'ON',
  Nl = 'NL',
  Sc = 'SC',
  Ms = 'MS',
}

/** Settings for class */
export type EdgexlClassSettingsInput = {
  classId: Scalars['String'];
  settings: EdgexlSettingsInput;
  students: Array<EdgexlStudentSettingsInput>;
};

export type EdgexlCopyAssessmentInput = {
  id: Scalars['ID'];
  currentSchoolId: Scalars['String'];
};

export type EdgexlCreateAssessmentInput = {
  attemptsNumber?: InputMaybe<Scalars['Int']>;
  deliveries: Array<DeliveryEnum>;
  isAllowViewCorrectAnswers?: InputMaybe<Scalars['Boolean']>;
  isAssigned?: Scalars['Boolean'];
  isRetakeOnlyIncorrect?: InputMaybe<Scalars['Boolean']>;
  isSameQuestionNumber?: InputMaybe<Scalars['Boolean']>;
  isScramble?: InputMaybe<Scalars['Boolean']>;
  items: Array<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  category?: InputMaybe<EdgexlCategoryEnum>;
  grade?: InputMaybe<EdgexlGradeEnum>;
  currentSchoolId: Scalars['String'];
};

/** Attributes for creating edgexl assignment */
export type EdgexlCreateInput = {
  assignmentSettings: EdgexlAssignmentSettingsInput;
  assignmentSource: AssignmentSourceInput;
  learnosityReferenceId: Scalars['String'];
  schoolId: Scalars['String'];
  title: Scalars['String'];
};

export type EdgexlDeleteAssessmentInput = {
  id: Scalars['ID'];
  currentSchoolId: Scalars['String'];
};

/** Attributes for editing edgexl assignment */
export type EdgexlEditInput = {
  assignmentId: Scalars['String'];
  assignmentSettings: EdgexlAssignmentSettingsInput;
};

export enum EdgexlGradeEnum {
  G01 = 'G01',
  G02 = 'G02',
  G03 = 'G03',
  G04 = 'G04',
  G05 = 'G05',
  G06 = 'G06',
  G6A = 'G6A',
  G07 = 'G07',
  G7A = 'G7A',
  G08 = 'G08',
  Ga1 = 'GA1',
  Ga2 = 'GA2',
  Gac = 'GAC',
  Ggo = 'GGO',
  Gkg = 'GKG',
  Gon = 'GON',
  Gp8 = 'GP8',
  Gpt = 'GPT',
  Gsa = 'GSA',
}

export enum EdgexlLibraryEnum {
  Individual = 'INDIVIDUAL',
  Mathnation = 'MATHNATION',
  District = 'DISTRICT',
  Others = 'OTHERS',
}

export type EdgexlQuestionContainerInput = {
  category: Scalars['String'];
  grade: Scalars['String'];
  unit: Scalars['String'];
};

export type EdgexlQuestionPoolInput = {
  category: EdgexlCategoryEnum;
  grade: EdgexlGradeEnum;
  units?: InputMaybe<Array<Scalars['String']>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  standards?: InputMaybe<Array<Scalars['String']>>;
  currentSchoolId: Scalars['String'];
};

/** Shared type for settings */
export type EdgexlSettingsInput = {
  attemptsNumber: Scalars['String'];
  endDate: Scalars['ISO8601DateTime'];
  notes: Scalars['String'];
  startDate: Scalars['ISO8601DateTime'];
};

/** Edgexl Settings Object Type */
export type EdgexlSettingsType = {
  __typename?: 'EdgexlSettingsType';
  attemptsNumber?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  notes?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
};

export type EdgexlShareAssessmentInput = {
  id: Scalars['ID'];
  currentSchoolId: Scalars['String'];
};

/** Edgexl Student Assignments Object Type */
export type EdgexlStudentAssignmentsType = {
  __typename?: 'EdgexlStudentAssignmentsType';
  collection?: Maybe<Array<EdgexlStudentAssignmentType>>;
  pageInfo?: Maybe<PageInfo>;
};

/** Edgexl Student Assignment Object Type */
export type EdgexlStudentAssignmentType = {
  __typename?: 'EdgexlStudentAssignmentType';
  assignmentId: Scalars['String'];
  classId?: Maybe<Scalars['String']>;
  completedAttemptsNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAllowViewCorrectAnswers?: Maybe<Scalars['String']>;
  isRetakeOnlyIncorrect?: Maybe<Scalars['String']>;
  isSameQuestionNumber?: Maybe<Scalars['String']>;
  isScramble?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Scalars['String']>>;
  learnosityReferenceId?: Maybe<Scalars['String']>;
  maxScore?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['String']>;
  studentSettings?: Maybe<EdgexlSettingsType>;
  submittedAt?: Maybe<Scalars['ISO8601DateTime']>;
  title?: Maybe<Scalars['String']>;
};

/** Student assignment settings */
export type EdgexlStudentSettingsInput = {
  settings: EdgexlSettingsInput;
  studentId: Scalars['String'];
};

export type EdgexlUpdateAssessmentInput = {
  id: Scalars['ID'];
  attemptsNumber?: InputMaybe<Scalars['Int']>;
  deliveries: Array<DeliveryEnum>;
  isAllowViewCorrectAnswers?: InputMaybe<Scalars['Boolean']>;
  isAssigned?: Scalars['Boolean'];
  isRetakeOnlyIncorrect?: InputMaybe<Scalars['Boolean']>;
  isSameQuestionNumber?: InputMaybe<Scalars['Boolean']>;
  isScramble?: InputMaybe<Scalars['Boolean']>;
  items: Array<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  category?: InputMaybe<EdgexlCategoryEnum>;
  grade?: InputMaybe<EdgexlGradeEnum>;
  currentSchoolId: Scalars['String'];
};

/** Edit Assignment Input */
export type EditAssignmentInput = {
  edgexlEditAssignmentInput: EdgexlEditInput;
};

/** Element Object Type */
export type Element = {
  __typename?: 'Element';
  elementId: Scalars['String'];
  elementName: Scalars['String'];
  projectId: Scalars['String'];
  scopeId: Scalars['String'];
  scopeName: Scalars['String'];
  sectionId: Scalars['String'];
  sectionName: Scalars['String'];
  viewId?: Maybe<Scalars['String']>;
};

export type ElementFilter = {
  curriculumArea?: InputMaybe<Array<Scalars['String']>>;
  gradeLevel?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Array<Scalars['String']>>;
  projectId?: InputMaybe<Scalars['String']>;
  resourceDefault?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
};

/** ElementInput */
export type ElementInput = {
  elementId: Scalars['String'];
  elementName: Scalars['String'];
  projectId: Scalars['String'];
  scopeId: Scalars['String'];
  scopeName: Scalars['String'];
  sectionId: Scalars['String'];
  sectionName: Scalars['String'];
  viewId: Scalars['String'];
};

export type Elements = {
  __typename?: 'Elements';
  collection?: Maybe<Array<SearchableElement>>;
  pageInfo?: Maybe<PageInfo>;
};

export enum ElementViewEnum {
  Student = 'STUDENT',
  Teacher = 'TEACHER',
}

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  id: Scalars['ID'];
  body: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fromEmail?: Maybe<Scalars['String']>;
  fromName?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  subject: Scalars['String'];
  type: EmailTemplateTypeEnum;
  attachments: Array<EmailTemplateAttachment>;
};

export type EmailTemplateAttachment = {
  __typename?: 'EmailTemplateAttachment';
  id: Scalars['ID'];
  emailTemplateId: Scalars['String'];
  originalFileName: Scalars['String'];
  downloadLink: Scalars['String'];
  path: Scalars['String'];
};

export type EmailTemplateAttachmentInput = {
  id: Scalars['String'];
  originalFileName: Scalars['String'];
  path: Scalars['String'];
};

export type EmailTemplateAttachmentUploadForm = {
  __typename?: 'EmailTemplateAttachmentUploadForm';
  id: Scalars['String'];
  fileName: Scalars['String'];
  formAttributes: Scalars['String'];
  formInputs: Scalars['String'];
};

export type EmailTemplateAttachmentUploadFormPayload = {
  __typename?: 'EmailTemplateAttachmentUploadFormPayload';
  collection: Array<EmailTemplateAttachmentUploadForm>;
};

export type EmailTemplateFilterInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  subject?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EmailTemplateTypeEnum>;
};

export type EmailTemplateImageUploadForm = {
  __typename?: 'EmailTemplateImageUploadForm';
  fileKey: Scalars['String'];
  formAttributes: Scalars['String'];
  formInputs: Scalars['String'];
  imageUrl: Scalars['String'];
};

export type EmailTemplatePageConnection = {
  __typename?: 'EmailTemplatePageConnection';
  collection: Array<EmailTemplate>;
  paginationInfo?: Maybe<EmailTemplatePaginationInfo>;
};

export type EmailTemplatePaginationInfo = {
  __typename?: 'EmailTemplatePaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type EmailTemplatePayload = {
  __typename?: 'EmailTemplatePayload';
  emailTemplate: EmailTemplate;
};

export type EmailTemplatePlaceholder = {
  __typename?: 'EmailTemplatePlaceholder';
  code: EmailTemplatePlaceholderEnum;
  exampleValue: Scalars['String'];
};

export enum EmailTemplatePlaceholderEnum {
  DistrictGlobalName = 'district_global_name',
  MfaCode = 'mfa_code',
  ParentRegistrationLink = 'parent_registration_link',
  Password = 'password',
  PreviewAccountActivityEndDate = 'preview_account_activity_end_date',
  PreviewAccountActivityStartDate = 'preview_account_activity_start_date',
  PreviewAccountId = 'preview_account_id',
  UserFirstName = 'user_first_name',
  UserLastName = 'user_last_name',
  Username = 'username',
  UserPasswordRecoveryApplication = 'user_password_recovery_application',
  UserPasswordRecoveryResetLink = 'user_password_recovery_reset_link',
  UserPasswordRecoveryResetLinkLifetimeHours = 'user_password_recovery_reset_link_lifetime_hours',
}

export type EmailTemplatePlaceholdersPayload = {
  __typename?: 'EmailTemplatePlaceholdersPayload';
  collection: Array<EmailTemplatePlaceholder>;
};

export enum EmailTemplateTypeEnum {
  AddChildToParent = 'addChildToParent',
  AdminMfa = 'adminMfa',
  PostReplyAdded = 'postReplyAdded',
  PreviewAccountActivityPeriodUpdated = 'previewAccountActivityPeriodUpdated',
  PreviewAccountCreated = 'previewAccountCreated',
  PreviewAccountFinished = 'previewAccountFinished',
  PreviewAccountStarted = 'previewAccountStarted',
  ParentRegistration = 'parentRegistration',
  UserPasswordRecovery = 'userPasswordRecovery',
}

export type EmbedUrlResponse = {
  __typename?: 'EmbedUrlResponse';
  EmbedUrl: Scalars['String'];
};

export type Entry = Node & {
  __typename?: 'Entry';
  _id: Scalars['String'];
  calendarId?: Maybe<Scalars['String']>;
  calendarType?: Maybe<CalendarTypeEnum>;
  createTime?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  editable?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  eventTemplateId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructionalDays?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  pacingGuideId?: Maybe<Scalars['String']>;
  pacingGuideTemplateId?: Maybe<Scalars['String']>;
  pacingGuideType?: Maybe<PacingGuideTypeEnum>;
  pk: Scalars['String'];
  schoolIds?: Maybe<Scalars['Iterable']>;
  scopeId?: Maybe<Scalars['String']>;
  scopePlanId?: Maybe<Scalars['String']>;
  scopePlanType?: Maybe<ScopePlanTypeEnum>;
  sharedBy?: Maybe<Scalars['String']>;
  sk: Scalars['String'];
  startDate: Scalars['String'];
  type: EntryTypeEnum;
  updateTime?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userRoleCode?: Maybe<Scalars['String']>;
  userType?: Maybe<UsersTypeEnum>;
  version?: Maybe<Scalars['Int']>;
};

export type EntryFilter = {
  endDate?: InputMaybe<Scalars['String']>;
  entryType?: InputMaybe<EntryTypeEnum>;
  startDate?: InputMaybe<Scalars['String']>;
};

/** Page connection for Entry. */
export type EntryPageConnection = {
  __typename?: 'EntryPageConnection';
  collection?: Maybe<Array<Maybe<Entry>>>;
  paginationInfo: EntryPaginationInfo;
};

/** Information about the pagination. */
export type EntryPaginationInfo = {
  __typename?: 'EntryPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

/** Entry types available */
export enum EntryTypeEnum {
  /** Entry type: Event */
  Event = 'Event',
  /** Entry type: First Day Of School */
  FirstDayOfSchool = 'FirstDayOfSchool',
  /** Entry type: Grading Period */
  GradingPeriod = 'GradingPeriod',
  /** Entry type: Holiday */
  Holiday = 'Holiday',
  /** Entry type: Last Day Of School */
  LastDayOfSchool = 'LastDayOfSchool',
  /** Entry type: Non Instructional Day */
  NonInstructionalDay = 'NonInstructionalDay',
  /** Entry type: Note */
  Note = 'Note',
  /** Entry type: Pacing Guide Scope */
  Scope = 'Scope',
  /** Entry type: Testing Day */
  TestingDay = 'TestingDay',
}

export type EventInput = {
  payload: Scalars['String'];
  type: Scalars['String'];
};

export type EventStatusPayload = {
  __typename?: 'EventStatusPayload';
  success: Scalars['Boolean'];
};

export enum ExternalFieldEnum {
  Email = 'EMAIL',
  Id = 'ID',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
}

export enum ExternalSystemNameEnum {
  SystemManual = 'SYSTEM_MANUAL',
  SystemOnerosterCsv = 'SYSTEM_ONEROSTER_CSV',
  SystemClever = 'SYSTEM_CLEVER',
  SystemClasslink = 'SYSTEM_CLASSLINK',
  SystemEdFi = 'SYSTEM_ED_FI',
  SystemMathnation = 'SYSTEM_MATHNATION',
}

export type ExtractOneRosterSchoolsInput = {
  districtId: Scalars['String'];
  file: Scalars['Upload'];
};

export enum FieldEnum {
  Title = 'TITLE',
  Id = 'ID',
}

export type FilterIconInput = {
  /** Used for retrieving specific icon information based in elementIconType */
  type?: InputMaybe<Scalars['String']>;
};

export type FilterTeamInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  membersActive?: InputMaybe<Scalars['Boolean']>;
};

export type FromMoveVideoInput = {
  groupIdentifier: Scalars['String'];
  tutorId?: InputMaybe<Scalars['String']>;
  videoIndex?: InputMaybe<Scalars['String']>;
};

export type GenerateQuestionResponse = {
  __typename?: 'GenerateQuestionResponse';
  jsonResponse?: Maybe<Scalars['String']>;
};

export type GetQuestionHintResponse = {
  __typename?: 'GetQuestionHintResponse';
  hint?: Maybe<Scalars['String']>;
};

export enum GlossaryFilterConditionEnum {
  Contain = 'CONTAIN',
  BeginWith = 'BEGIN_WITH',
}

export type GpbJwt = {
  __typename?: 'GpbJwt';
  JWT: Scalars['String'];
};

export enum GradeLevelEnum {
  GlPreK = 'gl_pre_k',
  GlK = 'gl_k',
  Gl_1 = 'gl_1',
  Gl_2 = 'gl_2',
  Gl_3 = 'gl_3',
  Gl_4 = 'gl_4',
  Gl_5 = 'gl_5',
  Gl_6 = 'gl_6',
  Gl_7 = 'gl_7',
  Gl_8 = 'gl_8',
  Gl_9 = 'gl_9',
  Gl_10 = 'gl_10',
  Gl_11 = 'gl_11',
  Gl_12 = 'gl_12',
}

export enum GradeLevelFilterEnum {
  GlUndefined = 'gl_undefined',
  GlPreK = 'gl_pre_k',
  GlK = 'gl_k',
  Gl_1 = 'gl_1',
  Gl_2 = 'gl_2',
  Gl_3 = 'gl_3',
  Gl_4 = 'gl_4',
  Gl_5 = 'gl_5',
  Gl_6 = 'gl_6',
  Gl_7 = 'gl_7',
  Gl_8 = 'gl_8',
  Gl_9 = 'gl_9',
  Gl_10 = 'gl_10',
  Gl_11 = 'gl_11',
  Gl_12 = 'gl_12',
}

export enum GradeLevels {
  /** 3-5 grade levels */
  Gl_35 = 'GL_35',
  /** 6-8(Middle School) grade levels */
  Gl_68 = 'GL_68',
  /** High School grade levels */
  GlHs = 'GL_HS',
  /** K-2 grade levels */
  GlK2 = 'GL_K2',
}

/** Available grade levels sorted by grades */
export type GradeLevelsByGrade = {
  __typename?: 'GradeLevelsByGrade';
  gl1?: Maybe<Array<GradeLevels>>;
  gl10?: Maybe<Array<GradeLevels>>;
  gl11?: Maybe<Array<GradeLevels>>;
  gl12?: Maybe<Array<GradeLevels>>;
  gl2?: Maybe<Array<GradeLevels>>;
  gl3?: Maybe<Array<GradeLevels>>;
  gl4?: Maybe<Array<GradeLevels>>;
  gl5?: Maybe<Array<GradeLevels>>;
  gl6?: Maybe<Array<GradeLevels>>;
  gl7?: Maybe<Array<GradeLevels>>;
  gl8?: Maybe<Array<GradeLevels>>;
  gl9?: Maybe<Array<GradeLevels>>;
  glk?: Maybe<Array<GradeLevels>>;
  glpk?: Maybe<Array<GradeLevels>>;
};

/** Available grade levels sorted by grades */
export type GradeLevelsByGradeInput = {
  gl1?: InputMaybe<Array<GradeLevels>>;
  gl10?: InputMaybe<Array<GradeLevels>>;
  gl11?: InputMaybe<Array<GradeLevels>>;
  gl12?: InputMaybe<Array<GradeLevels>>;
  gl2?: InputMaybe<Array<GradeLevels>>;
  gl3?: InputMaybe<Array<GradeLevels>>;
  gl4?: InputMaybe<Array<GradeLevels>>;
  gl5?: InputMaybe<Array<GradeLevels>>;
  gl6?: InputMaybe<Array<GradeLevels>>;
  gl7?: InputMaybe<Array<GradeLevels>>;
  gl8?: InputMaybe<Array<GradeLevels>>;
  gl9?: InputMaybe<Array<GradeLevels>>;
  glk?: InputMaybe<Array<GradeLevels>>;
  glpk?: InputMaybe<Array<GradeLevels>>;
};

export type Grades = {
  __typename?: 'Grades';
  grades: Array<Scalars['String']>;
};

/** Grading Session Type */
export type GradingSession = {
  __typename?: 'GradingSession';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum GradingTypeEnum {
  Auto = 'AUTO',
  Manual = 'MANUAL',
}

export type HelpPageInput = {
  audience: Audience;
};

export type HelpPageStructure = {
  __typename?: 'HelpPageStructure';
  audience: Audience;
  link: Scalars['String'];
};

export type Icon = {
  __typename?: 'Icon';
  id: Scalars['ID'];
  fileName: Scalars['String'];
  type: Scalars['String'];
  filePath: Scalars['String'];
  languageFilePath: Array<LanguageFilePath>;
  consumer: ConsumerEnum;
};

export type IconPageConnection = {
  __typename?: 'IconPageConnection';
  collection: Array<Icon>;
  paginationInfo?: Maybe<IconPaginationInfo>;
};

export type IconPaginationInfo = {
  __typename?: 'IconPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IconPayload = {
  __typename?: 'IconPayload';
  icon: Icon;
};

export type IdentifyUserFilter = {
  districtId?: InputMaybe<Scalars['String']>;
  lmsId?: InputMaybe<Scalars['String']>;
  sisId?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  altText?: Maybe<Scalars['String']>;
  categories: Array<ImageCategory>;
  createdAt: Scalars['DateTime'];
  files: Array<ImageFile>;
  id: Scalars['ID'];
  keywords: Array<ImageKeyword>;
  name: Scalars['String'];
  tagAssociations: Array<ImageTagAssociation>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImageCategory = {
  __typename?: 'ImageCategory';
  children: Array<ImageCategory>;
  id: Scalars['ID'];
  name: Scalars['String'];
  parent?: Maybe<ImageCategory>;
  path: Array<ImageCategoryPathPart>;
};

export type ImageCategoryFilterInput = {
  parentId?: InputMaybe<Scalars['ID']>;
};

export type ImageCategoryPageConnection = {
  __typename?: 'ImageCategoryPageConnection';
  collection: Array<ImageCategory>;
  paginationInfo?: Maybe<ImageCategoryPaginationInfo>;
};

export type ImageCategoryPaginationInfo = {
  __typename?: 'ImageCategoryPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ImageCategoryPathPart = {
  __typename?: 'ImageCategoryPathPart';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ImageCategoryPayload = {
  __typename?: 'ImageCategoryPayload';
  imageCategory: ImageCategory;
};

export type ImageFile = {
  __typename?: 'ImageFile';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  height?: Maybe<Scalars['Int']>;
  language?: Maybe<LanguageEnum>;
  mimeType?: Maybe<Scalars['String']>;
  originalFileName: Scalars['String'];
  path: Scalars['String'];
  processed: Scalars['Boolean'];
  sha256: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  width?: Maybe<Scalars['Int']>;
};

export type ImageFileUploadForm = {
  __typename?: 'ImageFileUploadForm';
  fileKey: Scalars['String'];
  formAttributes: Scalars['String'];
  formInputs: Scalars['String'];
  imageUrl: Scalars['String'];
};

export type ImageFilterInput = {
  language?: InputMaybe<LanguageEnum>;
  categoryId?: InputMaybe<Scalars['ID']>;
  keywordIds?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  tagAssociations?: InputMaybe<Array<ImageTagAssociationInput>>;
};

export type ImageKeyword = {
  __typename?: 'ImageKeyword';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ImageKeywordFilterInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type ImageKeywordPageConnection = {
  __typename?: 'ImageKeywordPageConnection';
  collection: Array<ImageKeyword>;
  paginationInfo?: Maybe<ImageKeywordPaginationInfo>;
};

export type ImageKeywordPaginationInfo = {
  __typename?: 'ImageKeywordPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ImageKeywordPayload = {
  __typename?: 'ImageKeywordPayload';
  imageKeyword: ImageKeyword;
};

export type ImagePageConnection = {
  __typename?: 'ImagePageConnection';
  collection: Array<Image>;
  paginationInfo?: Maybe<ImagePaginationInfo>;
};

export type ImagePaginationInfo = {
  __typename?: 'ImagePaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ImagePayload = {
  __typename?: 'ImagePayload';
  image: Image;
};

export type ImageTag = {
  __typename?: 'ImageTag';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ImageTagAssociation = {
  __typename?: 'ImageTagAssociation';
  id: Scalars['ID'];
  tag: ImageTag;
  values: Array<ImageTagValue>;
};

export type ImageTagAssociationInput = {
  tagId: Scalars['ID'];
  valueIds: Array<Scalars['ID']>;
};

export type ImageTagFilterInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type ImageTagPageConnection = {
  __typename?: 'ImageTagPageConnection';
  collection: Array<ImageTag>;
  paginationInfo?: Maybe<ImageTagPaginationInfo>;
};

export type ImageTagPaginationInfo = {
  __typename?: 'ImageTagPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ImageTagPayload = {
  __typename?: 'ImageTagPayload';
  imageTag: ImageTag;
};

export type ImageTagValue = {
  __typename?: 'ImageTagValue';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ImageTagValueFilterInput = {
  tagId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type ImageTagValuePageConnection = {
  __typename?: 'ImageTagValuePageConnection';
  collection: Array<ImageTagValue>;
  paginationInfo?: Maybe<ImageTagValuePaginationInfo>;
};

export type ImageTagValuePaginationInfo = {
  __typename?: 'ImageTagValuePaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ImageTagValuePayload = {
  __typename?: 'ImageTagValuePayload';
  imageTagValue: ImageTagValue;
};

export enum ImmediatelyShareResults {
  Disabled = 'DISABLED',
  WithoutCorrectAnswers = 'WITHOUT_CORRECT_ANSWERS',
  WithCorrectAnswers = 'WITH_CORRECT_ANSWERS',
}

export type ImportError = {
  __typename?: 'ImportError';
  id: Scalars['String'];
  message: Scalars['String'];
};

export type ImportErrorPageConnection = {
  __typename?: 'ImportErrorPageConnection';
  collection: Array<ImportError>;
  paginationInfo: ImportErrorPaginationInfo;
};

export type ImportErrorPaginationInfo = {
  __typename?: 'ImportErrorPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type ImportResult = {
  __typename?: 'ImportResult';
  endDate?: Maybe<Scalars['String']>;
  errorCount: Scalars['Int'];
  id: Scalars['String'];
  logs: Array<ImportResultLog>;
  statistics: Array<ImportStatistic>;
  source: IntegrationEnum;
  startDate: Scalars['String'];
  status: ImportStatusEnum;
};

export type ImportResultErrorFilterInput = {
  message?: InputMaybe<Scalars['String']>;
};

export type ImportResultErrorInput = {
  importResultId: Scalars['String'];
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<ImportResultErrorFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
};

export type ImportResultErrorsLink = {
  __typename?: 'ImportResultErrorsLink';
  url: Scalars['String'];
};

export type ImportResultErrorsLinkInput = {
  importResultId: Scalars['String'];
};

export type ImportResultInput = {
  id: Scalars['String'];
};

export type ImportResultLog = {
  __typename?: 'ImportResultLog';
  code: ImportStepCodeEnum;
  endDate?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  status: ImportStepStatusEnum;
};

export type ImportStatistic = {
  __typename?: 'ImportStatistic';
  changed: Scalars['Int'];
  notChanged: Scalars['Int'];
  total: Scalars['Int'];
  type: ImportStatisticTypeEnum;
};

export enum ImportStatisticTypeEnum {
  Staff = 'STAFF',
  Student = 'STUDENT',
}

export enum ImportStatusEnum {
  Completed = 'COMPLETED',
  CompletedWithWarnings = 'COMPLETED_WITH_WARNINGS',
  Error = 'ERROR',
  InQueue = 'IN_QUEUE',
  Started = 'STARTED',
}

export enum ImportStepCodeEnum {
  ArchiveExtractor = 'ARCHIVE_EXTRACTOR',
  CourseImporter = 'COURSE_IMPORTER',
  CourseOfferingImporter = 'COURSE_OFFERING_IMPORTER',
  CsvAcademicSessionImporter = 'CSV_ACADEMIC_SESSION_IMPORTER',
  CsvClassImporter = 'CSV_CLASS_IMPORTER',
  CsvCourseImporter = 'CSV_COURSE_IMPORTER',
  CsvEnrollmentImporter = 'CSV_ENROLLMENT_IMPORTER',
  CsvOrganizationImporter = 'CSV_ORGANIZATION_IMPORTER',
  CsvUserImporter = 'CSV_USER_IMPORTER',
  DataExporter = 'DATA_EXPORTER',
  DataRemover = 'DATA_REMOVER',
  DistrictAdminImporter = 'DISTRICT_ADMIN_IMPORTER',
  EtlJob = 'ETL_JOB',
  Exporter = 'EXPORTER',
  ImportFinalizer = 'IMPORT_FINALIZER',
  ImportInitializer = 'IMPORT_INITIALIZER',
  Initializer = 'INITIALIZER',
  OldDataRemover = 'OLD_DATA_REMOVER',
  RestAcademicSessionImporter = 'REST_ACADEMIC_SESSION_IMPORTER',
  RestClassImporter = 'REST_CLASS_IMPORTER',
  RestCourseImporter = 'REST_COURSE_IMPORTER',
  RestEnrollmentImporter = 'REST_ENROLLMENT_IMPORTER',
  RestOrganizationImporter = 'REST_ORGANIZATION_IMPORTER',
  RestUserImporter = 'REST_USER_IMPORTER',
  UserImporter = 'USER_IMPORTER',
  SectionImporter = 'SECTION_IMPORTER',
  SchoolImporter = 'SCHOOL_IMPORTER',
  StaffImporter = 'STAFF_IMPORTER',
  StaffRoleImporter = 'STAFF_ROLE_IMPORTER',
  StaffSchoolImporter = 'STAFF_SCHOOL_IMPORTER',
  StaffSectionImporter = 'STAFF_SECTION_IMPORTER',
  StudentImporter = 'STUDENT_IMPORTER',
  StudentSchoolImporter = 'STUDENT_SCHOOL_IMPORTER',
  StudentSectionImporter = 'STUDENT_SECTION_IMPORTER',
  TermImporter = 'TERM_IMPORTER',
}

export enum ImportStepStatusEnum {
  InProcess = 'IN_PROCESS',
  Success = 'SUCCESS',
  Error = 'ERROR',
}

/** Initiates a UserSelection. */
export type InitiateUserSelectionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  userId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

/** Initiates a UserSelection. */
export type InitiateUserSelectionPayload = {
  __typename?: 'initiateUserSelectionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  userSelection?: Maybe<UserSelection>;
};

export type InlineCss = {
  __typename?: 'InlineCss';
  id: Scalars['ID'];
  inlineCssContent: Scalars['String'];
  filePath: Scalars['String'];
};

export type InlineCssInput = {
  /** Path of the source file used for converting as Inline Css */
  filePath?: Scalars['String'];
  /** Raw HTML content of a entire page */
  html?: InputMaybe<Scalars['String']>;
  /** Css for the page to be applied as Inline Css */
  iframes: Array<Scalars['ID']>;
};

export type InlineCssPayload = {
  __typename?: 'InlineCssPayload';
  inlineCss: InlineCss;
};

/** Object of Post Input Request Type */
export type InputPostRequest = {
  /** ID of selected school */
  currentSchoolId: Scalars['String'];
  /** District Id of author user */
  districtId?: InputMaybe<Scalars['String']>;
  /** Id of post */
  id?: InputMaybe<Scalars['String']>;
  /** Get answered / unanswered posts */
  isAnswered?: InputMaybe<Scalars['Boolean']>;
  /** Get hidden / unhidden posts */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** Offset */
  offset?: InputMaybe<Scalars['Int']>;
  /** School Id of author user */
  schoolId?: InputMaybe<Scalars['String']>;
  /** Size */
  size?: InputMaybe<Scalars['Int']>;
  /** Two character state code of author user */
  stateCode?: InputMaybe<Scalars['String']>;
  /** User Id of post author */
  userId?: InputMaybe<Scalars['String']>;
  /** Array of Wall Ids */
  wallIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Object of Input Request Type */
export type InputRequest = {
  /** Config for the request */
  config?: InputMaybe<Scalars['String']>;
  /** Mode Inline or Assess */
  mode?: InputMaybe<ModeEnum>;
  /** Reference Id to be provided in the request */
  referenceId?: InputMaybe<Scalars['String']>;
  /** Type Activity or Item */
  type?: InputMaybe<TypeEnum>;
};

export type Integration = {
  __typename?: 'Integration';
  config?: Maybe<IntegrationConfig>;
  districtId: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  gradeMap: Array<IntegrationGradeMap>;
  integration: IntegrationEnum;
  roleMap: Array<IntegrationRoleMap>;
  schoolMap: Array<IntegrationSchoolMap>;
  settings?: Maybe<IntegrationSettings>;
  startDate?: Maybe<Scalars['String']>;
  status?: Maybe<ImportStatusEnum>;
  active: Scalars['Boolean'];
  autoSync: Scalars['Boolean'];
};

export type IntegrationConfig = {
  __typename?: 'IntegrationConfig';
  apiMode?: Maybe<ApiModeEnum>;
  baseUrl?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  sshConfigured?: Maybe<Scalars['Boolean']>;
  sshKey?: Maybe<Scalars['String']>;
  tokenUrl?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type IntegrationConfigInput = {
  baseUrl?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  sshConfigured?: InputMaybe<Scalars['Boolean']>;
  sshKey?: InputMaybe<Scalars['String']>;
  apiMode?: InputMaybe<ApiModeEnum>;
  year?: InputMaybe<Scalars['Int']>;
  tokenUrl?: InputMaybe<Scalars['String']>;
};

export enum IntegrationEnum {
  Classlink = 'CLASSLINK',
  Clever = 'CLEVER',
  EdFi = 'ED_FI',
  OneRosterCsv = 'ONE_ROSTER_CSV',
  Skyward = 'SKYWARD',
}

export type IntegrationGradeMap = {
  __typename?: 'IntegrationGradeMap';
  externalId: Scalars['String'];
  internalId?: Maybe<InternalGradeLevelEnum>;
  name?: Maybe<Scalars['String']>;
};

export type IntegrationGradeMapInput = {
  externalId: Scalars['String'];
  internalId?: InputMaybe<InternalGradeLevelEnum>;
  name?: InputMaybe<Scalars['String']>;
};

export type IntegrationInput = {
  districtId: Scalars['String'];
  integration: IntegrationEnum;
  config?: InputMaybe<IntegrationConfigInput>;
  schoolMap?: InputMaybe<Array<IntegrationSchoolMapInput>>;
  gradeMap?: InputMaybe<Array<IntegrationGradeMapInput>>;
  roleMap?: InputMaybe<Array<IntegrationRoleMapInput>>;
  settings?: InputMaybe<IntegrationSettingsInput>;
  autoSync?: InputMaybe<Scalars['Boolean']>;
  active?: InputMaybe<Scalars['Boolean']>;
};

export type IntegrationKeyInput = {
  districtId: Scalars['String'];
  integration: IntegrationEnum;
};

export type IntegrationRoleMap = {
  __typename?: 'IntegrationRoleMap';
  externalId: Scalars['String'];
  internalId?: Maybe<Scalars['String']>;
  mapToTitle?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  processRole?: Maybe<Scalars['Boolean']>;
};

export type IntegrationRoleMapInput = {
  externalId: Scalars['String'];
  internalId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  processRole?: InputMaybe<Scalars['Boolean']>;
  mapToTitle?: InputMaybe<Scalars['Boolean']>;
};

export type IntegrationSchoolMap = {
  __typename?: 'IntegrationSchoolMap';
  externalId: Scalars['String'];
  internalId?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  type?: Maybe<OneRosterSchoolTypeEnum>;
  status?: Maybe<OneRosterSchoolStatusEnum>;
};

export type IntegrationSchoolMapInput = {
  externalId: Scalars['String'];
  internalId?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<OneRosterSchoolTypeEnum>;
  status?: InputMaybe<OneRosterSchoolStatusEnum>;
};

export type IntegrationSettings = {
  __typename?: 'IntegrationSettings';
  ignoreTermDates: Scalars['Boolean'];
  passwordOverwriting: Scalars['Boolean'];
  unlistedClassesDeactivation: Scalars['Boolean'];
  unlistedUsersDeactivation: Scalars['Boolean'];
};

export type IntegrationSettingsInput = {
  ignoreTermDates?: InputMaybe<Scalars['Boolean']>;
  passwordOverwriting?: InputMaybe<Scalars['Boolean']>;
  unlistedClassesDeactivation?: InputMaybe<Scalars['Boolean']>;
  unlistedUsersDeactivation?: InputMaybe<Scalars['Boolean']>;
};

export type IntegrationsInput = {
  districtId?: InputMaybe<Scalars['String']>;
};

export enum InternalFieldEnum {
  Email = 'EMAIL',
  ExternalId = 'EXTERNAL_ID',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  LmsId = 'LMS_ID',
  StateId = 'STATE_ID',
  Username = 'USERNAME',
}

export type InternalFieldInput = {
  value: InternalFieldEnum;
  name?: InputMaybe<InternalFieldEnum>;
};

export enum InternalGradeLevelEnum {
  PreK = 'PRE_K',
  K = 'K',
  Level_1 = 'LEVEL_1',
  Level_2 = 'LEVEL_2',
  Level_3 = 'LEVEL_3',
  Level_4 = 'LEVEL_4',
  Level_5 = 'LEVEL_5',
  Level_6 = 'LEVEL_6',
  Level_7 = 'LEVEL_7',
  Level_8 = 'LEVEL_8',
  Level_9 = 'LEVEL_9',
  Level_10 = 'LEVEL_10',
  Level_11 = 'LEVEL_11',
  Level_12 = 'LEVEL_12',
}

/** Invites a Parent. */
export type InviteParentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  studentId: Scalars['String'];
};

/** Invites a Parent. */
export type InviteParentPayload = {
  __typename?: 'inviteParentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  parent?: Maybe<InviteParentPayloadData>;
};

/** Invites a Parent. */
export type InviteParentPayloadData = Node & {
  __typename?: 'inviteParentPayloadData';
  id: Scalars['ID'];
};

/** Item Signed Request type */
export type ItemSignedRequest = SignedRequestInterface & {
  __typename?: 'ItemSignedRequest';
  items?: Maybe<Array<Scalars['String']>>;
  message?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  renderingType?: Maybe<Scalars['String']>;
  signedRequest: Scalars['JSON'];
  state?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

/** Base Sign Request Input */
export type ItemSignRequestInput = {
  action?: InputMaybe<SignRequestActionInput>;
  config?: InputMaybe<Scalars['JSON']>;
  devDomain?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<Scalars['JSON']>>;
  name?: InputMaybe<Scalars['String']>;
  referenceId?: InputMaybe<Scalars['String']>;
  renderingType?: InputMaybe<AssignmentLearnosityRenderingType>;
  schoolId?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<SignAssignmentServices>;
  sessionId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<AssignmentLearnosityState>;
  type?: InputMaybe<AssignmentLearnosityType>;
};

export type ItemsSignRequestRequestInput = {
  items: Array<Scalars['String']>;
  /** JSON string */
  config?: InputMaybe<Scalars['String']>;
};

export type KarmaAward = Node & {
  __typename?: 'KarmaAward';
  _id: Scalars['Int'];
  created: Scalars['String'];
  districtId: Scalars['String'];
  id: Scalars['ID'];
  karma: Scalars['Int'];
  karmaTotal: Scalars['Int'];
  modified: Scalars['String'];
  queryTotal: Scalars['Int'];
  schoolId: Scalars['String'];
  schoolName: Scalars['String'];
  stateCode: Scalars['String'];
  subjectCode: Scalars['String'];
  user: Scalars['Iterable'];
  userId: Scalars['String'];
};

/** Page connection for KarmaAward. */
export type KarmaAwardPageConnection = {
  __typename?: 'KarmaAwardPageConnection';
  collection?: Maybe<Array<Maybe<KarmaAward>>>;
  paginationInfo: KarmaAwardPaginationInfo;
};

/** Information about the pagination. */
export type KarmaAwardPaginationInfo = {
  __typename?: 'KarmaAwardPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type KnowledgeTreeInput = {
  studentIdInput?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type KnowledgeTreePayload = {
  __typename?: 'KnowledgeTreePayload';
  edges: Array<EdgePayload>;
  standards: Array<StandardPayload>;
};

export enum LanguageEnum {
  En = 'en',
  Es = 'es',
}

export type LanguageFilePath = {
  __typename?: 'LanguageFilePath';
  languageCode: Scalars['ID'];
  filePath: Scalars['ID'];
};

export enum LanguageTagEnum {
  En = 'EN',
  Asl = 'ASL',
  Ht = 'HT',
  Pt = 'PT',
  Sp = 'SP',
}

export type LeaderDistrictAssignmentsFilter = {
  daId?: InputMaybe<Scalars['String']>;
  districtId: Scalars['String'];
  endDateFilter?: InputMaybe<DistrictAssignmentDateFilterInput>;
  gradeLevels?: InputMaybe<Array<Scalars['String']>>;
  schoolIds?: InputMaybe<Array<Scalars['String']>>;
  startDateFilter?: InputMaybe<DistrictAssignmentDateFilterInput>;
};

export type Lti = {
  __typename?: 'Lti';
  districtId: Scalars['String'];
  type: LtiTypeEnum;
  v1p1?: Maybe<LtiV1p1>;
  v1p3?: Maybe<LtiV1p3>;
};

/** Attributes for creating LTI STEMscopes assignment */
export type LtiCreateInput = {
  assignmentSource: AssignmentSourceInput;
  learnosityReferenceId: Scalars['String'];
  name: Scalars['String'];
  schoolId?: InputMaybe<Scalars['String']>;
};

/** LTI Edgexl Assignment Object Type */
export type LtiEdgexlAssignmentType = {
  __typename?: 'LtiEdgexlAssignmentType';
  assignmentId?: Maybe<Scalars['String']>;
  learnosityReferenceId?: Maybe<Scalars['String']>;
};

/** Attributes for creating LTI MathNation EdgeXl assignment */
export type LtiEdgeXlCreateInput = {
  assignmentId: Scalars['String'];
  assignmentSource: AssignmentSourceInput;
  gradeLevel: Scalars['String'];
  learnosityReferenceId: Scalars['String'];
  ownerId: Scalars['String'];
  schoolId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type LtiInput = {
  ltiKey?: InputMaybe<LtiKeyInput>;
  v1p3?: InputMaybe<LtiV1p3Input>;
};

export type LtiKeyInput = {
  districtId: Scalars['String'];
  type: LtiTypeEnum;
};

export type LtiListInput = {
  districtId?: InputMaybe<Scalars['String']>;
};

export enum LtiTypeEnum {
  CanvasV1P1 = 'CANVAS_V1P1',
  CanvasV1P3 = 'CANVAS_V1P3',
  ImsGlobalV1P3 = 'IMS_GLOBAL_V1P3',
  SafariMontageV1P1 = 'SAFARI_MONTAGE_V1P1',
  SafariMontageV1P3 = 'SAFARI_MONTAGE_V1P3',
  SchoologyV1P1 = 'SCHOOLOGY_V1P1',
  SchoologyV1P3 = 'SCHOOLOGY_V1P3',
}

export type LtiV1p1 = {
  __typename?: 'LtiV1p1';
  clientKey: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type LtiV1p3 = {
  __typename?: 'LtiV1p3';
  deploymentId: Scalars['String'];
  jwksUrl?: Maybe<Scalars['String']>;
};

export type LtiV1p3Input = {
  deploymentId: Scalars['String'];
  jwksUrl?: InputMaybe<Scalars['String']>;
};

/** Make Available District Assignment Input */
export type MakeAvailableDistrictAssignmentInput = {
  assignmentId: Scalars['String'];
  availableDate: Scalars['ISO8601Date'];
};

/** Manage District Assignment input */
export type ManageDistrictAssignmentInput = {
  assignmentId: Scalars['String'];
  studentIds: Array<Scalars['String']>;
};

export type MathNationGrades = {
  __typename?: 'MathNationGrades';
  grades: Array<Scalars['String']>;
};

export type MathNationScope = {
  __typename?: 'MathNationScope';
  active: Scalars['String'];
  category: Scalars['String'];
  contentAudience: AudienceEnum;
  contentGroups?: Maybe<Array<Scalars['String']>>;
  contentLocation: Scalars['String'];
  created: Scalars['String'];
  displayName: Scalars['String'];
  grade: Scalars['String'];
  id: Scalars['String'];
  lastUpdated: Scalars['String'];
  metadata: Scalars['JSON'];
  projectIdentifiers?: Maybe<Scalars['JSON']>;
  projectType: Scalars['String'];
  units: Array<Scalars['JSON']>;
};

export type MathNationScopeFilter = {
  category?: InputMaybe<Scalars['String']>;
  contentAudience?: InputMaybe<AudienceEnum>;
  grade?: InputMaybe<Scalars['String']>;
};

export type MathNationScopes = {
  __typename?: 'MathNationScopes';
  collection?: Maybe<Array<SearchableMathNationScope>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MediaPostObject = {
  __typename?: 'MediaPostObject';
  fileKey: Scalars['String'];
  formAttributes: Scalars['String'];
  formInputs: Scalars['String'];
};

export enum MediaTypeEnum {
  MediaTypeAudio = 'MEDIA_TYPE_AUDIO',
  MediaTypeAudioMp4 = 'MEDIA_TYPE_AUDIO_MP4',
  MediaTypePdf = 'MEDIA_TYPE_PDF',
  MediaTypeVideo = 'MEDIA_TYPE_VIDEO',
  MediaTypeCaption = 'MEDIA_TYPE_CAPTION',
  MediaTypeCaptionSrt = 'MEDIA_TYPE_CAPTION_SRT',
}

/** Must be used for MathNation LTI 1.1 or 1.3 */
export type MnContentItem = {
  componentId?: InputMaybe<Scalars['String']>;
  componentView: ElementViewEnum;
  lessonId: Scalars['String'];
  scopeId: Scalars['String'];
  unitId: Scalars['String'];
};

export type MnContentItemLinkData = {
  __typename?: 'MnContentItemLinkData';
  fields: Scalars['JSON'];
  ltiVersion: Scalars['String'];
  url: Scalars['String'];
};

export type MnCoursework = Node & {
  __typename?: 'mnCoursework';
  _id: Scalars['String'];
  id: Scalars['ID'];
  /** A token used for paginating through results. Pass this token in subsequent requests to retrieve the next page of results. */
  nextToken: Scalars['String'];
  resultSetJson: Scalars['String'];
};

export type MnCourseworkOrderType = {
  studentName?: InputMaybe<SortEnum>;
};

/** Must be used for MathNation LTI 1.3 */
export type MnEdgexl = {
  assessmentId: Scalars['String'];
  title: Scalars['String'];
};

/** MathNation LTI Input */
export type MnLtiContent = {
  courseworkAssignmentInput?: InputMaybe<Array<MnContentItem>>;
  edgexlAssignmentInput?: InputMaybe<Array<MnEdgexl>>;
};

export type MnOnRamp = Node & {
  __typename?: 'mnOnRamp';
  _id: Scalars['String'];
  id: Scalars['ID'];
  /** A token used for paginating through results. Pass this token in subsequent requests to retrieve the next page of results. */
  nextToken: Scalars['String'];
  resultSetJson: Scalars['String'];
};

export type MnOnRampOrderType = {
  studentName?: InputMaybe<SortEnum>;
};

/** Success Object Type */
export type MnPersonalAssignment = {
  __typename?: 'MnPersonalAssignment';
  activityId?: Maybe<Scalars['String']>;
  sign?: Maybe<Scalars['String']>;
};

export enum MnPersonalAssignmentType {
  Cyu = 'CYU',
  Or = 'OR',
  Ss = 'SS',
  Ty = 'TY',
}

/** One of the mode either inline or assess */
export enum ModeEnum {
  /** Inline Mode */
  Inline = 'inline',
  /** Assess Mode */
  Assess = 'assess',
}

export type MoveVideoInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  fromMovedInput: FromMoveVideoInput;
  glossaryTerm?: InputMaybe<Scalars['String']>;
  groupType: VideoGroupEnum;
  k5?: InputMaybe<Scalars['Boolean']>;
  mediaType?: InputMaybe<MediaTypeEnum>;
  toMovedInput: ToMoveVideoInput;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Archive assignments */
  archiveAssignments?: Maybe<Assignments>;
  /**
   * Creating Assignment
   * @deprecated Has been deprecated, use PutAssignment instead
   */
  createAssignment?: Maybe<Array<Assignment>>;
  /** Create Resource */
  createResource?: Maybe<Array<Resource>>;
  /** Get sign to start district assignment */
  daSignRequest?: Maybe<DistrictAssignmentSignRequestType>;
  /** Edit assignment */
  editAssignment?: Maybe<Success>;
  /** Edit stemscopes assignment */
  editStemscopesAssignment?: Maybe<Assignment>;
  /** Make Available District Assignment */
  makeAvailableDistrictAssignment?: Maybe<DistrictClassAssignmentType>;
  /** LTI. When a student opens the link, we must create or find the required activity and assignment */
  openLtiAssignmentLink?: Maybe<AssignmentUnion>;
  /** LTI. When a student opens the link, we must create or find the required activity and assignment */
  openLtiEdgexlAssignmentLink?: Maybe<AssignmentUnion>;
  /** Updating Activities share_results attribute */
  putActivitiesShareResults?: Maybe<Success>;
  /** Sign request */
  putAssignment?: Maybe<Array<AssignmentUnion>>;
  /** Updating District Student Assignments share_results attribute */
  putDistrictAssignmentsShareResults?: Maybe<DistrictStudentAssignmentsType>;
  /** PutDistrictStudentAssignments */
  putDistrictStudentAssignments?: Maybe<DistrictStudentAssignmentsType>;
  /** PutStudentAssignments */
  putStudentAssignments?: Maybe<StemscopesAssignment>;
  /** Reopen Assignment */
  reopenAssignment?: Maybe<Success>;
  /** Reopen District Assignment */
  reopenDistrictAssignment?: Maybe<DistrictStudentAssignmentsType>;
  /** Reset assignment */
  resetAssignment?: Maybe<Success>;
  /** Reset District Assignment */
  resetDistrictAssignment?: Maybe<DistrictStudentAssignmentsType>;
  /** Return assignment */
  returnAssignment?: Maybe<Success>;
  /** Return District Assignment */
  returnDistrictAssignment?: Maybe<DistrictStudentAssignmentsType>;
  /** Save Grading session */
  saveGradingSession?: Maybe<GradingSession>;
  /** Save students resources filter */
  saveStudentsResourcesFilters?: Maybe<StudentsResourcesSettings>;
  /** Sign Request. Add one of fragments: ReportSignedRequest, ItemSignedRequest */
  signRequest?: Maybe<SignedRequestUnion>;
  /** Start MN personal assignment */
  startMnPersonalAssignment?: Maybe<MnPersonalAssignment>;
  /** Reset reset and return statuses */
  startStemscopesAssignment?: Maybe<Success>;
  attachQuestionContainer: QuestionContainerPayload;
  controlAssessment: AssessmentPayload;
  controlContainer: AssessmentPayload;
  copyAssessment: AssessmentPayload;
  createAssessment: AssessmentPayload;
  createAssessmentPackageBox: AssessmentPackageBoxPayload;
  createQuestionContainer: QuestionContainerPayload;
  /** @deprecated Deprecated `$items` use `CreateSignRequestInput $input` instead */
  createSignRequest: SignRequestPayload;
  deleteAssessment: AssessmentPayload;
  shareAssessment: AssessmentPayload;
  updateAssessment: AssessmentPayload;
  bulkUpdateContents: Array<Content>;
  createContentGroup?: Maybe<ContentGroupPayload>;
  updateContent?: Maybe<ContentPayload>;
  updateContentGroup?: Maybe<ContentGroupPayload>;
  generateTcc: TccPackages;
  submitContentItemLinks: ContentItemLinkData;
  submitMnContentItemLinksV11: MnContentItemLinkData;
  submitMnContentItemLinksV13: ContentItemLinkData;
  /** A placeholder query used by thecodingmachine/graphqlite when there are no declared mutations. */
  dummyMutation?: Maybe<Scalars['String']>;
  createWidget: Widget;
  updateWidget: Widget;
  updateWidgets: Array<Maybe<Widget>>;
  deleteWidget: Widget;
  createAdoptionAccount: AdoptionAccountPayload;
  createAdoptionEvent: AdoptionEventPayload;
  createAdoptionReviewer: AdoptionReviewerPayload;
  createPreviewAccount: PreviewAccountPayload;
  createPreviewAccountsCsvPostObject: PreviewAccountsCsvPostObjectPayload;
  createProductGroup: ProductGroupPayload;
  deleteAdoptionAccount: AdoptionAccountPayload;
  deleteAdoptionEvent: AdoptionEventPayload;
  deleteAdoptionReviewer: AdoptionReviewerPayload;
  deletePreviewAccount: PreviewAccountPayload;
  deleteProductGroup: ProductGroupPayload;
  resetPreviewAccountActivityPeriodEnd?: Maybe<Scalars['Void']>;
  updateAdoptionAccount: AdoptionAccountPayload;
  updateAdoptionEvent: AdoptionEventPayload;
  updateAdoptionReviewer: AdoptionReviewerPayload;
  updatePreviewAccount: PreviewAccountPayload;
  updatePreviewAccountTeacher: PreviewAccountTeacherPayload;
  updateProductGroup: ProductGroupPayload;
  addPermission: SuccessPayload;
  createAccessPolicy: AccessPolicyPayload;
  createRole: RolePayload;
  deleteAccessPolicy: AccessPolicyPayload;
  deleteRole: RolePayload;
  removePermission: SuccessPayload;
  updateAccessPolicy: AccessPolicyPayload;
  updateRole: RolePayload;
  convertInlineCss: InlineCssPayload;
  scopePrintDownload: ScopePrintDownloadPayload;
  createImage: ImagePayload;
  createImageCategory: ImageCategoryPayload;
  createImageKeyword: ImageKeywordPayload;
  createImageTag: ImageTagPayload;
  createImageTagValue: ImageTagValuePayload;
  deleteImage: ImagePayload;
  deleteImageCategory: ImageCategoryPayload;
  deleteImageKeyword: ImageKeywordPayload;
  deleteImageTag: ImageTagPayload;
  deleteImageTagValue: ImageTagValuePayload;
  updateImage: ImagePayload;
  updateImageCategory: ImageCategoryPayload;
  updateImageKeyword: ImageKeywordPayload;
  updateImageTag: ImageTagPayload;
  updateImageTagValue: ImageTagValuePayload;
  createIntegration: Integration;
  deleteIntegration: Integration;
  extractOneRosterSchools: Array<OneRosterSchool>;
  forcePullIntegration: Integration;
  forceUploadOneRosterFile: Scalars['String'];
  pullIntegration: Integration;
  syncSchools: Integration;
  updateIntegration: Integration;
  uploadOneRosterFile: Scalars['String'];
  /** Creates a Calendar. */
  createCalendar?: Maybe<CreateCalendarPayload>;
  /** Creates a Entry. */
  createEntry?: Maybe<CreateEntryPayload>;
  /** Creates a PacingGuide. */
  createPacingGuide?: Maybe<CreatePacingGuidePayload>;
  /** Creates a ScopePlan. */
  createScopePlan?: Maybe<CreateScopePlanPayload>;
  /** Deletes a UserCalendar. */
  deleteUserCalendar?: Maybe<DeleteUserCalendarPayload>;
  /** Initiates a UserSelection. */
  initiateUserSelection?: Maybe<InitiateUserSelectionPayload>;
  /** Removes a Calendar. */
  removeCalendar?: Maybe<RemoveCalendarPayload>;
  /** Removes a Entry. */
  removeEntry?: Maybe<RemoveEntryPayload>;
  /** Removes a PacingGuide. */
  removePacingGuide?: Maybe<RemovePacingGuidePayload>;
  /** Removes a ScopePlan. */
  removeScopePlan?: Maybe<RemoveScopePlanPayload>;
  /** Shares a Calendar. */
  shareCalendar?: Maybe<ShareCalendarPayload>;
  /** ShareTemplates a PacingGuide. */
  shareTemplatePacingGuide?: Maybe<ShareTemplatePacingGuidePayload>;
  /** ShareTemplates a ScopePlan. */
  shareTemplateScopePlan?: Maybe<ShareTemplateScopePlanPayload>;
  /** Updates a Calendar. */
  updateCalendar?: Maybe<UpdateCalendarPayload>;
  /** Updates a DistrictSettingsLessonPlanner. */
  updateDistrictSettingsLessonPlanner?: Maybe<UpdateDistrictSettingsLessonPlannerPayload>;
  /** Updates a Entry. */
  updateEntry?: Maybe<UpdateEntryPayload>;
  /** Updates a PacingGuide. */
  updatePacingGuide?: Maybe<UpdatePacingGuidePayload>;
  /** Updates a PacingGuideScope. */
  updatePacingGuideScope?: Maybe<UpdatePacingGuideScopePayload>;
  /** Updates a ScopePlan. */
  updateScopePlan?: Maybe<UpdateScopePlanPayload>;
  /** Updates a ScopePlanScope. */
  updateScopePlanScope?: Maybe<UpdateScopePlanScopePayload>;
  /** Updates a UserCalendar. */
  updateUserCalendar?: Maybe<UpdateUserCalendarPayload>;
  /** Updates a UserSelection. */
  updateUserSelection?: Maybe<UpdateUserSelectionPayload>;
  /** Completes a DistrictSetupStatus. */
  completeDistrictSetupStatus?: Maybe<CompleteDistrictSetupStatusPayload>;
  /** Creates a District. */
  createDistrict?: Maybe<CreateDistrictPayload>;
  /** Creates a School. */
  createSchool?: Maybe<CreateSchoolPayload>;
  /** Deletes a District. */
  deleteDistrict?: Maybe<DeleteDistrictPayload>;
  /** Deletes a School. */
  deleteSchool?: Maybe<DeleteSchoolPayload>;
  /** Updates a District. */
  updateDistrict?: Maybe<UpdateDistrictPayload>;
  /** Updates a School. */
  updateSchool?: Maybe<UpdateSchoolPayload>;
  createPasswordRecovery: Scalars['Boolean'];
  createPasswordRecoveryRequest: Scalars['Boolean'];
  registerAdoptionReviewer: Scalars['Boolean'];
  registerParent?: Maybe<PublicParent>;
  registerPreviewAccountTeacher: PublicPreviewAccountTeacher;
  /** AddStudentTos a Classes. */
  addStudentToClasses?: Maybe<AddStudentToClassesPayload>;
  /** AddStudentsTos a Class. */
  addStudentsToClass?: Maybe<AddStudentsToClassPayload>;
  /** AddStudentsTos a StudentSubgroup. */
  addStudentsToStudentSubgroup?: Maybe<AddStudentsToStudentSubgroupPayload>;
  /** AddTeachersTos a StudentSubgroup. */
  addTeachersToStudentSubgroup?: Maybe<AddTeachersToStudentSubgroupPayload>;
  /** Creates a Class. */
  createClass?: Maybe<CreateClassPayload>;
  /**
   * Creates a ClassTeacher.
   * @deprecated Use nested mutation
   */
  createClassTeacher?: Maybe<CreateClassTeacherPayload>;
  /** Deletes a Class. */
  deleteClass?: Maybe<DeleteClassPayload>;
  /**
   * Removes a ClassTeacher.
   * @deprecated Use nested mutation
   */
  removeClassTeacher?: Maybe<RemoveClassTeacherPayload>;
  /** RemoveStudentFroms a Classes. */
  removeStudentFromClasses?: Maybe<RemoveStudentFromClassesPayload>;
  /** RemoveStudentsFroms a Class. */
  removeStudentsFromClass?: Maybe<RemoveStudentsFromClassPayload>;
  /** RemoveStudentsFroms a StudentSubgroup. */
  removeStudentsFromStudentSubgroup?: Maybe<RemoveStudentsFromStudentSubgroupPayload>;
  /** RemoveTeachersFroms a StudentSubgroup. */
  removeTeachersFromStudentSubgroup?: Maybe<RemoveTeachersFromStudentSubgroupPayload>;
  /** Updates a Class. */
  updateClass?: Maybe<UpdateClassPayload>;
  /**
   * Updates a ClassTeacher.
   * @deprecated Use nested mutation
   */
  updateClassTeacher?: Maybe<UpdateClassTeacherPayload>;
  /** Updates a DistrictSettingsRoster. */
  updateDistrictSettingsRoster?: Maybe<UpdateDistrictSettingsRosterPayload>;
  /**
   * Updates a StudentSubgroup.
   * @deprecated Use nested mutation
   */
  updateStudentSubgroup?: Maybe<UpdateStudentSubgroupPayload>;
  createProduct: ProductPayload;
  createSubscription: SubscriptionPayload;
  createSubscriptionPackage: SubscriptionPackagePayload;
  deleteProduct: ProductPayload;
  deleteSubscription: SubscriptionPayload;
  deleteSubscriptionPackage: SubscriptionPackagePayload;
  updateProduct: ProductPayload;
  updateSubscription: SubscriptionPayload;
  updateSubscriptionPackage: SubscriptionPackagePayload;
  createTeam: TeamPayload;
  updateTeam: TeamPayload;
  createQrcodeTemplate: QrcodeTemplate;
  createPdfAssessment: PdfAssessment;
  /** AddSchoolTos a Student. */
  addSchoolToStudent?: Maybe<AddSchoolToStudentPayload>;
  /** Cancels a Impersonation. */
  cancelImpersonation?: Maybe<CancelImpersonationPayload>;
  /** Creates a Impersonation. */
  createImpersonation?: Maybe<CreateImpersonationPayload>;
  /** Creates a PasswordChange. */
  createPasswordChange?: Maybe<CreatePasswordChangePayload>;
  /** Creates a User. */
  createUser?: Maybe<CreateUserPayload>;
  /**
   * Creates a UserRole.
   * @deprecated Use nested mutation
   */
  createUserRole?: Maybe<CreateUserRolePayload>;
  /**
   * Deletes a User.
   * @deprecated For QA use
   */
  deleteUser?: Maybe<DeleteUserPayload>;
  /** Invites a Parent. */
  inviteParent?: Maybe<InviteParentPayload>;
  /** ResetPasswordSetTimes a User. */
  resetPasswordSetTimeUser?: Maybe<ResetPasswordSetTimeUserPayload>;
  /** RestrictInvitations a Parent. */
  restrictInvitationParent?: Maybe<RestrictInvitationParentPayload>;
  /** Updates a DistrictSettingsUserManagement. */
  updateDistrictSettingsUserManagement?: Maybe<UpdateDistrictSettingsUserManagementPayload>;
  /** Updates a User. */
  updateUser?: Maybe<UpdateUserPayload>;
  /** UpdateUserActivitys a User. */
  updateUserActivity?: Maybe<UpdateUserActivityPayload>;
  /**
   * Updates a UserRole.
   * @deprecated Use nested mutation
   */
  updateUserRole?: Maybe<UpdateUserRolePayload>;
  /** VerifyEmailss a Parent. */
  verifyEmailsParent?: Maybe<VerifyEmailsParentPayload>;
  addLinkedVideo: VideoPayload;
  addQuestionsToVideo: VideoPayload;
  createReaderLibrary: ReaderLibraryPayload;
  createStemscopesMediaPack: CreateStemscopesMediaPackPayload;
  createStemscopesMediaPostObject: StemscopesMediaPostObjectPayload;
  createStreamingVideoPostObject: VideoPostObjectPayload;
  createTutor: TutorPayload;
  createVideoPostObject: VideoPostObjectPayload;
  deleteReaderLibrary: ReaderLibraryPayload;
  deleteStemscopesMedia: StemscopesMediaPayload;
  deleteStemscopesMediaPack: DeleteStemscopesMediaPackPayload;
  deleteStreamingVideo: StreamingVideoPayload;
  deleteTutor: TutorPayload;
  deleteVideo: VideoPayload;
  moveVideo: VideoPayload;
  updateReaderLibrary: ReaderLibraryPayload;
  updateStemscopesMedia: StemscopesMediaPostObjectPayload;
  updateStemscopesMediaPack: UpdateStemscopesMediaPackPayload;
  updateStreamingVideo: VideoPostObjectPayload;
  updateTutor: TutorPayload;
  districtEnabled: ChatGptDistrictPayload;
  /** get the text and prepare the conversation */
  sendTextScienceBot: ScienceBotPayload;
  deleteNote: NotePayload;
  putNote: NotePayload;
  onrampDeleteTeacherAssignment: DeleteTeacherAssignmentPayload;
  /** Onramp Teacher Assignment */
  onrampStudentAssignmentIdUpdate: StudentAssignmentIdUpdatePayload;
  /** Onramp Teacher Assignment */
  onrampStudentAssignmentResponse: StudentResponsePayload;
  /** Onramp Teacher Assignment Status */
  onrampStudentAssignmentStatus: StudentAssignmentStatusPayload;
  /** Reset start up test */
  resetOnrampStartUp: ResetStudentStartupPayload;
  /** Creates a Post. */
  createPost?: Maybe<CreatePostPayload>;
  /** Creates a WallAgeControl. */
  createWallAgeControl?: Maybe<CreateWallAgeControlPayload>;
  /** Creates a WallPostPin. */
  createWallPostPin?: Maybe<CreateWallPostPinPayload>;
  /** Removes a Post. */
  removePost?: Maybe<RemovePostPayload>;
  /** ToggleVerifySchoolAges a WallAgeControl. */
  toggleVerifySchoolAge?: Maybe<ToggleVerifySchoolAgePayload>;
  /** Toggles a WallPostLike. */
  toggleWallPostLike?: Maybe<ToggleWallPostLikePayload>;
  /** Updates a Post. */
  updatePost?: Maybe<UpdatePostPayload>;
  createAiChat: AiChatPayload;
  updateDistrictSettingsKnowledgeBase: DistrictSettingsKnowledgeBase;
  createEmailTemplate: EmailTemplatePayload;
  updateEmailTemplate: EmailTemplatePayload;
  createLti: Lti;
  createSso: Sso;
  createSsoWithPolicies: Sso;
  deleteLti: Lti;
  deleteSso: Sso;
  regenerateLtiV1p1Credentials: Lti;
  regenerateSsoCredentials: Sso;
  updateLti: Lti;
  updateSso: Sso;
  updateSsoWithPolicies: Sso;
  publishEvent: EventStatusPayload;
};

export type MutationArchiveAssignmentsArgs = {
  action: ArchiveAssignmentAction;
  assignmentIds: Array<Scalars['String']>;
};

export type MutationCreateAssignmentArgs = {
  params: CreateAssignmentInput;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type MutationCreateResourceArgs = {
  params: CreateResourceInput;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type MutationDaSignRequestArgs = {
  params: DistrictAssignmentSignRequestInput;
};

export type MutationEditAssignmentArgs = {
  assignmentParams: EditAssignmentInput;
};

export type MutationEditStemscopesAssignmentArgs = {
  assignmentParams: StemscopesEditInput;
};

export type MutationMakeAvailableDistrictAssignmentArgs = {
  params: MakeAvailableDistrictAssignmentInput;
};

export type MutationOpenLtiAssignmentLinkArgs = {
  assignmentId: Scalars['String'];
  assignmentParams: PutAssignmentInput;
  curriculumArea: Scalars['String'];
  gradeLevel: Scalars['String'];
  ownerId: Scalars['String'];
};

export type MutationOpenLtiEdgexlAssignmentLinkArgs = {
  assignmentParams: PutAssignmentInput;
};

export type MutationPutActivitiesShareResultsArgs = {
  params: PutActivitiesShareResultsInput;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type MutationPutAssignmentArgs = {
  assignmentParams: PutAssignmentInput;
};

export type MutationPutDistrictAssignmentsShareResultsArgs = {
  params: DistrictAssignmentsShareResultsInput;
};

export type MutationPutDistrictStudentAssignmentsArgs = {
  params: PutDistrictStudentAssignmentsInput;
};

export type MutationPutStudentAssignmentsArgs = {
  params: PutStudentAssignmentsInput;
};

export type MutationReopenAssignmentArgs = {
  sessionsParams: ReopenAssignmentInput;
};

export type MutationReopenDistrictAssignmentArgs = {
  params: ReopenDistrictAssignmentInput;
};

export type MutationResetAssignmentArgs = {
  sessionsParams: ResetAssignmentInput;
};

export type MutationResetDistrictAssignmentArgs = {
  params: ManageDistrictAssignmentInput;
};

export type MutationReturnAssignmentArgs = {
  sessionsParams: ReturnAssignmentInput;
};

export type MutationReturnDistrictAssignmentArgs = {
  params: ManageDistrictAssignmentInput;
};

export type MutationSaveGradingSessionArgs = {
  sessionsParams: Array<SaveGradingSessionInput>;
};

export type MutationSaveStudentsResourcesFiltersArgs = {
  studentsFilters: StudentResourcesFilterInput;
};

export type MutationSignRequestArgs = {
  params: SignRequestInput;
};

export type MutationStartMnPersonalAssignmentArgs = {
  assignmentParams: StartMnPersonalAssignmentInput;
  ltiAssignment?: InputMaybe<Scalars['String']>;
};

export type MutationStartStemscopesAssignmentArgs = {
  activityParams: ActivityParamsInput;
};

export type MutationAttachQuestionContainerArgs = {
  input: AttachQuestionContainerInput;
};

export type MutationControlAssessmentArgs = {
  input: ControlAssessmentInput;
};

export type MutationControlContainerArgs = {
  input: ControlContainerInput;
};

export type MutationCopyAssessmentArgs = {
  input: CopyAssessmentInput;
};

export type MutationCreateAssessmentArgs = {
  input: CreateAssessmentInput;
};

export type MutationCreateAssessmentPackageBoxArgs = {
  input: CreateAssessmentPackageBoxInput;
};

export type MutationCreateQuestionContainerArgs = {
  input: CreateQuestionContainerInput;
};

export type MutationCreateSignRequestArgs = {
  items?: InputMaybe<Array<Scalars['String']>>;
  input?: InputMaybe<CreateSignRequestInput>;
};

export type MutationDeleteAssessmentArgs = {
  input: DeleteAssessmentInput;
};

export type MutationShareAssessmentArgs = {
  input: ShareAssessmentInput;
};

export type MutationUpdateAssessmentArgs = {
  input: UpdateAssessmentInput;
};

export type MutationBulkUpdateContentsArgs = {
  input: BulkUpdateContentsInput;
};

export type MutationCreateContentGroupArgs = {
  input: CreateContentGroupInput;
};

export type MutationUpdateContentArgs = {
  input: UpdateContentInput;
};

export type MutationUpdateContentGroupArgs = {
  input: UpdateContentGroupInput;
};

export type MutationGenerateTccArgs = {
  contentGroupMapping?: InputMaybe<Array<ContentGroupMapping>>;
  contentGroups?: InputMaybe<Array<Scalars['String']>>;
  grades?: InputMaybe<Array<Scalars['String']>>;
  ltiConsumer?: InputMaybe<TccConsumerEnum>;
  productName: ProductNameEnum;
  schoolIds: Array<Scalars['String']>;
  version: TccVersionEnum;
};

export type MutationSubmitContentItemLinksArgs = {
  contentItems: Array<ContentItem>;
  schoolId: Scalars['String'];
};

export type MutationSubmitMnContentItemLinksV11Args = {
  mnContentItems: Array<MnContentItem>;
  schoolId: Scalars['String'];
};

export type MutationSubmitMnContentItemLinksV13Args = {
  mnLtiContentTypes: MnLtiContent;
  schoolId: Scalars['String'];
};

export type MutationCreateWidgetArgs = {
  input: CreateWidgetInput;
};

export type MutationUpdateWidgetArgs = {
  input: UpdateWidgetInput;
};

export type MutationUpdateWidgetsArgs = {
  input: Array<InputMaybe<UpdateWidgetInput>>;
};

export type MutationDeleteWidgetArgs = {
  input: DeleteWidgetInput;
};

export type MutationCreateAdoptionAccountArgs = {
  input: CreateAdoptionAccountInput;
};

export type MutationCreateAdoptionEventArgs = {
  input: CreateAdoptionEventInput;
};

export type MutationCreateAdoptionReviewerArgs = {
  input: CreateAdoptionReviewerInput;
};

export type MutationCreatePreviewAccountArgs = {
  input: CreatePreviewAccountInput;
};

export type MutationCreatePreviewAccountsCsvPostObjectArgs = {
  input: CreatePreviewAccountsCsvPostObjectInput;
};

export type MutationCreateProductGroupArgs = {
  input: CreateProductGroupInput;
};

export type MutationDeleteAdoptionAccountArgs = {
  input: DeleteAdoptionAccountInput;
};

export type MutationDeleteAdoptionEventArgs = {
  input: DeleteAdoptionEventInput;
};

export type MutationDeleteAdoptionReviewerArgs = {
  input: DeleteAdoptionReviewerInput;
};

export type MutationDeletePreviewAccountArgs = {
  input: DeletePreviewAccountInput;
};

export type MutationDeleteProductGroupArgs = {
  input: DeleteProductGroupInput;
};

export type MutationResetPreviewAccountActivityPeriodEndArgs = {
  input: ResetPreviewAccountActivityPeriodEndInput;
};

export type MutationUpdateAdoptionAccountArgs = {
  input: UpdateAdoptionAccountInput;
};

export type MutationUpdateAdoptionEventArgs = {
  input: UpdateAdoptionEventInput;
};

export type MutationUpdateAdoptionReviewerArgs = {
  input: UpdateAdoptionReviewerInput;
};

export type MutationUpdatePreviewAccountArgs = {
  input: UpdatePreviewAccountInput;
};

export type MutationUpdatePreviewAccountTeacherArgs = {
  input: UpdatePreviewAccountTeacherInput;
};

export type MutationUpdateProductGroupArgs = {
  input: UpdateProductGroupInput;
};

export type MutationAddPermissionArgs = {
  input: AddPermissionInput;
};

export type MutationCreateAccessPolicyArgs = {
  input: CreateAccessPolicyInput;
};

export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};

export type MutationDeleteAccessPolicyArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteRoleArgs = {
  id: Scalars['ID'];
};

export type MutationRemovePermissionArgs = {
  input: RemovePermissionInput;
};

export type MutationUpdateAccessPolicyArgs = {
  input: UpdateAccessPolicyInput;
};

export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};

export type MutationConvertInlineCssArgs = {
  input: InlineCssInput;
};

export type MutationScopePrintDownloadArgs = {
  input: ScopePrintDownloadInput;
};

export type MutationCreateImageArgs = {
  input: CreateImageInput;
};

export type MutationCreateImageCategoryArgs = {
  input: CreateImageCategoryInput;
};

export type MutationCreateImageKeywordArgs = {
  input: CreateImageKeywordInput;
};

export type MutationCreateImageTagArgs = {
  input: CreateImageTagInput;
};

export type MutationCreateImageTagValueArgs = {
  input: CreateImageTagValueInput;
};

export type MutationDeleteImageArgs = {
  input: DeleteImageInput;
};

export type MutationDeleteImageCategoryArgs = {
  input: DeleteImageCategoryInput;
};

export type MutationDeleteImageKeywordArgs = {
  input: DeleteImageKeywordInput;
};

export type MutationDeleteImageTagArgs = {
  input: DeleteImageTagInput;
};

export type MutationDeleteImageTagValueArgs = {
  input: DeleteImageTagValueInput;
};

export type MutationUpdateImageArgs = {
  input: UpdateImageInput;
};

export type MutationUpdateImageCategoryArgs = {
  input: UpdateImageCategoryInput;
};

export type MutationUpdateImageKeywordArgs = {
  input: UpdateImageKeywordInput;
};

export type MutationUpdateImageTagArgs = {
  input: UpdateImageTagInput;
};

export type MutationUpdateImageTagValueArgs = {
  input: UpdateImageTagValueInput;
};

export type MutationCreateIntegrationArgs = {
  integration: IntegrationInput;
};

export type MutationDeleteIntegrationArgs = {
  integrationInput: IntegrationKeyInput;
};

export type MutationExtractOneRosterSchoolsArgs = {
  schoolsInput: ExtractOneRosterSchoolsInput;
};

export type MutationForcePullIntegrationArgs = {
  integrationInput: IntegrationKeyInput;
};

export type MutationForceUploadOneRosterFileArgs = {
  districtId: Scalars['String'];
  file: Scalars['Upload'];
};

export type MutationPullIntegrationArgs = {
  integrationInput: IntegrationKeyInput;
};

export type MutationSyncSchoolsArgs = {
  integrationInput: IntegrationKeyInput;
};

export type MutationUpdateIntegrationArgs = {
  integration: IntegrationInput;
};

export type MutationUploadOneRosterFileArgs = {
  districtId: Scalars['String'];
  file: Scalars['Upload'];
};

export type MutationCreateCalendarArgs = {
  input: CreateCalendarInput;
};

export type MutationCreateEntryArgs = {
  input: CreateEntryInput;
};

export type MutationCreatePacingGuideArgs = {
  input: CreatePacingGuideInput;
};

export type MutationCreateScopePlanArgs = {
  input: CreateScopePlanInput;
};

export type MutationDeleteUserCalendarArgs = {
  input: DeleteUserCalendarInput;
};

export type MutationInitiateUserSelectionArgs = {
  input: InitiateUserSelectionInput;
};

export type MutationRemoveCalendarArgs = {
  input: RemoveCalendarInput;
};

export type MutationRemoveEntryArgs = {
  input: RemoveEntryInput;
};

export type MutationRemovePacingGuideArgs = {
  input: RemovePacingGuideInput;
};

export type MutationRemoveScopePlanArgs = {
  input: RemoveScopePlanInput;
};

export type MutationShareCalendarArgs = {
  input: ShareCalendarInput;
};

export type MutationShareTemplatePacingGuideArgs = {
  input: ShareTemplatePacingGuideInput;
};

export type MutationShareTemplateScopePlanArgs = {
  input: ShareTemplateScopePlanInput;
};

export type MutationUpdateCalendarArgs = {
  input: UpdateCalendarInput;
};

export type MutationUpdateDistrictSettingsLessonPlannerArgs = {
  input: UpdateDistrictSettingsLessonPlannerInput;
};

export type MutationUpdateEntryArgs = {
  input: UpdateEntryInput;
};

export type MutationUpdatePacingGuideArgs = {
  input: UpdatePacingGuideInput;
};

export type MutationUpdatePacingGuideScopeArgs = {
  input: UpdatePacingGuideScopeInput;
};

export type MutationUpdateScopePlanArgs = {
  input: UpdateScopePlanInput;
};

export type MutationUpdateScopePlanScopeArgs = {
  input: UpdateScopePlanScopeInput;
};

export type MutationUpdateUserCalendarArgs = {
  input: UpdateUserCalendarInput;
};

export type MutationUpdateUserSelectionArgs = {
  input: UpdateUserSelectionInput;
};

export type MutationCompleteDistrictSetupStatusArgs = {
  input: CompleteDistrictSetupStatusInput;
};

export type MutationCreateDistrictArgs = {
  input: CreateDistrictInput;
};

export type MutationCreateSchoolArgs = {
  input: CreateSchoolInput;
};

export type MutationDeleteDistrictArgs = {
  input: DeleteDistrictInput;
};

export type MutationDeleteSchoolArgs = {
  input: DeleteSchoolInput;
};

export type MutationUpdateDistrictArgs = {
  input: UpdateDistrictInput;
};

export type MutationUpdateSchoolArgs = {
  input: UpdateSchoolInput;
};

export type MutationCreatePasswordRecoveryArgs = {
  input: PasswordRecoveryInput;
};

export type MutationCreatePasswordRecoveryRequestArgs = {
  input: PasswordRecoveryRequestInput;
};

export type MutationRegisterAdoptionReviewerArgs = {
  input: RegisterAdoptionReviewerInput;
};

export type MutationRegisterParentArgs = {
  input: RegisterParentInput;
};

export type MutationRegisterPreviewAccountTeacherArgs = {
  input: RegisterPreviewAccountTeacherInput;
};

export type MutationAddStudentToClassesArgs = {
  input: AddStudentToClassesInput;
};

export type MutationAddStudentsToClassArgs = {
  input: AddStudentsToClassInput;
};

export type MutationAddStudentsToStudentSubgroupArgs = {
  input: AddStudentsToStudentSubgroupInput;
};

export type MutationAddTeachersToStudentSubgroupArgs = {
  input: AddTeachersToStudentSubgroupInput;
};

export type MutationCreateClassArgs = {
  input: CreateClassInput;
};

export type MutationCreateClassTeacherArgs = {
  input: CreateClassTeacherInput;
};

export type MutationDeleteClassArgs = {
  input: DeleteClassInput;
};

export type MutationRemoveClassTeacherArgs = {
  input: RemoveClassTeacherInput;
};

export type MutationRemoveStudentFromClassesArgs = {
  input: RemoveStudentFromClassesInput;
};

export type MutationRemoveStudentsFromClassArgs = {
  input: RemoveStudentsFromClassInput;
};

export type MutationRemoveStudentsFromStudentSubgroupArgs = {
  input: RemoveStudentsFromStudentSubgroupInput;
};

export type MutationRemoveTeachersFromStudentSubgroupArgs = {
  input: RemoveTeachersFromStudentSubgroupInput;
};

export type MutationUpdateClassArgs = {
  input: UpdateClassInput;
};

export type MutationUpdateClassTeacherArgs = {
  input: UpdateClassTeacherInput;
};

export type MutationUpdateDistrictSettingsRosterArgs = {
  input: UpdateDistrictSettingsRosterInput;
};

export type MutationUpdateStudentSubgroupArgs = {
  input: UpdateStudentSubgroupInput;
};

export type MutationCreateProductArgs = {
  createProductInput: CreateProductInput;
};

export type MutationCreateSubscriptionArgs = {
  createSubscriptionInput: CreateSubscriptionInput;
};

export type MutationCreateSubscriptionPackageArgs = {
  createSubscriptionPackageInput: CreateSubscriptionPackageInput;
};

export type MutationDeleteProductArgs = {
  deleteProductInput: DeleteProductInput;
};

export type MutationDeleteSubscriptionArgs = {
  deleteSubscriptionInput: DeleteSubscriptionInput;
};

export type MutationDeleteSubscriptionPackageArgs = {
  deleteSubscriptionPackageInput: DeleteSubscriptionPackageInput;
};

export type MutationUpdateProductArgs = {
  updateProductInput: UpdateProductInput;
};

export type MutationUpdateSubscriptionArgs = {
  updateSubscriptionInput: UpdateSubscriptionInput;
};

export type MutationUpdateSubscriptionPackageArgs = {
  updateSubscriptionPackageInput: UpdateSubscriptionPackageInput;
};

export type MutationCreateTeamArgs = {
  createTeamInput: CreateTeamInput;
};

export type MutationUpdateTeamArgs = {
  updateTeamInput: UpdateTeamInput;
};

export type MutationCreateQrcodeTemplateArgs = {
  input: CreateQrcodeTemplateInput;
};

export type MutationCreatePdfAssessmentArgs = {
  input: CreatePdfAssessmentInput;
};

export type MutationAddSchoolToStudentArgs = {
  input: AddSchoolToStudentInput;
};

export type MutationCancelImpersonationArgs = {
  input: CancelImpersonationInput;
};

export type MutationCreateImpersonationArgs = {
  input: CreateImpersonationInput;
};

export type MutationCreatePasswordChangeArgs = {
  input: CreatePasswordChangeInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateUserRoleArgs = {
  input: CreateUserRoleInput;
};

export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

export type MutationInviteParentArgs = {
  input: InviteParentInput;
};

export type MutationResetPasswordSetTimeUserArgs = {
  input: ResetPasswordSetTimeUserInput;
};

export type MutationRestrictInvitationParentArgs = {
  input: RestrictInvitationParentInput;
};

export type MutationUpdateDistrictSettingsUserManagementArgs = {
  input: UpdateDistrictSettingsUserManagementInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserActivityArgs = {
  input: UpdateUserActivityInput;
};

export type MutationUpdateUserRoleArgs = {
  input: UpdateUserRoleInput;
};

export type MutationVerifyEmailsParentArgs = {
  input: VerifyEmailsParentInput;
};

export type MutationAddLinkedVideoArgs = {
  input: AddLinkedVideoInput;
};

export type MutationAddQuestionsToVideoArgs = {
  input: AddQuestionsToVideoInput;
};

export type MutationCreateReaderLibraryArgs = {
  input: CreateReaderLibraryInput;
};

export type MutationCreateStemscopesMediaPackArgs = {
  input: CreateStemscopesMediaPackInput;
};

export type MutationCreateStemscopesMediaPostObjectArgs = {
  input: CreateStemscopesMediaPostObjectInput;
};

export type MutationCreateStreamingVideoPostObjectArgs = {
  input: CreateStreamingVideoPostObjectInput;
};

export type MutationCreateTutorArgs = {
  input: CreateTutorInput;
};

export type MutationCreateVideoPostObjectArgs = {
  input: CreateVideoPostObjectInput;
};

export type MutationDeleteReaderLibraryArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteStemscopesMediaArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteStemscopesMediaPackArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteStreamingVideoArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteTutorArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteVideoArgs = {
  input: DeleteVideoInput;
};

export type MutationMoveVideoArgs = {
  input: MoveVideoInput;
};

export type MutationUpdateReaderLibraryArgs = {
  input: UpdateReaderLibraryInput;
};

export type MutationUpdateStemscopesMediaArgs = {
  input: UpdateStemscopesMediaInput;
};

export type MutationUpdateStemscopesMediaPackArgs = {
  input: UpdateStemscopesMediaPackInput;
};

export type MutationUpdateStreamingVideoArgs = {
  input: UpdateStreamingVideoInput;
};

export type MutationUpdateTutorArgs = {
  input: UpdateTutorInput;
};

export type MutationDistrictEnabledArgs = {
  districtId?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSendTextScienceBotArgs = {
  conversationID?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteNoteArgs = {
  id: Scalars['ID'];
};

export type MutationPutNoteArgs = {
  id: Scalars['ID'];
  type: NoteInputTypeEnum;
  data: Scalars['String'];
};

export type MutationOnrampDeleteTeacherAssignmentArgs = {
  input: DeleteTeacherAssignmentInput;
};

export type MutationOnrampStudentAssignmentIdUpdateArgs = {
  input: OnrampStudentAssignmentIdUpdateInput;
};

export type MutationOnrampStudentAssignmentResponseArgs = {
  input: OnrampStudentAssignmentResponseInput;
};

export type MutationOnrampStudentAssignmentStatusArgs = {
  input: OnrampAssignmentStatusInput;
};

export type MutationResetOnrampStartUpArgs = {
  input: ResetOnrampStartupTestInput;
};

export type MutationCreatePostArgs = {
  input: CreatePostInput;
};

export type MutationCreateWallAgeControlArgs = {
  input: CreateWallAgeControlInput;
};

export type MutationCreateWallPostPinArgs = {
  input: CreateWallPostPinInput;
};

export type MutationRemovePostArgs = {
  input: RemovePostInput;
};

export type MutationToggleVerifySchoolAgeArgs = {
  input: ToggleVerifySchoolAgeInput;
};

export type MutationToggleWallPostLikeArgs = {
  input: ToggleWallPostLikeInput;
};

export type MutationUpdatePostArgs = {
  input: UpdatePostInput;
};

export type MutationCreateAiChatArgs = {
  input: AiChatInput;
};

export type MutationUpdateDistrictSettingsKnowledgeBaseArgs = {
  input: DistrictSettingsInput;
};

export type MutationCreateEmailTemplateArgs = {
  input: CreateEmailTemplateInput;
};

export type MutationUpdateEmailTemplateArgs = {
  input: UpdateEmailTemplateInput;
};

export type MutationCreateLtiArgs = {
  ltiInput: LtiInput;
};

export type MutationCreateSsoArgs = {
  ssoInput: SsoInput;
};

export type MutationCreateSsoWithPoliciesArgs = {
  ssoInput: SsoWithPoliciesInput;
};

export type MutationDeleteLtiArgs = {
  ltiKeyInput: LtiKeyInput;
};

export type MutationDeleteSsoArgs = {
  ssoKeyInput: SsoKeyInput;
};

export type MutationRegenerateLtiV1p1CredentialsArgs = {
  ltiKeyInput: LtiKeyInput;
};

export type MutationRegenerateSsoCredentialsArgs = {
  ssoKeyInput: SsoKeyInput;
};

export type MutationUpdateLtiArgs = {
  ltiInput: LtiInput;
};

export type MutationUpdateSsoArgs = {
  ssoInput: SsoInput;
};

export type MutationUpdateSsoWithPoliciesArgs = {
  ssoInput: SsoWithPoliciesInput;
};

export type MutationPublishEventArgs = {
  input: EventInput;
};

export type NewDistrict = {
  __typename?: 'NewDistrict';
  city?: Maybe<Scalars['String']>;
  globalName: Scalars['String'];
  id: Scalars['String'];
  localName: Scalars['String'];
  ssoIntegrations: Array<SsoTypeEnum>;
  state: Scalars['String'];
  urlCode?: Maybe<Scalars['String']>;
};

export type NewDistrictPageConnection = {
  __typename?: 'NewDistrictPageConnection';
  collection: Array<NewDistrict>;
  paginationInfo?: Maybe<NewDistrictPaginationInfo>;
};

export type NewDistrictPaginationInfo = {
  __typename?: 'NewDistrictPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A node, according to the Relay specification. */
export type Node = {
  /** The id of this node. */
  id: Scalars['ID'];
};

export enum NoteInputTypeEnum {
  FreeResponse = 'FREE_RESPONSE',
  Goboard = 'GOBOARD',
  Checkbox = 'CHECKBOX',
  Radio = 'RADIO',
  Input = 'INPUT',
  Overlay = 'OVERLAY',
  ShortAnswer = 'SHORT_ANSWER',
  DesmosCalculator = 'DESMOS_CALCULATOR',
  DesmosUrl = 'DESMOS_URL',
}

export type NoteItemPayload = {
  __typename?: 'NoteItemPayload';
  data: Scalars['String'];
  id: Scalars['String'];
  type: Scalars['String'];
};

export type NotePayload = {
  __typename?: 'NotePayload';
  item?: Maybe<NoteItemPayload>;
};

export type OneRosterSchool = {
  __typename?: 'OneRosterSchool';
  externalId: Scalars['String'];
  name: Scalars['String'];
  status: OneRosterSchoolStatusEnum;
  type?: Maybe<OneRosterSchoolTypeEnum>;
};

export enum OneRosterSchoolStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Tobedeleted = 'TOBEDELETED',
}

export enum OneRosterSchoolTypeEnum {
  District = 'DISTRICT',
  School = 'SCHOOL',
}

export type OnrampAssignmentStatusInput = {
  id?: Scalars['Int'];
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type OnrampQuestionContainerInput = {
  category: Scalars['String'];
  domain: Scalars['String'];
  grade: Scalars['String'];
  standard: Scalars['String'];
  topic: Scalars['String'];
};

/** OnRampReport type: Pathway / Startup / TeacherAssigned */
export enum OnRampReportTypeEnum {
  /** Pathway Type */
  Pathway = 'Pathway',
  /** Startup Type */
  Startup = 'Startup',
  /** TeacherAssigned Type */
  TeacherAssigned = 'TeacherAssigned',
}

export type OnrampStartupTestInput = {
  studentGrade?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type OnrampStatusPayload = {
  __typename?: 'OnrampStatusPayload';
  /** // More specific type hint for clarity */
  onrampStatus: Array<Array<Scalars['String']>>;
};

export type OnrampStudentAssignmentIdUpdateInput = {
  id?: Scalars['Int'];
  learnosityAssignmentId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type OnrampStudentAssignmentInput = {
  typeInput?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type OnrampStudentAssignmentResponseInput = {
  id?: Scalars['Int'];
  learnosityAssignmentId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type OnrampStudentStatusInput = {
  studentIds: Array<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  districtId?: Maybe<Scalars['String']>;
  district: District;
  schools: Array<School>;
  subscriptionScope: SubscriptionScopeEnum;
  lineItems: Array<OrderLineItem>;
  active: Scalars['Boolean'];
  systemAdministratorEmail?: Maybe<Scalars['String']>;
  systemAdministratorName?: Maybe<Scalars['String']>;
};

export enum OrderByEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type OrderByInput = {
  field: Scalars['String'];
  order: OrderByEnum;
};

export type OrderFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  quantity?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type OrderLineItem = {
  __typename?: 'OrderLineItem';
  id: Scalars['ID'];
  productId: Scalars['String'];
  product?: Maybe<Product>;
  quantity: Scalars['Int'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type OrderPageConnection = {
  __typename?: 'OrderPageConnection';
  collection: Array<Order>;
  paginationInfo?: Maybe<OrderPaginationInfo>;
};

export type OrderPaginationInfo = {
  __typename?: 'OrderPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** Attributes for creating stemscopes other assignment */
export type OtherAssignmentInput = {
  otherAssignment?: InputMaybe<Scalars['Boolean']>;
  otherAssignmentScope?: InputMaybe<Scalars['String']>;
};

/** Owner Assignments Object Type */
export type OwnerAssignments = {
  __typename?: 'OwnerAssignments';
  assignments: Array<Assignment>;
  pageInfo?: Maybe<PageInfo>;
  studentAssignments: Array<StudentAssignment>;
};

export type PacingGuide = Node & {
  __typename?: 'PacingGuide';
  _id: Scalars['String'];
  assignedTo?: Maybe<Scalars['String']>;
  calendarId?: Maybe<Scalars['String']>;
  calendarType?: Maybe<CalendarTypeEnum>;
  createTime?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  curriculumArea?: Maybe<Scalars['String']>;
  editable?: Maybe<Scalars['Boolean']>;
  grade?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isSelected?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pacingGuideId?: Maybe<Scalars['String']>;
  pacingGuideTemplateId?: Maybe<Scalars['String']>;
  pacingGuideType?: Maybe<PacingGuideTypeEnum>;
  pk: Scalars['String'];
  schoolIds?: Maybe<Scalars['Iterable']>;
  scopeEditable?: Maybe<Scalars['Boolean']>;
  scopes?: Maybe<PacingGuideScopePageConnection>;
  searchName?: Maybe<Scalars['String']>;
  selectedCalendar?: Maybe<Scalars['Iterable']>;
  sharedBy?: Maybe<Scalars['String']>;
  sharedData?: Maybe<Scalars['Iterable']>;
  sharedOption?: Maybe<Scalars['String']>;
  sharedType?: Maybe<Scalars['String']>;
  sk: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userRoleCode?: Maybe<Scalars['String']>;
  userType?: Maybe<UsersTypeEnum>;
  version?: Maybe<Scalars['Int']>;
};

export type PacingGuideScopesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  pacingGuideId?: InputMaybe<Scalars['String']>;
  scopeId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Array<InputMaybe<PacingGuideScopeFilter>>>;
};

export type PacingGuideFilter = {
  curriculumArea?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pacingGuideId?: InputMaybe<Scalars['String']>;
  pacingGuideType?: InputMaybe<PacingGuideTypeEnum>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  teamId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PacingGuideFilter_Order = {
  name?: InputMaybe<SortEnum>;
};

/** Page connection for PacingGuide. */
export type PacingGuidePageConnection = {
  __typename?: 'PacingGuidePageConnection';
  collection?: Maybe<Array<Maybe<PacingGuide>>>;
  paginationInfo: PacingGuidePaginationInfo;
};

/** Information about the pagination. */
export type PacingGuidePaginationInfo = {
  __typename?: 'PacingGuidePaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PacingGuideScope = Node & {
  __typename?: 'PacingGuideScope';
  _id: Scalars['String'];
  calendarId?: Maybe<Scalars['String']>;
  calendarType?: Maybe<CalendarTypeEnum>;
  createTime?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructionalDays?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pacingGuideId?: Maybe<Scalars['String']>;
  pacingGuideTemplateId?: Maybe<Scalars['String']>;
  pacingGuideType?: Maybe<PacingGuideTypeEnum>;
  pk: Scalars['String'];
  schoolIds?: Maybe<Scalars['Iterable']>;
  scopeId?: Maybe<Scalars['String']>;
  sharedBy?: Maybe<Scalars['String']>;
  sk: Scalars['String'];
  type: EntryTypeEnum;
  updateTime?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userRoleCode?: Maybe<Scalars['String']>;
  userType?: Maybe<UsersTypeEnum>;
  version?: Maybe<Scalars['Int']>;
};

export type PacingGuideScopeFilter = {
  pacingGuideId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  scopeId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Page connection for PacingGuideScope. */
export type PacingGuideScopePageConnection = {
  __typename?: 'PacingGuideScopePageConnection';
  collection?: Maybe<Array<Maybe<PacingGuideScope>>>;
  paginationInfo: PacingGuideScopePaginationInfo;
};

/** Information about the pagination. */
export type PacingGuideScopePaginationInfo = {
  __typename?: 'PacingGuideScopePaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

/** Pacing Guide type: Individual / Team / Template */
export enum PacingGuideTypeEnum {
  /** Individual Type */
  Individual = 'Individual',
  /** Team Type */
  Team = 'Team',
  /** Template Type */
  Template = 'Template',
}

export type PageInfo = {
  __typename?: 'PageInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginationInfoPayload = {
  __typename?: 'PaginationInfoPayload';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type Parent = Node & {
  __typename?: 'Parent';
  _id?: Maybe<Scalars['String']>;
  districtUuid: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  inviteCount: Scalars['Int'];
  inviteStatus: ParentInviteStatusEnum;
  invitedAt: Scalars['String'];
  parent?: Maybe<User>;
  student: User;
};

export enum ParentInviteStatusEnum {
  InviteSent = 'INVITE_SENT',
  Active = 'ACTIVE',
}

export type ParentStudent = Node & {
  __typename?: 'ParentStudent';
  /** @deprecated Used from the student property */
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated Used from the student property */
  lastName?: Maybe<Scalars['String']>;
  student: Student;
  /** @deprecated Used from the student property */
  studentId: Scalars['String'];
};

export enum PasswordRecoveryApplication {
  MathNation = 'MathNation',
  StemScopes = 'StemScopes',
}

export type PasswordRecoveryInput = {
  districtActivation?: Scalars['Boolean'];
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export type PasswordRecoveryRequestInput = {
  application?: PasswordRecoveryApplication;
  email: Scalars['String'];
};

export type PasswordRecoveryToken = {
  __typename?: 'PasswordRecoveryToken';
  expired: Scalars['Boolean'];
};

export type Pdf = {
  __typename?: 'Pdf';
  url: Scalars['String'];
  groupIdentifier: Scalars['String'];
  id: Scalars['String'];
};

export type PdfAssessment = {
  __typename?: 'PdfAssessment';
  /** base64 representation of pdf or string representation of html */
  content: Scalars['String'];
};

export enum PermissionScope {
  Platform = 'Platform',
  District = 'District',
  School = 'School',
  Klass = 'Klass',
  User = 'User',
  Owner = 'Owner',
}

export type PlannerTeam = Node & {
  __typename?: 'PlannerTeam';
  _id: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

/** Page connection for PlannerTeam. */
export type PlannerTeamPageConnection = {
  __typename?: 'PlannerTeamPageConnection';
  collection?: Maybe<Array<Maybe<PlannerTeam>>>;
  paginationInfo: PlannerTeamPaginationInfo;
};

/** Information about the pagination. */
export type PlannerTeamPaginationInfo = {
  __typename?: 'PlannerTeamPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PlatformAdminActivityPoolPayload = {
  __typename?: 'PlatformAdminActivityPoolPayload';
  items: Array<PlatformAdminQuestionItemPayload>;
  tags: Array<TagPayload>;
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type PlatformAdminAssessmentItemPayload = {
  __typename?: 'PlatformAdminAssessmentItemPayload';
  status: Scalars['String'];
  tags: Array<TagPayload>;
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserPayload>;
  items: Array<Scalars['String']>;
};

export type PlatformAdminAssessmentPoolInput = {
  items: Array<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  next?: InputMaybe<Scalars['String']>;
};

export type PlatformAdminAssessmentPoolPayload = {
  __typename?: 'PlatformAdminAssessmentPoolPayload';
  list: Array<PlatformAdminAssessmentItemPayload>;
  next: Scalars['String'];
};

export type PlatformAdminAttachQuestionContainerInput = {
  id: Scalars['ID'];
  cyuQuestionContainerInput?: InputMaybe<CyuQuestionContainerInput>;
  edgexlQuestionContainerInput?: InputMaybe<EdgexlQuestionContainerInput>;
  onrampQuestionContainerInput?: InputMaybe<OnrampQuestionContainerInput>;
  ssQuestionContainerInput?: InputMaybe<SsQuestionContainerInput>;
  tyQuestionContainerInput?: InputMaybe<TyQuestionContainerInput>;
};

export type PlatformAdminControlAssessmentInput = {
  ids: Array<Scalars['String']>;
  schoolId: Scalars['String'];
  action: ControlActionEnum;
};

export type PlatformAdminControlContainerInput = {
  action: ControlActionEnum;
};

export type PlatformAdminCreateQuestionContainerInput = {
  cyuQuestionContainerInput?: InputMaybe<CyuQuestionContainerInput>;
  edgexlQuestionContainerInput?: InputMaybe<EdgexlQuestionContainerInput>;
  onrampQuestionContainerInput?: InputMaybe<OnrampQuestionContainerInput>;
  ssQuestionContainerInput?: InputMaybe<SsQuestionContainerInput>;
  tyQuestionContainerInput?: InputMaybe<TyQuestionContainerInput>;
};

export type PlatformAdminQuestionInput = {
  signedRequest?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type PlatformAdminQuestionItemPayload = {
  __typename?: 'PlatformAdminQuestionItemPayload';
  questionId: Scalars['String'];
  tags: Array<TagPayload>;
  id: Scalars['String'];
};

export type PlatformAdminQuestionPayload = {
  __typename?: 'PlatformAdminQuestionPayload';
  item?: Maybe<PlatformAdminQuestionItemPayload>;
  signedRequest: Scalars['String'];
};

export type PlatformAdminQuestionPoolInput = {
  domains?: InputMaybe<Array<Scalars['String']>>;
  lesson?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductEnum>;
  purpose?: InputMaybe<PurposeEnum>;
  topics?: InputMaybe<Array<Scalars['String']>>;
  category?: InputMaybe<EdgexlCategoryEnum>;
  grade?: InputMaybe<EdgexlGradeEnum>;
  standards?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  units?: InputMaybe<Array<Scalars['String']>>;
  next?: InputMaybe<Scalars['String']>;
};

export type PlatformAdminQuestionPoolPayload = {
  __typename?: 'PlatformAdminQuestionPoolPayload';
  list: Array<PlatformAdminActivityPoolPayload>;
  next: Scalars['String'];
};

export type PlatformAdminQuestionsInput = {
  standards?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  next?: InputMaybe<Scalars['String']>;
};

export type PlatformAdminQuestionsPayload = {
  __typename?: 'PlatformAdminQuestionsPayload';
  list: Array<PlatformAdminQuestionItemPayload>;
  next: Scalars['String'];
};

export type PlatformAdminUpdateAssessmentInput = {
  isRemove?: InputMaybe<Scalars['Boolean']>;
  items: Array<Scalars['String']>;
  id: Scalars['ID'];
};

export type PlatformAdminUpdateAssessmentPayload = {
  __typename?: 'PlatformAdminUpdateAssessmentPayload';
  item?: Maybe<PlatformAdminAssessmentItemPayload>;
};

export enum PolicyNameEnum {
  Configured = 'CONFIGURED',
  Email = 'EMAIL',
  Name = 'NAME',
  LmsId = 'LMS_ID',
  Username = 'USERNAME',
}

export type PoolEnumsPayload = {
  __typename?: 'PoolEnumsPayload';
  curriculumAreas: Array<Scalars['String']>;
  editions: Array<Scalars['String']>;
  grades: Array<Scalars['String']>;
  keywords: Array<Scalars['String']>;
  questionTypes: Array<Scalars['String']>;
  scopes: Array<Scalars['String']>;
  standards: Array<Scalars['String']>;
};

export type PoolQuestionTotalPayload = {
  __typename?: 'PoolQuestionTotalPayload';
  total: Scalars['Int'];
};

export type Post = Node & {
  __typename?: 'Post';
  _id: Scalars['Int'];
  comment: Scalars['String'];
  conversationId?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  districtId: Scalars['String'];
  documentKey?: Maybe<Scalars['String']>;
  documentPath?: Maybe<Scalars['String']>;
  documentType?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isAnswered: Scalars['Boolean'];
  isBlocked: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  isRobotAttempt: Scalars['Boolean'];
  isRobotFailed: Scalars['Boolean'];
  isStared: Scalars['Boolean'];
  isSticky: Scalars['Boolean'];
  isWelcomePost: Scalars['Boolean'];
  karmaAward?: Maybe<KarmaAward>;
  likedByCaller: Scalars['Boolean'];
  likes: Scalars['Iterable'];
  modified: Scalars['String'];
  parent?: Maybe<Post>;
  replies: Scalars['Iterable'];
  robotParent?: Maybe<Post>;
  robotReply?: Maybe<Post>;
  schoolId: Scalars['String'];
  schoolName: Scalars['String'];
  sessionId: Scalars['String'];
  stateCode: Scalars['String'];
  user: Scalars['Iterable'];
  userId: Scalars['String'];
  wallId: Scalars['String'];
  wallPostLikes?: Maybe<Array<Maybe<WallPostLike>>>;
  wallPostPin?: Maybe<WallPostPin>;
  wallPostReplies?: Maybe<PostPageConnection>;
};

export type PostWallPostRepliesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

/** Page connection for Post. */
export type PostPageConnection = {
  __typename?: 'PostPageConnection';
  collection?: Maybe<Array<Maybe<Post>>>;
  paginationInfo: PostPaginationInfo;
};

/** Information about the pagination. */
export type PostPaginationInfo = {
  __typename?: 'PostPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PreviewAccount = {
  __typename?: 'PreviewAccount';
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  activityPeriodEnd: Scalars['DateTime'];
  activityPeriodStart: Scalars['DateTime'];
  district: District;
  firstLoginDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  productGroups: Array<ProductGroup>;
  products: Array<Product>;
  registrationToken: Scalars['String'];
  school: School;
  state: StateEnum;
  subscriptionPackage: SubscriptionPackage;
  teachers: Array<PreviewAccountTeacher>;
};

export type PreviewAccountFilterInput = {
  activityPeriodStartFrom?: InputMaybe<Scalars['DateTime']>;
  activityPeriodStartTo?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<StateEnum>;
};

export type PreviewAccountImportLog = {
  __typename?: 'PreviewAccountImportLog';
  id: Scalars['ID'];
  errors: Array<PreviewAccountImportLogError>;
  originalFilename: Scalars['String'];
  importEnd?: Maybe<Scalars['DateTime']>;
  importStart: Scalars['DateTime'];
  importSucceeded: Scalars['Boolean'];
  message: Scalars['String'];
  type: PreviewAccountImportLogTypeEnum;
};

export type PreviewAccountImportLogError = {
  __typename?: 'PreviewAccountImportLogError';
  id: Scalars['ID'];
  field: Scalars['String'];
  importLogId: Scalars['String'];
  value: Scalars['String'];
};

export type PreviewAccountImportLogPageConnection = {
  __typename?: 'PreviewAccountImportLogPageConnection';
  collection: Array<PreviewAccountImportLog>;
  paginationInfo?: Maybe<PreviewAccountImportLogPaginationInfo>;
};

export type PreviewAccountImportLogPaginationInfo = {
  __typename?: 'PreviewAccountImportLogPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export enum PreviewAccountImportLogTypeEnum {
  Csv = 'csv',
}

export type PreviewAccountOrderInput = {
  active?: InputMaybe<SortEnum>;
  activityPeriodEnd?: InputMaybe<SortEnum>;
  activityPeriodStart?: InputMaybe<SortEnum>;
  firstLoginDate?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
  state?: InputMaybe<SortEnum>;
};

export type PreviewAccountPageConnection = {
  __typename?: 'PreviewAccountPageConnection';
  collection: Array<PreviewAccount>;
  paginationInfo?: Maybe<PreviewAccountPaginationInfo>;
};

export type PreviewAccountPaginationInfo = {
  __typename?: 'PreviewAccountPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PreviewAccountPayload = {
  __typename?: 'PreviewAccountPayload';
  previewAccount: PreviewAccount;
};

export type PreviewAccountsCsvPostObjectPayload = {
  __typename?: 'PreviewAccountsCsvPostObjectPayload';
  fileKey: Scalars['String'];
  formAttributes: Scalars['String'];
  formInputs: Scalars['String'];
};

export type PreviewAccountTeacher = {
  __typename?: 'PreviewAccountTeacher';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  previewAccount: PreviewAccount;
  user: User;
};

export type PreviewAccountTeacherFilterInput = {
  previewAccountId?: InputMaybe<Scalars['ID']>;
  username?: InputMaybe<Scalars['String']>;
};

export type PreviewAccountTeacherOrderInput = {
  email?: InputMaybe<SortEnum>;
  firstName?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
  lastName?: InputMaybe<SortEnum>;
  username?: InputMaybe<SortEnum>;
};

export type PreviewAccountTeacherPageConnection = {
  __typename?: 'PreviewAccountTeacherPageConnection';
  collection: Array<PreviewAccountTeacher>;
  paginationInfo?: Maybe<PreviewAccountTeacherPaginationInfo>;
};

export type PreviewAccountTeacherPaginationInfo = {
  __typename?: 'PreviewAccountTeacherPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PreviewAccountTeacherPayload = {
  __typename?: 'PreviewAccountTeacherPayload';
  previewAccountTeacher: PreviewAccountTeacher;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  /** Use only for merging data! */
  _id: Scalars['ID'];
  sku: Scalars['String'];
  productType: ProductTypeEnum;
  description: Scalars['String'];
  scopeGroups: Array<ScopeGroup>;
  contentGroups: Array<ContentGroup>;
};

export enum ProductEnum {
  Mathnation = 'MATHNATION',
  Science = 'SCIENCE',
  Stemscopesmath = 'STEMSCOPESMATH',
}

export type ProductFilterInput = {
  contentGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  description?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<ProductTypeEnum>;
  scopeGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  sku?: InputMaybe<Scalars['String']>;
};

export type ProductGroup = {
  __typename?: 'ProductGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  products: Array<Product>;
};

export type ProductGroupFilterInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type ProductGroupPageConnection = {
  __typename?: 'ProductGroupPageConnection';
  collection: Array<ProductGroup>;
  paginationInfo?: Maybe<ProductGroupPaginationInfo>;
};

export type ProductGroupPaginationInfo = {
  __typename?: 'ProductGroupPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProductGroupPayload = {
  __typename?: 'ProductGroupPayload';
  productGroup: ProductGroup;
};

export enum ProductNameEnum {
  Mathnation = 'MATHNATION',
  Stemscopes = 'STEMSCOPES',
}

export type ProductPageConnection = {
  __typename?: 'ProductPageConnection';
  collection: Array<Product>;
  paginationInfo?: Maybe<ProductPaginationInfo>;
};

export type ProductPaginationInfo = {
  __typename?: 'ProductPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProductPayload = {
  __typename?: 'ProductPayload';
  product: Product;
};

export enum ProductTypeEnum {
  MathNation = 'MathNation',
  StemScopes = 'StemScopes',
}

export enum ProjectTypeEnum {
  DigitalNotebook = 'DigitalNotebook',
  HelpPage = 'HelpPage',
  Lesson = 'Lesson',
  MathNation = 'MathNation',
  Scope = 'Scope',
  Streaming = 'Streaming',
  TeacherResource = 'TeacherResource',
  DailyNumeracy = 'DailyNumeracy',
  FactFluency = 'FactFluency',
  HowTo = 'HowTo',
  Undefined = 'Undefined',
}

export type PublicAdoptionAccount = {
  __typename?: 'PublicAdoptionAccount';
  districtId: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  uiCustomization?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
};

export type PublicParent = {
  __typename?: 'PublicParent';
  districtId: Scalars['String'];
  id: Scalars['String'];
};

export type PublicPreviewAccount = {
  __typename?: 'PublicPreviewAccount';
  district: NewDistrict;
  id: Scalars['String'];
};

export type PublicPreviewAccountTeacher = {
  __typename?: 'PublicPreviewAccountTeacher';
  id: Scalars['String'];
  previewAccount: PublicPreviewAccount;
};

export enum PurposeEnum {
  Cyu = 'CYU',
  Edgexl = 'EDGEXL',
  Onramp = 'ONRAMP',
  Ss = 'SS',
  Stemscopes = 'STEMSCOPES',
  Ty = 'TY',
}

export type PurposeEnumInput = {
  curriculumAreas?: InputMaybe<Array<Scalars['String']>>;
  grades?: InputMaybe<Array<Scalars['String']>>;
  gradingTypes?: InputMaybe<Array<GradingTypeEnum>>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  purpose: PurposeEnum;
  questionBanks?: InputMaybe<Array<QuestionBankEnum>>;
  questionTypes?: InputMaybe<Array<Scalars['String']>>;
  scopeNames?: InputMaybe<Array<Scalars['String']>>;
  state?: InputMaybe<Scalars['String']>;
};

/** Attributes for Activities share_results update */
export type PutActivitiesShareResultsInput = {
  assignmentId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  shareResults: ShareResults;
  studentIds: Array<Scalars['String']>;
};

/** Put Assignment Input */
export type PutAssignmentInput = {
  districtPutAssignmentInput?: InputMaybe<DistrictCreateInput>;
  edgexlPutAssignmentInput?: InputMaybe<EdgexlCreateInput>;
  ltiPutAssignmentInput?: InputMaybe<LtiCreateInput>;
  mnEdgexlPutAssignmentInput?: InputMaybe<LtiEdgeXlCreateInput>;
  putTeacherAssignmentInput?: InputMaybe<TeacherAssignmentsCreateInput>;
  stemscopesPutAssignmentInput?: InputMaybe<StemscopesCreateInput>;
};

/** PutDistrictStudentAssignmentsInput */
export type PutDistrictStudentAssignmentsInput = {
  assignmentId: Scalars['String'];
  newStudentIds: Array<Scalars['String']>;
};

/** PutStudentAssignmentsInput */
export type PutStudentAssignmentsInput = {
  assignees: AssignmentAssigneesInput;
  assignmentId: Scalars['String'];
};

/** Qrcode render types */
export enum QrcodeRenderEnum {
  Pdf = 'pdf',
  Html = 'html',
}

export type QrcodeTemplate = {
  __typename?: 'QrcodeTemplate';
  /** base64 representation of pdf or string representation of html */
  content: Scalars['String'];
};

export type QrcodeUserData = {
  /** teacher firstname and lastname */
  teacher: Scalars['String'];
  plainPassword?: InputMaybe<Scalars['String']>;
  qrcode: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  username?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  assignment?: Maybe<Assignment>;
  assignments?: Maybe<Assignments>;
  baseDistrictAssignments?: Maybe<DistrictBaseAssignmentsType>;
  /** Return District Class Assignments and District Students Assignments for teacher */
  districtAssignments?: Maybe<DistrictClassAssignmentsType>;
  /** Return District Class Assignments and District Students Assignments for district leader */
  districtAssignmentsForLeader?: Maybe<DistrictClassAssignmentsType>;
  districtStudentAssignments?: Maybe<DistrictStudentAssignmentsType>;
  edgexlAssignment?: Maybe<EdgexlAssignmentType>;
  /** Return edgexl assignments */
  edgexlAssignments?: Maybe<EdgexlAssignmentsType>;
  edgexlAssignmentsProgress?: Maybe<EdgexlAssignmentsProgressInfoType>;
  edgexlStudentAssignments?: Maybe<EdgexlStudentAssignmentsType>;
  mnPersonalAssignmentAttempts?: Maybe<EdgexlAssignmentsProgressInfoType>;
  ownerAssignments?: Maybe<OwnerAssignments>;
  /** @deprecated Has been deprecated, use StudentStudentAssignments instead */
  studentNotebook?: Maybe<StudentAssignments>;
  studentNotebooks?: Maybe<StudentNotebooks>;
  studentResources?: Maybe<StudentResources>;
  studentResourcesFilters?: Maybe<StudentsResourcesSettings>;
  studentStudentAssignments?: Maybe<StudentAssignments>;
  teacherFeedback?: Maybe<TeacherFeedback>;
  teacherStudentAssignments?: Maybe<StudentAssignments>;
  assessment: AssessmentPayload;
  assessmentLeader: AssessmentPayload;
  assessmentPool: AssessmentPoolPayload;
  assessmentPoolLeader: AssessmentPoolPayload;
  /** @deprecated Deprecated `$purpose` use `PurposeEnumInput $input` instead */
  poolEnums: PoolEnumsPayload;
  poolQuestionTotal: PoolQuestionTotalPayload;
  question: QuestionPayload;
  questionPool: QuestionPoolPayload;
  questionPoolLeader: QuestionPoolPayload;
  questions: QuestionsPayload;
  readContainer: ContainersVisibilityPayload;
  content?: Maybe<Content>;
  contentGroup?: Maybe<ContentGroup>;
  contentGroups: ContentGroupPageConnection;
  contents: ContentPageConnection;
  tccPackageDownloadLink: DownloadTccPackage;
  tccPackages: TccPackages;
  tccPackagesCount: TccPackagesCount;
  baseScope?: Maybe<BaseScope>;
  curriculumAreas?: Maybe<CurriculumAreas>;
  digitalNotebookCovers?: Maybe<DigitalNotebookCovers>;
  elements?: Maybe<Elements>;
  grades?: Maybe<Grades>;
  helpPage: HelpPageStructure;
  mnGrades?: Maybe<MathNationGrades>;
  mnScope?: Maybe<MathNationScope>;
  mnScopes: MathNationScopes;
  recentlyViewed?: Maybe<RecentlyViewed>;
  scope?: Maybe<Scope>;
  scopes: Scopes;
  streaming?: Maybe<Streaming>;
  streamingContentTypes?: Maybe<StreamingContentTypes>;
  streamingCurriculumAreas?: Maybe<StreamingCurriculumAreas>;
  streamingGrades?: Maybe<StreamingGrades>;
  streamingTags?: Maybe<StreamingTags>;
  streamingTopics?: Maybe<StreamingTopics>;
  streamings?: Maybe<Streamings>;
  teacherResource?: Maybe<TeacherResource>;
  teacherResources?: Maybe<TeacherResources>;
  topics?: Maybe<Topics>;
  widget: Widget;
  widgets: Array<Maybe<Widget>>;
  dashboardEmbedUrl: EmbedUrlResponse;
  dashboardEmbedUrlForAnonymousUser: EmbedUrlResponse;
  adoptionAccount: AdoptionAccount;
  adoptionAccounts: AdoptionAccountPageConnection;
  adoptionEvent: AdoptionEvent;
  adoptionEvents: AdoptionEventPageConnection;
  adoptionReviewer: AdoptionReviewer;
  adoptionReviewers: AdoptionReviewerPageConnection;
  latestPreviewAccountImportLog: PreviewAccountImportLog;
  previewAccount: PreviewAccount;
  previewAccountImportLog: PreviewAccountImportLog;
  previewAccountImportLogs: PreviewAccountImportLogPageConnection;
  previewAccountTeacher: PreviewAccountTeacher;
  previewAccountTeachers: PreviewAccountTeacherPageConnection;
  previewAccounts: PreviewAccountPageConnection;
  productGroup: ProductGroup;
  productGroups: ProductGroupPageConnection;
  node?: Maybe<Node>;
  accessPolicies: AccessPolicyPageConnection;
  accessPolicy: AccessPolicy;
  aclApplications: Array<AclApplication>;
  /** Use only for merging data! */
  lookupRoles: Array<Role>;
  role?: Maybe<Role>;
  roles: RolePageConnection;
  icon: Icon;
  icons: IconPageConnection;
  /** Use only for merging data! */
  lookupIcons: Array<Icon>;
  image: Image;
  imageCategories: ImageCategoryPageConnection;
  imageCategory: ImageCategory;
  imageFileUploadForm: ImageFileUploadForm;
  imageKeyword: ImageKeyword;
  imageKeywords: ImageKeywordPageConnection;
  imageTag: ImageTag;
  imageTagValue: ImageTagValue;
  imageTagValues: ImageTagValuePageConnection;
  imageTags: ImageTagPageConnection;
  images: ImagePageConnection;
  cleverSchools: Array<CleverSchool>;
  edfiGradeLevels: Array<EdfiGradeLevel>;
  edfiJobRoles: Array<EdfiJobRole>;
  edfiSchools: Array<EdfiSchool>;
  importResult: ImportResult;
  importResultErrors: ImportErrorPageConnection;
  importResultErrorsLink: ImportResultErrorsLink;
  importResults: Array<ImportResult>;
  integration?: Maybe<Integration>;
  integrations: Array<Integration>;
  oneRosterRestOauth2Schools: Array<OneRosterSchool>;
  oneRosterRestSchools: Array<OneRosterSchool>;
  getReport?: Maybe<Report>;
  getSignedRequests?: Maybe<Array<Maybe<SignedRequest>>>;
  calendar?: Maybe<Calendar>;
  calendarTemplate?: Maybe<Calendar>;
  calendarTemplates?: Maybe<CalendarPageConnection>;
  calendars?: Maybe<CalendarPageConnection>;
  districtSettingsLessonPlanner?: Maybe<DistrictSettingsLessonPlanner>;
  entries?: Maybe<EntryPageConnection>;
  entry?: Maybe<Entry>;
  pacingGuide?: Maybe<PacingGuide>;
  pacingGuideScope?: Maybe<PacingGuideScope>;
  pacingGuideScopes?: Maybe<PacingGuideScopePageConnection>;
  pacingGuideTemplate?: Maybe<PacingGuide>;
  pacingGuideTemplates?: Maybe<PacingGuidePageConnection>;
  pacingGuides?: Maybe<PacingGuidePageConnection>;
  plannerTeams?: Maybe<PlannerTeamPageConnection>;
  scopePlan?: Maybe<ScopePlan>;
  scopePlanScope?: Maybe<ScopePlanScope>;
  scopePlanScopes?: Maybe<ScopePlanScopePageConnection>;
  scopePlanTemplate?: Maybe<ScopePlan>;
  scopePlanTemplates?: Maybe<ScopePlanPageConnection>;
  scopePlans?: Maybe<ScopePlanPageConnection>;
  searchCalendar?: Maybe<Calendar>;
  userCalendar?: Maybe<UserCalendar>;
  userCalendars?: Maybe<UserCalendarPageConnection>;
  userSelection?: Maybe<UserSelection>;
  userSelections?: Maybe<UserSelectionPageConnection>;
  studentAssignmentsMock: StudentAssignmentMockPageConnection;
  district?: Maybe<District>;
  districtSetupStatus?: Maybe<DistrictSetupStatus>;
  districts?: Maybe<DistrictPageConnection>;
  lookupDistricts?: Maybe<Array<Maybe<District>>>;
  lookupSchools?: Maybe<Array<Maybe<School>>>;
  school?: Maybe<School>;
  schools?: Maybe<SchoolPageConnection>;
  publicDistrict: NewDistrict;
  newDistricts: NewDistrictPageConnection;
  passwordRecoveryToken?: Maybe<PasswordRecoveryToken>;
  previewAccountDistricts: NewDistrictPageConnection;
  publicAdoptionAccount: PublicAdoptionAccount;
  systemAdmins: SystemAdminPageConnection;
  /** @deprecated Used to display enum in the schema */
  accesses?: Maybe<Array<Maybe<Access>>>;
  class?: Maybe<Class>;
  classStudents?: Maybe<ClassStudentPageConnection>;
  classTeachers?: Maybe<ClassTeacherPageConnection>;
  classes?: Maybe<ClassPageConnection>;
  districtSettingsRoster?: Maybe<DistrictSettingsRoster>;
  lookupRosterStudents?: Maybe<Array<Maybe<Student>>>;
  student?: Maybe<Student>;
  studentSubgroup?: Maybe<StudentSubgroup>;
  teacher?: Maybe<Teacher>;
  teacherStudents?: Maybe<StudentPageConnection>;
  teachers?: Maybe<TeacherPageConnection>;
  applicationSettings?: Maybe<ApplicationSettings>;
  activeContentGroups: Array<ContentGroup>;
  activeContentGroupsWithSchools: Array<ContentGroupWithSchoolPayload>;
  /** Use only for merging data! */
  lookupProducts: Array<Product>;
  order: Order;
  orders: OrderPageConnection;
  product: Product;
  products: ProductPageConnection;
  subscription: Subscription;
  subscriptionPackage: SubscriptionPackage;
  subscriptionPackages: SubscriptionPackagePageConnection;
  subscriptions: SubscriptionPageConnection;
  team: Team;
  teams: TeamPageConnection;
  uptime: Scalars['Float'];
  districtSettingsUserManagement?: Maybe<DistrictSettingsUserManagement>;
  identifyUsers?: Maybe<Array<Maybe<User>>>;
  lookupStudents?: Maybe<Array<Maybe<Student>>>;
  lookupTeachers?: Maybe<Array<Maybe<Teacher>>>;
  lookupUsers?: Maybe<Array<Maybe<User>>>;
  parentStudents?: Maybe<Array<Maybe<ParentStudent>>>;
  parents?: Maybe<Array<Maybe<Parent>>>;
  user?: Maybe<User>;
  userRoles?: Maybe<UserRolePageConnection>;
  users?: Maybe<UserPageConnection>;
  readerLibraries: ReaderLibraryPageConnection;
  readerLibrary: ReaderLibraryPayload;
  stemscopesMedia: StemscopesMediaPayload;
  stemscopesMediaPack: ReadStemscopesMediaPackPayload;
  stemscopesMediaPacks: StemscopesMediaPackPageConnection;
  stemscopesMedias: StemscopesMediaPageConnection;
  streamingVideo: StreamingVideoPayload;
  streamingVideos: StreamingVideoPageConnection;
  tutor: TutorPayload;
  tutors: TutorPageConnection;
  video: VideoPayload;
  videos: UnionVideoPdfPageConnection;
  videosByGroup: UnionVideoPdfPageConnection;
  /** Init science Bot to get the conversation id */
  initScienceBot: ScienceBotPayload;
  /** Get all the conversation by ID */
  scienceBots: Array<ScienceBotPayload>;
  note: NotePayload;
  academicStandards: AcademicStandardsPayload;
  adminKnowledgeTree: KnowledgeTreePayload;
  benchmarkList: BenchmarkObjectsPayload;
  createTeacherAssignment: TeacherAssignmentPayload;
  knowledgeTreeData: KnowledgeTreePayload;
  /** After complete StartUp, prepare progress */
  onrampStartUpCompleted: StudentResponsePayload;
  /** Init start up test */
  onrampStartUpTest: SignRequestStartUpPayload;
  /** Onramp Teacher Assignment */
  onrampStudentAssignment: StudentAssignmentPayload;
  /** Onramp Student dashboard */
  onrampStudentDashboard: StudentPayload;
  onrampStudentStatus: OnrampStatusPayload;
  onrampTeacherAssignmentsList: TeacherAssignmentListPayload;
  standardList: StandardListPayload;
  standardsByName: Array<Standards>;
  /** Onramp Student Certificate */
  studentCertificate: StudentCertificatePayload;
  teacherStandardList: StandardListPayload;
  karmaAwards?: Maybe<KarmaAwardPageConnection>;
  posts?: Maybe<PostPageConnection>;
  s3UploadSignedUrl?: Maybe<S3UploadSignedUrl>;
  unfilteredPosts?: Maybe<PostPageConnection>;
  wallAgeControls?: Maybe<Array<Maybe<WallAgeControl>>>;
  wallControls?: Maybe<Array<Maybe<WallControl>>>;
  wallPostLikes?: Maybe<Array<Maybe<WallPostLike>>>;
  wallPostList: WallPostConnect;
  standards: Array<Standard>;
  aiChat: AiChatHistoryPayload;
  districtSettingsKnowledgeBase: DistrictSettingsKnowledgeBase;
  wallDisplayNameList: WallListConnect;
  emailTemplate: EmailTemplate;
  emailTemplateAttachmentUploadForm: EmailTemplateAttachmentUploadFormPayload;
  emailTemplateImageUploadForm: EmailTemplateImageUploadForm;
  emailTemplatePlaceholders: EmailTemplatePlaceholdersPayload;
  emailTemplates: EmailTemplatePageConnection;
  fromEmails: StringPageConnection;
  canvasConfig: CanvasConfig;
  canvasSsoConfig: CanvasSsoConfig;
  listLti: Array<Lti>;
  listSso: Array<Sso>;
  readLti: Lti;
  readSso: Sso;
  askChatbot?: Maybe<ChatbotResponse>;
  getQuestionHint?: Maybe<GetQuestionHintResponse>;
  generateQuestion?: Maybe<GenerateQuestionResponse>;
  /** A placeholder query used by thecodingmachine/graphqlite when there are no declared queries. */
  dummyQuery?: Maybe<Scalars['String']>;
  athenaDistricts?: Maybe<AthenaDistrictPageConnection>;
  mnCoursework?: Maybe<MnCoursework>;
  mnOnRamp?: Maybe<MnOnRamp>;
  ssStandards?: Maybe<SsStandards>;
};

export type QueryAssignmentArgs = {
  id?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryAssignmentsArgs = {
  archiveStatus?: InputMaybe<ArchiveStatuses>;
  archived?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssignmentsFilter>;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryBaseDistrictAssignmentsArgs = {
  paginationInfo?: InputMaybe<DistrictAssignmentPageInfoInput>;
};

export type QueryDistrictAssignmentsArgs = {
  filter?: InputMaybe<DistrictAssignmentsFilter>;
};

export type QueryDistrictAssignmentsForLeaderArgs = {
  filter: LeaderDistrictAssignmentsFilter;
};

export type QueryDistrictStudentAssignmentsArgs = {
  classId?: InputMaybe<Scalars['String']>;
  schoolId: Scalars['String'];
  studentId?: InputMaybe<Scalars['String']>;
};

export type QueryEdgexlAssignmentArgs = {
  id: Scalars['String'];
};

export type QueryEdgexlAssignmentsArgs = {
  assessmentId?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryEdgexlAssignmentsProgressArgs = {
  params: EdgexlAssignmentsProgressInfoInput;
};

export type QueryEdgexlStudentAssignmentsArgs = {
  studentId: Scalars['String'];
};

export type QueryMnPersonalAssignmentAttemptsArgs = {
  assignmentSource: AssignmentSourceInput;
  assignmentType: MnPersonalAssignmentType;
};

export type QueryOwnerAssignmentsArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  assignmentDateFilter?: InputMaybe<AssignmentDateFilterInput>;
  classId?: InputMaybe<Scalars['String']>;
  endDateFilter?: InputMaybe<AssignmentDateFilterInput>;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryStudentNotebookArgs = {
  classId: Scalars['String'];
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryStudentNotebooksArgs = {
  schoolId?: InputMaybe<Scalars['String']>;
  studentId?: InputMaybe<Scalars['String']>;
};

export type QueryStudentResourcesArgs = {
  filter?: InputMaybe<StudentResourcesFilter>;
  schoolId: Scalars['String'];
};

export type QueryStudentStudentAssignmentsArgs = {
  filter?: InputMaybe<StudentAssignmentsFilter>;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryTeacherFeedbackArgs = {
  sessionId: Scalars['String'];
};

export type QueryTeacherStudentAssignmentsArgs = {
  filter?: InputMaybe<StudentAssignmentsFilter>;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryAssessmentArgs = {
  input: AssessmentInput;
};

export type QueryAssessmentLeaderArgs = {
  input: AssessmentInput;
};

export type QueryAssessmentPoolArgs = {
  input: AssessmentPoolInput;
};

export type QueryAssessmentPoolLeaderArgs = {
  input: AssessmentPoolInput;
};

export type QueryPoolEnumsArgs = {
  purpose?: InputMaybe<PurposeEnum>;
  input?: InputMaybe<PurposeEnumInput>;
};

export type QueryPoolQuestionTotalArgs = {
  purpose: PurposeEnum;
};

export type QueryQuestionArgs = {
  input: QuestionInput;
};

export type QueryQuestionPoolArgs = {
  input: QuestionPoolInput;
};

export type QueryQuestionPoolLeaderArgs = {
  input: QuestionPoolInput;
};

export type QueryQuestionsArgs = {
  input: QuestionsInput;
};

export type QueryContentArgs = {
  id: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type QueryContentGroupArgs = {
  id: Scalars['ID'];
};

export type QueryContentGroupsArgs = {
  filter?: InputMaybe<ContentGroupFilterInput>;
  order?: InputMaybe<ContentGroupOrderInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryContentsArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryTccPackageDownloadLinkArgs = {
  id: Scalars['String'];
};

export type QueryTccPackagesArgs = {
  districtId: Scalars['String'];
  ltiConsumer: TccConsumerEnum;
};

export type QueryTccPackagesCountArgs = {
  districtId: Scalars['String'];
};

export type QueryBaseScopeArgs = {
  id: Scalars['String'];
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryCurriculumAreasArgs = {
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryDigitalNotebookCoversArgs = {
  filter?: InputMaybe<DigitalNotebookCoverFilter>;
};

export type QueryElementsArgs = {
  filter?: InputMaybe<ElementFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryGradesArgs = {
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryHelpPageArgs = {
  input: HelpPageInput;
};

export type QueryMnGradesArgs = {
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryMnScopeArgs = {
  id: Scalars['String'];
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryMnScopesArgs = {
  filter?: InputMaybe<MathNationScopeFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  schoolId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type QueryRecentlyViewedArgs = {
  schoolId: Scalars['String'];
};

export type QueryScopeArgs = {
  id: Scalars['String'];
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryScopesArgs = {
  filter?: InputMaybe<ScopeFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  schoolIds?: Array<Scalars['String']>;
};

export type QueryStreamingArgs = {
  schoolId: Scalars['String'];
  id: Scalars['String'];
};

export type QueryStreamingContentTypesArgs = {
  schoolId: Scalars['String'];
};

export type QueryStreamingCurriculumAreasArgs = {
  schoolId: Scalars['String'];
};

export type QueryStreamingGradesArgs = {
  schoolId: Scalars['String'];
};

export type QueryStreamingTagsArgs = {
  schoolId: Scalars['String'];
};

export type QueryStreamingTopicsArgs = {
  schoolId: Scalars['String'];
};

export type QueryStreamingsArgs = {
  schoolId: Scalars['String'];
  filter?: InputMaybe<StreamingFilter>;
  search?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryTeacherResourceArgs = {
  id: Scalars['String'];
  schoolId: Scalars['String'];
};

export type QueryTeacherResourcesArgs = {
  schoolId?: InputMaybe<Scalars['String']>;
  schoolIds?: Array<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type QueryTopicsArgs = {
  schoolId?: InputMaybe<Scalars['String']>;
  schoolIds?: Array<Scalars['String']>;
};

export type QueryWidgetArgs = {
  id: Scalars['String'];
};

export type QueryDashboardEmbedUrlArgs = {
  dashboardID: Scalars['String'];
  sheetID?: InputMaybe<Scalars['String']>;
  visualID?: InputMaybe<Scalars['String']>;
};

export type QueryDashboardEmbedUrlForAnonymousUserArgs = {
  dashboardID: Scalars['String'];
  sheetID?: InputMaybe<Scalars['String']>;
  visualID?: InputMaybe<Scalars['String']>;
};

export type QueryAdoptionAccountArgs = {
  id: Scalars['ID'];
};

export type QueryAdoptionAccountsArgs = {
  filter?: InputMaybe<AdoptionAccountFilterInput>;
  order?: InputMaybe<AdoptionAccountOrderInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryAdoptionEventArgs = {
  id: Scalars['ID'];
};

export type QueryAdoptionEventsArgs = {
  filter?: InputMaybe<AdoptionEventFilterInput>;
  order?: InputMaybe<AdoptionEventOrderInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryAdoptionReviewerArgs = {
  id: Scalars['ID'];
};

export type QueryAdoptionReviewersArgs = {
  itemsPerPage: Scalars['Int'];
  order?: InputMaybe<AdoptionReviewerOrderInput>;
  page: Scalars['Int'];
};

export type QueryLatestPreviewAccountImportLogArgs = {
  type: PreviewAccountImportLogTypeEnum;
};

export type QueryPreviewAccountArgs = {
  id: Scalars['ID'];
};

export type QueryPreviewAccountImportLogArgs = {
  id: Scalars['ID'];
};

export type QueryPreviewAccountImportLogsArgs = {
  type: PreviewAccountImportLogTypeEnum;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryPreviewAccountTeacherArgs = {
  id: Scalars['ID'];
};

export type QueryPreviewAccountTeachersArgs = {
  filter?: InputMaybe<PreviewAccountTeacherFilterInput>;
  itemsPerPage: Scalars['Int'];
  order?: InputMaybe<PreviewAccountTeacherOrderInput>;
  page: Scalars['Int'];
};

export type QueryPreviewAccountsArgs = {
  filter?: InputMaybe<PreviewAccountFilterInput>;
  order?: InputMaybe<PreviewAccountOrderInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryProductGroupArgs = {
  id: Scalars['ID'];
};

export type QueryProductGroupsArgs = {
  filter?: InputMaybe<ProductGroupFilterInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryNodeArgs = {
  id: Scalars['ID'];
};

export type QueryAccessPoliciesArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryAccessPolicyArgs = {
  id: Scalars['ID'];
};

export type QueryLookupRolesArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryRoleArgs = {
  id: Scalars['ID'];
};

export type QueryRolesArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryIconArgs = {
  id: Scalars['ID'];
};

export type QueryIconsArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  filter?: InputMaybe<FilterIconInput>;
};

export type QueryLookupIconsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryImageArgs = {
  id: Scalars['ID'];
};

export type QueryImageCategoriesArgs = {
  filter?: InputMaybe<ImageCategoryFilterInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryImageCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryImageFileUploadFormArgs = {
  input: ReadImageFileUploadFormInput;
};

export type QueryImageKeywordArgs = {
  id: Scalars['ID'];
};

export type QueryImageKeywordsArgs = {
  filter?: InputMaybe<ImageKeywordFilterInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryImageTagArgs = {
  id: Scalars['ID'];
};

export type QueryImageTagValueArgs = {
  id: Scalars['ID'];
};

export type QueryImageTagValuesArgs = {
  filter: ImageTagValueFilterInput;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryImageTagsArgs = {
  filter?: InputMaybe<ImageTagFilterInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryImagesArgs = {
  filter?: InputMaybe<ImageFilterInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryCleverSchoolsArgs = {
  districtId: Scalars['String'];
};

export type QueryEdfiGradeLevelsArgs = {
  configInput: IntegrationConfigInput;
};

export type QueryEdfiJobRolesArgs = {
  configInput: IntegrationConfigInput;
};

export type QueryEdfiSchoolsArgs = {
  configInput: IntegrationConfigInput;
};

export type QueryImportResultArgs = {
  input: ImportResultInput;
};

export type QueryImportResultErrorsArgs = {
  input: ImportResultErrorInput;
};

export type QueryImportResultErrorsLinkArgs = {
  input: ImportResultErrorsLinkInput;
};

export type QueryImportResultsArgs = {
  integrationInput?: InputMaybe<IntegrationKeyInput>;
};

export type QueryIntegrationArgs = {
  integrationInput: IntegrationKeyInput;
};

export type QueryIntegrationsArgs = {
  input?: InputMaybe<IntegrationsInput>;
};

export type QueryOneRosterRestOauth2SchoolsArgs = {
  configInput: IntegrationConfigInput;
};

export type QueryOneRosterRestSchoolsArgs = {
  configInput: IntegrationConfigInput;
};

export type QueryGetReportArgs = {
  containerId: Scalars['String'];
  activityId?: InputMaybe<Scalars['String']>;
  activities?: InputMaybe<Scalars['Iterable']>;
  users?: InputMaybe<Scalars['Iterable']>;
  userId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  displayUser?: InputMaybe<Scalars['Boolean']>;
  type: Scalars['String'];
  sessionId?: InputMaybe<Scalars['String']>;
};

export type QueryGetSignedRequestsArgs = {
  parameter?: InputMaybe<Array<InputMaybe<InputRequest>>>;
  view?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryCalendarArgs = {
  id: Scalars['ID'];
};

export type QueryCalendarTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryCalendarTemplatesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CalendarFilter_Order>>>;
  calendarType?: InputMaybe<CalendarTypeEnum>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<InputMaybe<CalendarTemplateFilter>>>;
};

export type QueryCalendarsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CalendarFilter_Order>>>;
  calendarId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  calendarType?: InputMaybe<CalendarTypeEnum>;
  teamId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Array<InputMaybe<CalendarFilter>>>;
};

export type QueryEntriesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  entryType?: InputMaybe<EntryTypeEnum>;
  filter?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
};

export type QueryEntryArgs = {
  id: Scalars['ID'];
};

export type QueryPacingGuideArgs = {
  id: Scalars['ID'];
};

export type QueryPacingGuideScopeArgs = {
  id: Scalars['ID'];
};

export type QueryPacingGuideScopesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  pacingGuideId?: InputMaybe<Scalars['String']>;
  scopeId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Array<InputMaybe<PacingGuideScopeFilter>>>;
};

export type QueryPacingGuideTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryPacingGuideTemplatesArgs = {
  name?: InputMaybe<Scalars['String']>;
  curriculumAreas?: InputMaybe<Scalars['Iterable']>;
  grades?: InputMaybe<Scalars['Iterable']>;
  sharedType?: InputMaybe<Scalars['String']>;
  pacingGuideType?: InputMaybe<PacingGuideTypeEnum>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

export type QueryPacingGuidesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  curriculumArea?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PacingGuideFilter_Order>>>;
  pacingGuideId?: InputMaybe<Scalars['String']>;
  pacingGuideType?: InputMaybe<PacingGuideTypeEnum>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  teamId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Array<InputMaybe<PacingGuideFilter>>>;
};

export type QueryPlannerTeamsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

export type QueryScopePlanArgs = {
  id: Scalars['ID'];
};

export type QueryScopePlanScopeArgs = {
  id: Scalars['ID'];
};

export type QueryScopePlanScopesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  scopePlanId?: InputMaybe<Scalars['String']>;
  scopeId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Array<InputMaybe<ScopePlanScopeFilter>>>;
};

export type QueryScopePlanTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryScopePlanTemplatesArgs = {
  name?: InputMaybe<Scalars['String']>;
  curriculumAreas?: InputMaybe<Scalars['Iterable']>;
  grades?: InputMaybe<Scalars['Iterable']>;
  scopePlanType?: InputMaybe<ScopePlanTypeEnum>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

export type QueryScopePlansArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  curriculumArea?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ScopePlanFilter_Order>>>;
  scopePlanId?: InputMaybe<Scalars['String']>;
  scopePlanType?: InputMaybe<ScopePlanTypeEnum>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  teamId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Array<InputMaybe<ScopePlanFilter>>>;
};

export type QuerySearchCalendarArgs = {
  calendarType?: InputMaybe<CalendarTypeEnum>;
  calendarName?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
};

export type QueryUserCalendarArgs = {
  id: Scalars['ID'];
};

export type QueryUserCalendarsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
  accessType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<UserCalendarFilter_Order>>>;
  calendarId?: InputMaybe<Scalars['String']>;
  calendarType?: InputMaybe<CalendarTypeEnum>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Array<InputMaybe<UserCalendarFilter>>>;
};

export type QueryUserSelectionArgs = {
  id: Scalars['ID'];
};

export type QueryUserSelectionsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

export type QueryStudentAssignmentsMockArgs = {
  classId: Scalars['String'];
};

export type QueryDistrictArgs = {
  id: Scalars['ID'];
};

export type QueryDistrictsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<DistrictFilter_Order>>>;
  id?: InputMaybe<Scalars['String']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<StateEnum>;
  filter?: InputMaybe<Array<InputMaybe<DistrictFilter>>>;
};

export type QueryLookupDistrictsArgs = {
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryLookupSchoolsArgs = {
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySchoolArgs = {
  id: Scalars['ID'];
};

export type QuerySchoolsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  districtName?: InputMaybe<Scalars['String']>;
  districtState?: InputMaybe<StateEnum>;
  districtId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SchoolFilter_Order>>>;
  filter?: InputMaybe<Array<InputMaybe<SchoolFilter>>>;
};

export type QueryPublicDistrictArgs = {
  id: Scalars['ID'];
};

export type QueryNewDistrictsArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<StateEnum>;
};

export type QueryPasswordRecoveryTokenArgs = {
  id: Scalars['ID'];
};

export type QueryPreviewAccountDistrictsArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryPublicAdoptionAccountArgs = {
  id: Scalars['ID'];
};

export type QuerySystemAdminsArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  districtUuid: Scalars['String'];
};

export type QueryClassArgs = {
  id: Scalars['ID'];
};

export type QueryClassStudentsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
};

export type QueryClassTeachersArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
};

export type QueryClassesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Array<InputMaybe<ClassFilter_Order>>>;
  hasLmsId?: InputMaybe<Scalars['Boolean']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<StatusEnum>;
  studentsId?: InputMaybe<Scalars['String']>;
  teachersId?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<InputMaybe<ClassFilter>>>;
};

export type QueryLookupRosterStudentsArgs = {
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryStudentArgs = {
  id: Scalars['ID'];
};

export type QueryStudentSubgroupArgs = {
  id: Scalars['ID'];
};

export type QueryTeacherArgs = {
  id: Scalars['ID'];
};

export type QueryTeacherStudentsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
  schoolId?: InputMaybe<Scalars['String']>;
  teachersId?: InputMaybe<Scalars['String']>;
  classIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  studentHasArchivedClasses?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Array<InputMaybe<TeacherStudentFilter>>>;
};

export type QueryTeachersArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  schoolId?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<InputMaybe<TeacherFilter>>>;
};

export type QueryActiveContentGroupsArgs = {
  filter?: InputMaybe<ContentGroupFilterInput>;
};

export type QueryLookupProductsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryOrderArgs = {
  id: Scalars['ID'];
};

export type QueryOrdersArgs = {
  filter?: InputMaybe<OrderFilterInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryProductArgs = {
  id: Scalars['ID'];
};

export type QueryProductsArgs = {
  filter?: InputMaybe<ProductFilterInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QuerySubscriptionArgs = {
  id: Scalars['ID'];
};

export type QuerySubscriptionPackageArgs = {
  id: Scalars['ID'];
};

export type QuerySubscriptionPackagesArgs = {
  filter?: InputMaybe<SubscriptionPackageFilterInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QuerySubscriptionsArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryTeamArgs = {
  id: Scalars['ID'];
};

export type QueryTeamsArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  filter?: InputMaybe<FilterTeamInput>;
};

export type QueryIdentifyUsersArgs = {
  districtId?: InputMaybe<Scalars['String']>;
  lmsId?: InputMaybe<Scalars['String']>;
  sisId?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<InputMaybe<IdentifyUserFilter>>>;
};

export type QueryLookupStudentsArgs = {
  id_list: Array<InputMaybe<Scalars['String']>>;
};

export type QueryLookupTeachersArgs = {
  id_list: Array<InputMaybe<Scalars['String']>>;
};

export type QueryLookupUsersArgs = {
  id_list: Array<InputMaybe<Scalars['String']>>;
};

export type QueryParentsArgs = {
  studentId: Scalars['String'];
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryUserRolesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

export type QueryUsersArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
  classesArchived?: InputMaybe<Scalars['Boolean']>;
  classesCurriculumArea?: InputMaybe<Scalars['String']>;
  classesGradeOrCourse?: InputMaybe<Scalars['String']>;
  classesId?: InputMaybe<Scalars['String']>;
  classesLanguage?: InputMaybe<Scalars['String']>;
  classesName?: InputMaybe<Scalars['String']>;
  curriculumArea?: InputMaybe<CurriculumAreaEnum>;
  districtId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  excludeCurrent?: InputMaybe<Scalars['Boolean']>;
  exclude?: InputMaybe<Array<InputMaybe<UserFilter_Exclude>>>;
  externalSystemName?: InputMaybe<ExternalSystemNameEnum>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  fullNameOrUsername?: InputMaybe<Scalars['String']>;
  gradeLevel?: InputMaybe<GradeLevelFilterEnum>;
  highestRoleId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lmsId?: InputMaybe<Scalars['String']>;
  rolesId?: InputMaybe<Scalars['String']>;
  schoolsId?: InputMaybe<Scalars['String']>;
  schoolsIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  sisId?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  subgroupsId?: InputMaybe<Scalars['String']>;
  subgroupsName?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<UserFilter_Order>>>;
  type?: InputMaybe<UserTypeEnum>;
  username?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<InputMaybe<UserFilter>>>;
};

export type QueryReaderLibrariesArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  filter?: InputMaybe<ReaderLibraryFilterInput>;
};

export type QueryReaderLibraryArgs = {
  id: Scalars['ID'];
};

export type QueryStemscopesMediaArgs = {
  id: Scalars['ID'];
};

export type QueryStemscopesMediaPackArgs = {
  id: Scalars['ID'];
};

export type QueryStemscopesMediaPacksArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  filter?: InputMaybe<StemscopesMediaPackFilterInput>;
};

export type QueryStemscopesMediasArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  filter?: InputMaybe<StemscopesMediaFilterInput>;
};

export type QueryStreamingVideoArgs = {
  id: Scalars['ID'];
};

export type QueryStreamingVideosArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  filter?: InputMaybe<StreamingVideoFilterInput>;
};

export type QueryTutorArgs = {
  id: Scalars['ID'];
};

export type QueryTutorsArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  filter?: InputMaybe<TutorFilterInput>;
};

export type QueryVideoArgs = {
  id: Scalars['ID'];
};

export type QueryVideosArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  filter: VideoFilterInput;
  sort?: InputMaybe<VideoSortInput>;
};

export type QueryVideosByGroupArgs = {
  input: VideoByGroupInput;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryScienceBotsArgs = {
  id: Scalars['String'];
};

export type QueryNoteArgs = {
  id: Scalars['ID'];
};

export type QueryAcademicStandardsArgs = {
  input: AcademicStandardInput;
};

export type QueryAdminKnowledgeTreeArgs = {
  input: AdminKnowledgeTreeInput;
};

export type QueryBenchmarkListArgs = {
  input: BenchmarkInput;
};

export type QueryCreateTeacherAssignmentArgs = {
  input: TeacherAssignmentInput;
};

export type QueryKnowledgeTreeDataArgs = {
  input: KnowledgeTreeInput;
};

export type QueryOnrampStartUpCompletedArgs = {
  currentSchoolId: Scalars['String'];
};

export type QueryOnrampStartUpTestArgs = {
  input: OnrampStartupTestInput;
};

export type QueryOnrampStudentAssignmentArgs = {
  input: OnrampStudentAssignmentInput;
};

export type QueryOnrampStudentDashboardArgs = {
  currentSchoolId: Scalars['String'];
};

export type QueryOnrampStudentStatusArgs = {
  input: OnrampStudentStatusInput;
};

export type QueryOnrampTeacherAssignmentsListArgs = {
  input: TeacherAssignmentListInput;
};

export type QueryStandardListArgs = {
  input: StandardListInput;
};

export type QueryStandardsByNameArgs = {
  standardNames: Array<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type QueryStudentCertificateArgs = {
  input: StudentCertificateInput;
};

export type QueryTeacherStandardListArgs = {
  input: StandardListInput;
};

export type QueryKarmaAwardsArgs = {
  districtId?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  timeRange?: InputMaybe<Scalars['String']>;
  page: Scalars['Int'];
  itemsPerPage: Scalars['Int'];
  currentSchoolId: Scalars['String'];
};

export type QueryPostsArgs = {
  parameter?: InputMaybe<InputPostRequest>;
  page: Scalars['Int'];
  itemsPerPage: Scalars['Int'];
};

export type QueryS3UploadSignedUrlArgs = {
  wallId: Scalars['String'];
  currentSchoolId: Scalars['String'];
  fileName: Scalars['String'];
};

export type QueryUnfilteredPostsArgs = {
  parameter?: InputMaybe<UnfilteredInputPostRequest>;
  page: Scalars['Int'];
  itemsPerPage: Scalars['Int'];
};

export type QueryWallAgeControlsArgs = {
  classId: Scalars['String'];
  currentSchoolId: Scalars['String'];
};

export type QueryWallControlsArgs = {
  currentSchoolId: Scalars['String'];
};

export type QueryWallPostLikesArgs = {
  currentSchoolId: Scalars['String'];
};

export type QueryStandardsArgs = {
  state: Scalars['String'];
  subject: Scalars['String'];
  ids?: InputMaybe<Array<Scalars['String']>>;
  parentIds?: InputMaybe<Array<Scalars['String']>>;
  onlyRoot?: InputMaybe<Scalars['Boolean']>;
};

export type QueryEmailTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryEmailTemplateAttachmentUploadFormArgs = {
  input: ReadEmailTemplateAttachmentUploadFormInput;
};

export type QueryEmailTemplateImageUploadFormArgs = {
  input: ReadEmailTemplateImageUploadFormInput;
};

export type QueryEmailTemplatePlaceholdersArgs = {
  type: EmailTemplateTypeEnum;
};

export type QueryEmailTemplatesArgs = {
  filter?: InputMaybe<EmailTemplateFilterInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryFromEmailsArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryCanvasConfigArgs = {
  districtInput: DistrictInput;
};

export type QueryCanvasSsoConfigArgs = {
  districtInput: DistrictInput;
};

export type QueryListLtiArgs = {
  listInput?: InputMaybe<LtiListInput>;
};

export type QueryListSsoArgs = {
  listInput?: InputMaybe<SsoListInput>;
};

export type QueryReadLtiArgs = {
  ltiKeyInput: LtiKeyInput;
};

export type QueryReadSsoArgs = {
  ssoKeyInput: SsoKeyInput;
};

export type QueryAskChatbotArgs = {
  userId: Scalars['String'];
  query: Scalars['String'];
  threadId?: InputMaybe<Scalars['String']>;
};

export type QueryGetQuestionHintArgs = {
  question: Scalars['String'];
  options?: InputMaybe<Scalars['String']>;
  correctOption?: InputMaybe<Scalars['String']>;
};

export type QueryGenerateQuestionArgs = {
  question: Scalars['String'];
  options?: InputMaybe<Scalars['String']>;
};

export type QueryAthenaDistrictsArgs = {
  filter?: InputMaybe<DistrictFilter>;
  order?: InputMaybe<DistrictSort>;
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

export type QueryMnCourseworkArgs = {
  classId?: InputMaybe<Scalars['String']>;
  districtId?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  schoolId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  teacherId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<MnCourseworkOrderType>;
};

export type QueryMnOnRampArgs = {
  classId?: InputMaybe<Scalars['String']>;
  districtId?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  reportType?: InputMaybe<OnRampReportTypeEnum>;
  state?: InputMaybe<Scalars['String']>;
  teacherId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<MnOnRampOrderType>;
};

export type QuerySsStandardsArgs = {
  classId?: InputMaybe<Scalars['String']>;
  districtId?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<SsStandardsSort>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  schoolId?: InputMaybe<Scalars['String']>;
  standards?: InputMaybe<Scalars['String']>;
  teacherId?: InputMaybe<Scalars['String']>;
  assignmentId?: InputMaybe<Scalars['String']>;
};

export enum QuestionBankEnum {
  Teacher = 'TEACHER',
  District = 'DISTRICT',
  Extra = 'EXTRA',
}

export type QuestionContainerPayload = {
  __typename?: 'QuestionContainerPayload';
  statusPayload?: Maybe<StatusPayload>;
};

export type QuestionInput = {
  platformAdminInput?: InputMaybe<PlatformAdminQuestionInput>;
};

export type QuestionPayload = {
  __typename?: 'QuestionPayload';
  platformAdminPayload?: Maybe<PlatformAdminQuestionPayload>;
};

export type QuestionPool = {
  __typename?: 'QuestionPool';
  categories: Array<Scalars['String']>;
  curriculumAreas: Array<Scalars['String']>;
  grades: Array<Scalars['String']>;
  grading?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lessons: Array<Scalars['String']>;
  editions: Array<Scalars['String']>;
  questionTypes: Array<Scalars['String']>;
  scopes: Array<Scalars['String']>;
  standards: Array<Scalars['String']>;
  units: Array<Scalars['String']>;
};

export type QuestionPoolInput = {
  edgexlInput?: InputMaybe<EdgexlQuestionPoolInput>;
  platformAdminInput?: InputMaybe<PlatformAdminQuestionPoolInput>;
  stemscopesInput?: InputMaybe<StemscopesQuestionPoolInput>;
};

export type QuestionPoolPageConnection = {
  __typename?: 'QuestionPoolPageConnection';
  collection: Array<QuestionPool>;
  paginationInfo?: Maybe<QuestionPoolPaginationInfo>;
};

export type QuestionPoolPaginationInfo = {
  __typename?: 'QuestionPoolPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type QuestionPoolPayload = {
  __typename?: 'QuestionPoolPayload';
  impEdgexlPayload?: Maybe<QuestionPoolPageConnection>;
  impStemscopesPayload?: Maybe<QuestionPoolPageConnection>;
  platformAdminPayload?: Maybe<PlatformAdminQuestionPoolPayload>;
};

export type QuestionsInput = {
  platformAdminInput?: InputMaybe<PlatformAdminQuestionsInput>;
};

export type QuestionsPayload = {
  __typename?: 'QuestionsPayload';
  platformAdminPayload?: Maybe<PlatformAdminQuestionsPayload>;
};

export type ReadEmailTemplateAttachmentUploadFormInput = {
  fileNames: Array<Scalars['String']>;
};

export type ReadEmailTemplateImageUploadFormInput = {
  fileName: Scalars['String'];
};

export type ReaderLibrary = {
  __typename?: 'ReaderLibrary';
  description: Scalars['String'];
  pdfFile: Scalars['String'];
  enPdfFile: Scalars['String'];
  esPdfFile: Scalars['String'];
  title: Scalars['String'];
  id: Scalars['String'];
};

export type ReaderLibraryFilterInput = {
  title?: InputMaybe<Scalars['String']>;
};

export type ReaderLibraryPageConnection = {
  __typename?: 'ReaderLibraryPageConnection';
  collection: Array<ReaderLibrary>;
  paginationInfo?: Maybe<ReaderLibraryPaginationInfo>;
};

export type ReaderLibraryPaginationInfo = {
  __typename?: 'ReaderLibraryPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ReaderLibraryPayload = {
  __typename?: 'ReaderLibraryPayload';
  readerLibrary: ReaderLibrary;
};

export type ReadImageFileUploadFormInput = {
  fileName: Scalars['String'];
};

export type ReadStemscopesMediaPackPayload = {
  __typename?: 'ReadStemscopesMediaPackPayload';
  pack: StemscopesMediaPack;
  items: Array<StemscopesMedia>;
};

export type RecentlyViewed = {
  __typename?: 'RecentlyViewed';
  displayedNames?: Maybe<Array<Scalars['String']>>;
  objectIds: Array<Scalars['String']>;
  projectIds: Array<Scalars['String']>;
};

export type RegisterAdoptionReviewerInput = {
  plainPassword: Scalars['String'];
  registrationToken: Scalars['String'];
  username: Scalars['String'];
};

export type RegisterParentInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  username: Scalars['String'];
  plainPassword: Scalars['String'];
  registrationToken: Scalars['String'];
};

export type RegisterPreviewAccountTeacherInput = {
  plainPassword: Scalars['String'];
  registrationToken: Scalars['String'];
  username: Scalars['String'];
};

/** Removes a Calendar. */
export type RemoveCalendarInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

/** Removes a Calendar. */
export type RemoveCalendarPayload = {
  __typename?: 'removeCalendarPayload';
  calendar?: Maybe<Calendar>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Removes a ClassTeacher. */
export type RemoveClassTeacherInput = {
  classId: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  teacherId: Scalars['String'];
};

/** Removes a ClassTeacher. */
export type RemoveClassTeacherPayload = {
  __typename?: 'removeClassTeacherPayload';
  classTeacher?: Maybe<ClassTeacher>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Removes a Entry. */
export type RemoveEntryInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

/** Removes a Entry. */
export type RemoveEntryPayload = {
  __typename?: 'removeEntryPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  entry?: Maybe<Entry>;
};

/** Removes a PacingGuide. */
export type RemovePacingGuideInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

/** Removes a PacingGuide. */
export type RemovePacingGuidePayload = {
  __typename?: 'removePacingGuidePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  pacingGuide?: Maybe<PacingGuide>;
};

export type RemovePermissionInput = {
  id: Scalars['ID'];
  scopedPermission: ScopedPermissionInput;
};

/** Removes a Post. */
export type RemovePostInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
  id: Scalars['String'];
};

/** Removes a Post. */
export type RemovePostPayload = {
  __typename?: 'removePostPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
};

/** Removes a ScopePlan. */
export type RemoveScopePlanInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

/** Removes a ScopePlan. */
export type RemoveScopePlanPayload = {
  __typename?: 'removeScopePlanPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  scopePlan?: Maybe<ScopePlan>;
};

/** RemoveStudentFroms a Classes. */
export type RemoveStudentFromClassesInput = {
  classesIds: Scalars['Iterable'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  studentId: Scalars['String'];
};

/** RemoveStudentFroms a Classes. */
export type RemoveStudentFromClassesPayload = {
  __typename?: 'removeStudentFromClassesPayload';
  classes?: Maybe<RemoveStudentFromClassesPayloadData>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** RemoveStudentFroms a Classes. */
export type RemoveStudentFromClassesPayloadData = Node & {
  __typename?: 'removeStudentFromClassesPayloadData';
  id: Scalars['ID'];
};

/** RemoveStudentsFroms a Class. */
export type RemoveStudentsFromClassInput = {
  classId: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  studentIds: Scalars['Iterable'];
};

/** RemoveStudentsFroms a Class. */
export type RemoveStudentsFromClassPayload = {
  __typename?: 'removeStudentsFromClassPayload';
  class?: Maybe<Class>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** RemoveStudentsFroms a StudentSubgroup. */
export type RemoveStudentsFromStudentSubgroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  studentIds: Scalars['Iterable'];
  subgroupId: Scalars['String'];
};

/** RemoveStudentsFroms a StudentSubgroup. */
export type RemoveStudentsFromStudentSubgroupPayload = {
  __typename?: 'removeStudentsFromStudentSubgroupPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  studentSubgroup?: Maybe<RemoveStudentsFromStudentSubgroupPayloadData>;
};

/** RemoveStudentsFroms a StudentSubgroup. */
export type RemoveStudentsFromStudentSubgroupPayloadData = Node & {
  __typename?: 'removeStudentsFromStudentSubgroupPayloadData';
  _id: Scalars['String'];
  id: Scalars['ID'];
};

/** RemoveTeachersFroms a StudentSubgroup. */
export type RemoveTeachersFromStudentSubgroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  subgroupId: Scalars['String'];
  teacherIds: Scalars['Iterable'];
};

/** RemoveTeachersFroms a StudentSubgroup. */
export type RemoveTeachersFromStudentSubgroupPayload = {
  __typename?: 'removeTeachersFromStudentSubgroupPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  studentSubgroup?: Maybe<RemoveTeachersFromStudentSubgroupPayloadData>;
};

/** RemoveTeachersFroms a StudentSubgroup. */
export type RemoveTeachersFromStudentSubgroupPayloadData = Node & {
  __typename?: 'removeTeachersFromStudentSubgroupPayloadData';
  _id: Scalars['String'];
  id: Scalars['ID'];
};

/** Reopen assignment input */
export type ReopenAssignmentInput = {
  assignmentId: Scalars['String'];
  endDate: Scalars['ISO8601Date'];
  notes: Scalars['String'];
  studentIds: Array<Scalars['String']>;
};

/** Reopen District Assignment input */
export type ReopenDistrictAssignmentInput = {
  assignmentId: Scalars['String'];
  reopenDate: Scalars['ISO8601Date'];
  studentIds: Array<Scalars['String']>;
};

export type Report = Node & {
  __typename?: 'Report';
  _id: Scalars['String'];
  id: Scalars['ID'];
  signedRequest: Scalars['String'];
};

/** Report Signed Request type */
export type ReportSignedRequest = SignedRequestInterface & {
  __typename?: 'ReportSignedRequest';
  items?: Maybe<Array<Scalars['String']>>;
  message?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  renderingType?: Maybe<Scalars['String']>;
  signedRequest: Scalars['JSON'];
  state?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

/** Report Sign Request Input */
export type ReportSignRequestInput = {
  devDomain?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<Scalars['String']>>;
  referenceId?: InputMaybe<Scalars['String']>;
  renderingType?: InputMaybe<AssignmentLearnosityRenderingType>;
  /** Data for field "reports" in sign */
  reports: Scalars['JSON'];
  schoolId?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<SignAssignmentServices>;
  sessionId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<AssignmentLearnosityState>;
  studentId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AssignmentLearnosityType>;
};

/** Reset assignment input */
export type ResetAssignmentInput = {
  assignmentId: Scalars['String'];
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  notes?: InputMaybe<Scalars['String']>;
  studentIds: Array<Scalars['String']>;
};

export type ResetOnrampStartupTestInput = {
  assignmentId: Scalars['Int'];
  studentGrade: Scalars['String'];
  currentSchoolId: Scalars['String'];
};

/** ResetPasswordSetTimes a User. */
export type ResetPasswordSetTimeUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  passwordSetTime?: InputMaybe<Scalars['DateTime']>;
  userIds: Scalars['Iterable'];
};

/** ResetPasswordSetTimes a User. */
export type ResetPasswordSetTimeUserPayload = {
  __typename?: 'resetPasswordSetTimeUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type ResetPreviewAccountActivityPeriodEndInput = {
  activityPeriodEnd?: InputMaybe<Scalars['DateTime']>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ResetStudentStartupPayload = {
  __typename?: 'ResetStudentStartupPayload';
  status: Scalars['String'];
};

/** Resource Object Type */
export type Resource = {
  __typename?: 'Resource';
  classId: Scalars['String'];
  labels?: Maybe<Array<Scalars['String']>>;
  learnosityReferenceId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  originLearnosityId?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  resourceId: Scalars['String'];
  resourceSource: ResourceSource;
  schoolId?: Maybe<Scalars['String']>;
  status: AssignmentStatus;
  studentIds?: Maybe<Array<Scalars['String']>>;
  subgroupIds?: Maybe<Array<Scalars['String']>>;
};

/** ResourceSource Object Type */
export type ResourceSource = {
  __typename?: 'ResourceSource';
  element?: Maybe<Element>;
};

/** ResourceSourceInput */
export type ResourceSourceInput = {
  element?: InputMaybe<ElementInput>;
};

export type ResponseFeedback = {
  __typename?: 'ResponseFeedback';
  feedback: Scalars['String'];
  responseId: Scalars['String'];
};

/** Response feedback input */
export type ResponseFeedbackInput = {
  feedback: Scalars['String'];
  responseId: Scalars['String'];
};

/** RestrictInvitations a Parent. */
export type RestrictInvitationParentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  parentId: Scalars['String'];
  studentId: Scalars['String'];
};

/** RestrictInvitations a Parent. */
export type RestrictInvitationParentPayload = {
  __typename?: 'restrictInvitationParentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  parent?: Maybe<RestrictInvitationParentPayloadData>;
};

/** RestrictInvitations a Parent. */
export type RestrictInvitationParentPayloadData = Node & {
  __typename?: 'restrictInvitationParentPayloadData';
  id: Scalars['ID'];
};

/** Return assignment input */
export type ReturnAssignmentInput = {
  assignmentId: Scalars['String'];
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  notes: Scalars['String'];
  studentIds: Array<Scalars['String']>;
};

/** Use only for merging data! */
export type Role = Node & {
  __typename?: 'Role';
  code?: Maybe<RoleCodeEnum>;
  id: Scalars['ID'];
  name: Scalars['String'];
  policies: Array<AccessPolicy>;
  _id: Scalars['String'];
};

export enum RoleCodeEnum {
  Adoptions = 'Adoptions',
  AdoptionContentReviewer = 'AdoptionContentReviewer',
  AdoptionReviewer = 'AdoptionReviewer',
  CampusCurriculumLeader = 'CampusCurriculumLeader',
  CampusLeader = 'CampusLeader',
  ContentAuthor = 'ContentAuthor',
  CustomerSupport = 'CustomerSupport',
  DistrictAdmin = 'DistrictAdmin',
  DistrictCurriculumLeader = 'DistrictCurriculumLeader',
  DistrictLeader = 'DistrictLeader',
  Finance = 'Finance',
  Parent = 'Parent',
  PlatformAdmin = 'PlatformAdmin',
  Student = 'Student',
  SchoolAdmin = 'SchoolAdmin',
  SystemAdmin = 'SystemAdmin',
  Teacher = 'Teacher',
  WallWatcher = 'WallWatcher',
}

export type RolePageConnection = {
  __typename?: 'RolePageConnection';
  collection: Array<Role>;
  paginationInfo?: Maybe<RolePaginationInfo>;
};

export type RolePaginationInfo = {
  __typename?: 'RolePaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type RolePayload = {
  __typename?: 'RolePayload';
  role: Role;
};

export type S3UploadSignedUrl = Node & {
  __typename?: 's3UploadSignedUrl';
  _id: Scalars['String'];
  formAttributes: Scalars['Iterable'];
  formInputs: Scalars['Iterable'];
  id: Scalars['ID'];
};

/** Save grading session input */
export type SaveGradingSessionInput = {
  responseFeedbacks?: InputMaybe<Array<ResponseFeedbackInput>>;
  responses?: InputMaybe<Array<Scalars['JSON']>>;
  sessionId: Scalars['String'];
  userId: Scalars['String'];
};

export type School = Node & {
  __typename?: 'School';
  id: Scalars['ID'];
  /** Use only for merging data! */
  _id: Scalars['ID'];
  city?: Maybe<Scalars['String']>;
  district: District;
  /** @deprecated Use from District */
  districtId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  externalSystem?: Maybe<Scalars['String']>;
  globalName: Scalars['String'];
  localName: Scalars['String'];
  mathNationId?: Maybe<Scalars['String']>;
  /** @deprecated The field has been renamed to globalName */
  name: Scalars['String'];
  ncesId?: Maybe<Scalars['String']>;
  sisId?: Maybe<Scalars['String']>;
};

export type SchoolFilter = {
  city?: InputMaybe<Scalars['String']>;
  districtId?: InputMaybe<Scalars['String']>;
  districtName?: InputMaybe<Scalars['String']>;
  districtState?: InputMaybe<StateEnum>;
  id?: InputMaybe<Scalars['String']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SchoolFilter_Order>>>;
};

export type SchoolFilter_Order = {
  city?: InputMaybe<SortEnum>;
  district_globalName?: InputMaybe<SortEnum>;
  district_state?: InputMaybe<SortEnum>;
  globalName?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
};

export type SchoolGradeLevels = {
  __typename?: 'SchoolGradeLevels';
  gradeLevels?: Maybe<Array<Scalars['String']>>;
  schoolId?: Maybe<Scalars['String']>;
};

export type SchoolGradeLevelsInput = {
  gradeLevels: Array<Scalars['String']>;
  schoolId: Scalars['String'];
};

/** Page connection for School. */
export type SchoolPageConnection = {
  __typename?: 'SchoolPageConnection';
  collection?: Maybe<Array<Maybe<School>>>;
  paginationInfo: SchoolPaginationInfo;
};

/** Information about the pagination. */
export type SchoolPaginationInfo = {
  __typename?: 'SchoolPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type ScienceBotPayload = {
  __typename?: 'ScienceBotPayload';
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type Scope = {
  __typename?: 'Scope';
  active?: Maybe<Scalars['Boolean']>;
  banner: Scalars['String'];
  contentGroups: Array<Scalars['String']>;
  contentLocation: Scalars['String'];
  created: Scalars['String'];
  displayName: Scalars['String'];
  editionName: Scalars['String'];
  elementTitles: Array<Scalars['String']>;
  home: Scalars['JSON'];
  id: Scalars['String'];
  image: Scalars['String'];
  internalName: Scalars['String'];
  lastUpdated: Scalars['String'];
  launchUrl: Scalars['String'];
  metadata: Scalars['JSON'];
  platform: Scalars['String'];
  positionNumber: Scalars['Int'];
  projectId: Scalars['String'];
  projectType: Scalars['String'];
  sections: Array<Scalars['JSON']>;
  state: Scalars['String'];
  tags: Array<Scalars['String']>;
  taxonomy: Array<Scalars['JSON']>;
  version: Scalars['Int'];
};

export type ScopedPermission = {
  __typename?: 'ScopedPermission';
  permission: Scalars['String'];
  scope: PermissionScope;
};

export type ScopedPermissionInput = {
  permission: Scalars['String'];
  scope: PermissionScope;
};

export type ScopeFilter = {
  curriculumArea?: InputMaybe<Array<Scalars['String']>>;
  editionName?: InputMaybe<Scalars['String']>;
  gradeLevel?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Array<Scalars['String']>>;
  instructionalDays?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  topic?: InputMaybe<Array<Scalars['String']>>;
};

export type ScopeGroup = {
  __typename?: 'ScopeGroup';
  id: Scalars['ID'];
  /** Use only for merging data! */
  _id: Scalars['ID'];
};

export type ScopePlan = Node & {
  __typename?: 'ScopePlan';
  _id: Scalars['String'];
  approachType?: Maybe<ScopePlanApproachEnum>;
  assignedTo?: Maybe<Scalars['String']>;
  calendarId?: Maybe<Scalars['String']>;
  calendarType?: Maybe<CalendarTypeEnum>;
  classSize?: Maybe<Scalars['Int']>;
  classTime?: Maybe<Scalars['Int']>;
  createTime?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  curriculumArea?: Maybe<Scalars['String']>;
  editable?: Maybe<Scalars['Boolean']>;
  grade?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isSelected?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pacingGuideId?: Maybe<Scalars['String']>;
  pk: Scalars['String'];
  schoolIds?: Maybe<Scalars['Iterable']>;
  scopePlanId?: Maybe<Scalars['String']>;
  scopePlanType?: Maybe<ScopePlanTypeEnum>;
  scopes?: Maybe<ScopePlanScopePageConnection>;
  searchName?: Maybe<Scalars['String']>;
  sharedBy?: Maybe<Scalars['String']>;
  sharedData?: Maybe<Scalars['Iterable']>;
  sharedOption?: Maybe<Scalars['String']>;
  sk: Scalars['String'];
  teacherExp?: Maybe<Scalars['Int']>;
  updateTime?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userRoleCode?: Maybe<Scalars['String']>;
  userType?: Maybe<UsersTypeEnum>;
  version?: Maybe<Scalars['Int']>;
};

export type ScopePlanScopesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  scopePlanId?: InputMaybe<Scalars['String']>;
  scopeId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Array<InputMaybe<ScopePlanScopeFilter>>>;
};

/** Scope Plan type: Adaptive / Traditional */
export enum ScopePlanApproachEnum {
  /** Adaptive Type */
  Adaptive = 'Adaptive',
  /** Traditional Type */
  Traditional = 'Traditional',
}

export type ScopePlanFilter = {
  curriculumArea?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  scopePlanId?: InputMaybe<Scalars['String']>;
  scopePlanType?: InputMaybe<ScopePlanTypeEnum>;
  teamId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ScopePlanFilter_Order = {
  name?: InputMaybe<SortEnum>;
};

/** Page connection for ScopePlan. */
export type ScopePlanPageConnection = {
  __typename?: 'ScopePlanPageConnection';
  collection?: Maybe<Array<Maybe<ScopePlan>>>;
  paginationInfo: ScopePlanPaginationInfo;
};

/** Information about the pagination. */
export type ScopePlanPaginationInfo = {
  __typename?: 'ScopePlanPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type ScopePlanScope = Node & {
  __typename?: 'ScopePlanScope';
  _id: Scalars['String'];
  createTime?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  elements?: Maybe<Scalars['Iterable']>;
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructionalDays?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  pk: Scalars['String'];
  schoolIds?: Maybe<Scalars['Iterable']>;
  scopeId?: Maybe<Scalars['String']>;
  scopePlanId?: Maybe<Scalars['String']>;
  sharedBy?: Maybe<Scalars['String']>;
  sk: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  type: EntryTypeEnum;
  updateTime?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userRoleCode?: Maybe<Scalars['String']>;
  userType?: Maybe<UsersTypeEnum>;
  version?: Maybe<Scalars['Int']>;
};

/** Actions: Add / Remove */
export enum ScopePlanScopeElementActionTypeEnum {
  /** Action Type Add */
  Add = 'Add',
  /** Action Type Remove */
  Remove = 'Remove',
}

export type ScopePlanScopeFilter = {
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  scopeId?: InputMaybe<Scalars['String']>;
  scopePlanId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Page connection for ScopePlanScope. */
export type ScopePlanScopePageConnection = {
  __typename?: 'ScopePlanScopePageConnection';
  collection?: Maybe<Array<Maybe<ScopePlanScope>>>;
  paginationInfo: ScopePlanScopePaginationInfo;
};

/** Information about the pagination. */
export type ScopePlanScopePaginationInfo = {
  __typename?: 'ScopePlanScopePaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

/** Scope Plan type: Individual / Team / Template */
export enum ScopePlanTypeEnum {
  /** Individual Type */
  Individual = 'Individual',
  /** Team Type */
  Team = 'Team',
  /** Template Type */
  Template = 'Template',
}

export type ScopePrintDownloadInput = {
  /** Id of the scope which user tries to print/download */
  scopeId?: Scalars['String'];
  /** Type of the request */
  eventType?: InputMaybe<ScopePrintDownloadTypeEnum>;
};

export type ScopePrintDownloadPayload = {
  __typename?: 'ScopePrintDownloadPayload';
  scopeId: Scalars['String'];
};

export enum ScopePrintDownloadTypeEnum {
  Print = 'Print',
  Download = 'Download',
}

export type Scopes = {
  __typename?: 'Scopes';
  collection: Array<SearchableScope>;
  pageInfo: PageInfo;
};

export type SearchableElement = {
  __typename?: 'SearchableElement';
  contentGroups: Array<Scalars['String']>;
  curriculumArea?: Maybe<Scalars['String']>;
  editionName: Scalars['String'];
  elementFiles?: Maybe<Array<Scalars['JSON']>>;
  gradeLevel?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  metadata: Scalars['JSON'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  projectType: Scalars['String'];
  resourceDefault: Scalars['Boolean'];
  scopeId: Scalars['String'];
  scopeName: Scalars['String'];
  sectionId: Scalars['String'];
  sectionName: Scalars['String'];
  sectionType: Scalars['String'];
  studentView?: Maybe<Array<Scalars['JSON']>>;
  teacherView?: Maybe<Array<Scalars['JSON']>>;
};

export type SearchableMathNationScope = {
  __typename?: 'SearchableMathNationScope';
  category: Scalars['String'];
  contentAudience: AudienceEnum;
  contentGroups?: Maybe<Array<Scalars['String']>>;
  displayName: Scalars['String'];
  grade: Scalars['String'];
  id: Scalars['String'];
  projectIdentifiers?: Maybe<Scalars['JSON']>;
  projectTitles?: Maybe<Scalars['JSON']>;
};

export type SearchableScope = {
  __typename?: 'SearchableScope';
  contentGroups: Array<Scalars['String']>;
  displayName: Scalars['String'];
  editionName: Scalars['String'];
  elementTaxonomy?: Maybe<Array<Scalars['JSON']>>;
  elementTitles: Array<Scalars['String']>;
  id: Scalars['String'];
  image: Scalars['String'];
  internalName: Scalars['String'];
  metadata: Scalars['JSON'];
  positionNumber: Scalars['Int'];
  projectId: Scalars['String'];
  projectType: Scalars['String'];
  sections: Array<Scalars['JSON']>;
  taxonomy: Array<Scalars['JSON']>;
};

export type SearchableTeacherResource = {
  __typename?: 'SearchableTeacherResource';
  contentGroups: Array<Scalars['String']>;
  displayName: Scalars['String'];
  elementTitles: Array<Scalars['String']>;
  id: Scalars['String'];
  internalName: Scalars['String'];
  metadata: Scalars['JSON'];
  positionNumber: Scalars['Int'];
  projectId: Scalars['String'];
  projectType: Scalars['String'];
  sectionTitles: Array<Scalars['JSON']>;
};

export enum SetupStatusEnum {
  Activated = 'activated',
  Completed = 'completed',
  NotStarted = 'notStarted',
}

export type ShareAssessmentInput = {
  edgexlInput?: InputMaybe<EdgexlShareAssessmentInput>;
  stemscopesInput?: InputMaybe<StemscopesShareAssessmentInput>;
};

/** Shares a Calendar. */
export type ShareCalendarInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  shareType: Scalars['String'];
  userIds: Scalars['Iterable'];
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
  version: Scalars['Int'];
};

/** Shares a Calendar. */
export type ShareCalendarPayload = {
  __typename?: 'shareCalendarPayload';
  calendar?: Maybe<Calendar>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum ShareResults {
  Disabled = 'DISABLED',
  WithoutCorrectAnswers = 'WITHOUT_CORRECT_ANSWERS',
  WithCorrectAnswers = 'WITH_CORRECT_ANSWERS',
}

/** ShareTemplates a PacingGuide. */
export type ShareTemplatePacingGuideInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  sharedData: Scalars['Iterable'];
  sharedOption: Scalars['String'];
  sharedType: Scalars['String'];
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
  version: Scalars['Int'];
};

/** ShareTemplates a PacingGuide. */
export type ShareTemplatePacingGuidePayload = {
  __typename?: 'shareTemplatePacingGuidePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  pacingGuide?: Maybe<PacingGuide>;
};

/** ShareTemplates a ScopePlan. */
export type ShareTemplateScopePlanInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  sharedData: Scalars['Iterable'];
  sharedOption: Scalars['String'];
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
  version: Scalars['Int'];
};

/** ShareTemplates a ScopePlan. */
export type ShareTemplateScopePlanPayload = {
  __typename?: 'shareTemplateScopePlanPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  scopePlan?: Maybe<ScopePlan>;
};

export enum SignAssignmentServices {
  Assess = 'assess',
  Author = 'author',
  Data = 'data',
  Events = 'events',
  Items = 'items',
  Questions = 'questions',
  Reports = 'reports',
}

export type SignedRequest = Node & {
  __typename?: 'SignedRequest';
  _id: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  mode: Scalars['String'];
  referenceId: Scalars['String'];
  signedRequest: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

/** Signed Request Interface */
export type SignedRequestInterface = {
  items?: Maybe<Array<Scalars['String']>>;
  referenceId?: Maybe<Scalars['String']>;
  renderingType?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** Signed Request Union */
export type SignedRequestUnion = ItemSignedRequest | ReportSignedRequest;

/** Action for sign */
export type SignRequestActionInput = {
  startNewAttempt: Scalars['Boolean'];
};

/** Sign Request Input */
export type SignRequestInput = {
  itemSignedRequestInput?: InputMaybe<ItemSignRequestInput>;
  reportSignedRequestInput?: InputMaybe<ReportSignRequestInput>;
};

export type SignRequestPayload = {
  __typename?: 'SignRequestPayload';
  signedRequest: Scalars['String'];
};

export type SignRequestStartUpPayload = {
  __typename?: 'SignRequestStartUpPayload';
  subject: Scalars['String'];
  startupCompleted: Scalars['Boolean'];
  signedRequest: Scalars['String'];
};

export enum SortDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** Sort type: ASC / DESC */
export enum SortEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum SortFieldEnum {
  Created = 'CREATED',
  Updated = 'UPDATED',
}

export type SortInput = {
  direction: SortDirectionEnum;
  field: SortFieldEnum;
};

export type Sso = {
  __typename?: 'Sso';
  oauth?: Maybe<SsoOauth>;
  saml?: Maybe<SsoSaml>;
  districtId: Scalars['String'];
  type: SsoTypeEnum;
  policies: Array<SsoPolicy>;
};

export type SsoInput = {
  oauth?: InputMaybe<SsoOauthInput>;
  saml?: InputMaybe<SsoSamlInput>;
  ssoKey: SsoKeyInput;
};

export type SsoKeyInput = {
  districtId: Scalars['String'];
  type: SsoTypeEnum;
};

export type SsoListInput = {
  districtId?: InputMaybe<Scalars['String']>;
};

export type SsoOauth = {
  __typename?: 'SsoOauth';
  baseUrl?: Maybe<Scalars['String']>;
  clientId: Scalars['String'];
  clientSecret?: Maybe<Scalars['String']>;
};

export type SsoOauthInput = {
  clientId: Scalars['String'];
  clientSecret?: InputMaybe<Scalars['String']>;
  baseUrl?: InputMaybe<Scalars['String']>;
};

export type SsoPolicy = {
  __typename?: 'SsoPolicy';
  externalField?: Maybe<ExternalFieldEnum>;
  internalFields: Array<SsoPolicyInternalField>;
  name: PolicyNameEnum;
  sequence: Scalars['Int'];
  userType: SsoUserTypeEnum;
};

export type SsoPolicyInput = {
  internalFields: Array<InternalFieldInput>;
  externalField?: InputMaybe<ExternalFieldEnum>;
  name: PolicyNameEnum;
  sequence: Scalars['Int'];
  userType: SsoUserTypeEnum;
};

export type SsoPolicyInternalField = {
  __typename?: 'SsoPolicyInternalField';
  name?: Maybe<InternalFieldEnum>;
  value: InternalFieldEnum;
};

export type SsoSaml = {
  __typename?: 'SsoSaml';
  idpEntityId: Scalars['String'];
  idpSingleSingOnUrl: Scalars['String'];
  idpSingleLogoutUrl: Scalars['String'];
  idpX509Cert: Scalars['String'];
};

export type SsoSamlInput = {
  idpEntityId: Scalars['String'];
  idpSingleSingOnUrl: Scalars['String'];
  idpSingleLogoutUrl: Scalars['String'];
  idpX509Cert: Scalars['String'];
};

export enum SsoTypeEnum {
  Oauth2Canvas = 'OAUTH2_CANVAS',
  Oauth2Classlink = 'OAUTH2_CLASSLINK',
  Oauth2Clever = 'OAUTH2_CLEVER',
  OauthCanvas = 'OAUTH_CANVAS',
  SamlClasslink = 'SAML_CLASSLINK',
  SamlGoogle = 'SAML_GOOGLE',
  SamlMicrosoft = 'SAML_MICROSOFT',
}

export enum SsoUserTypeEnum {
  Staff = 'STAFF',
  Student = 'STUDENT',
}

export type SsoWithPoliciesInput = {
  policies?: InputMaybe<Array<SsoPolicyInput>>;
  oauth?: InputMaybe<SsoOauthInput>;
  saml?: InputMaybe<SsoSamlInput>;
  ssoKey: SsoKeyInput;
};

export type SsQuestionContainerInput = {
  category: Scalars['String'];
  grade: Scalars['String'];
  lesson: Scalars['String'];
  unit: Scalars['String'];
};

export type SsStandards = Node & {
  __typename?: 'ssStandards';
  _id: Scalars['String'];
  id: Scalars['ID'];
  /** A token used for paginating through results. Pass this token in subsequent requests to retrieve the next page of results. */
  nextToken: Scalars['String'];
  resultSetJson: Scalars['String'];
};

/** SsStandardsSort type: studentName / scorePct by ASC or DESC */
export type SsStandardsSort = {
  /** sort by scorePct asc or desc */
  scorePct?: InputMaybe<SortEnum>;
  /** sort by studentName asc or desc */
  studentName?: InputMaybe<SortEnum>;
};

export type Standard = {
  __typename?: 'Standard';
  id: Scalars['String'];
  state: Scalars['String'];
  grade: Scalars['String'];
  standard: Scalars['String'];
  sourceDocument: Scalars['String'];
  subject: Scalars['String'];
  parent: Scalars['String'];
  description: Scalars['String'];
  itemsBack?: Maybe<Array<Scalars['String']>>;
  itemsForward?: Maybe<Array<Scalars['String']>>;
  hasNoKids?: Maybe<Scalars['Boolean']>;
};

export type StandardListInput = {
  domainInput?: InputMaybe<Scalars['String']>;
  gradeInput?: InputMaybe<Scalars['String']>;
  standardInput?: InputMaybe<Scalars['String']>;
  currentSchoolId?: InputMaybe<Scalars['String']>;
  isPlatformAdmin?: Scalars['Boolean'];
};

export type StandardListPayload = {
  __typename?: 'StandardListPayload';
  standards: Scalars['String'];
};

export type StandardPayload = {
  __typename?: 'StandardPayload';
  color: Scalars['String'];
  desc: Scalars['String'];
  id: Scalars['Int'];
  xCoordinate: Scalars['Int'];
  yCoordinate: Scalars['Int'];
  standard: Scalars['String'];
};

export type Standards = {
  __typename?: 'Standards';
  description?: Maybe<Scalars['String']>;
  standard?: Maybe<Scalars['String']>;
  standardId: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

/** Params for CYU/SS/TY Assignments */
export type StartMnPersonalAssignmentInput = {
  assignmentSource: AssignmentSourceInput;
  assignmentType: MnPersonalAssignmentType;
  devDomain?: InputMaybe<Scalars['String']>;
  signItem: ItemSignRequestInput;
};

export enum StateEnum {
  Al = 'AL',
  Ak = 'AK',
  Az = 'AZ',
  Ar = 'AR',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  De = 'DE',
  Dc = 'DC',
  Fl = 'FL',
  Ga = 'GA',
  Hi = 'HI',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ia = 'IA',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Me = 'ME',
  Md = 'MD',
  Ma = 'MA',
  Mi = 'MI',
  Mn = 'MN',
  Ms = 'MS',
  Mo = 'MO',
  Mt = 'MT',
  Ne = 'NE',
  Nv = 'NV',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Ny = 'NY',
  Nc = 'NC',
  Nd = 'ND',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Vt = 'VT',
  Va = 'VA',
  Wa = 'WA',
  Wv = 'WV',
  Wi = 'WI',
  Wy = 'WY',
}

export enum StatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type StatusPayload = {
  __typename?: 'StatusPayload';
  success: Scalars['Boolean'];
};

export type StemscopesAssessmentInput = {
  field: FieldEnum;
  value: Scalars['String'];
  currentSchoolId: Scalars['String'];
};

export type StemscopesAssessmentItemPayload = {
  __typename?: 'StemscopesAssessmentItemPayload';
  isArchived?: Maybe<Scalars['Boolean']>;
  isAssigned?: Maybe<Scalars['Boolean']>;
  isEdited?: Maybe<Scalars['Boolean']>;
  isPrintable?: Maybe<Scalars['Boolean']>;
  isShared?: Maybe<Scalars['Boolean']>;
  activityType: ActivityTypeEnum;
  createdAt: Scalars['String'];
  deliveries: Array<DeliveryEnum>;
  hasManuallyGradedQuestions?: Maybe<Scalars['Boolean']>;
  hiddenForSchool: Scalars['Boolean'];
  library?: Maybe<StemscopesLibraryEnum>;
  status: Scalars['String'];
  subPurpose?: Maybe<SubPurposeEnum>;
  teams: Array<Scalars['String']>;
  updatedAt: Scalars['String'];
  usersViewOnlyIds: Array<Scalars['String']>;
  id: Scalars['String'];
  createdBy?: Maybe<UserPayload>;
  title?: Maybe<Scalars['String']>;
  standards: Array<Scalars['String']>;
  items: Array<Scalars['String']>;
};

export type StemscopesAssessmentPackageBoxItemPayload = {
  __typename?: 'StemscopesAssessmentPackageBoxItemPayload';
  assessments: Array<Scalars['String']>;
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type StemscopesAssessmentPackageBoxPayload = {
  __typename?: 'StemscopesAssessmentPackageBoxPayload';
  item?: Maybe<StemscopesAssessmentPackageBoxItemPayload>;
};

export type StemscopesAssessmentPayload = {
  __typename?: 'StemscopesAssessmentPayload';
  item?: Maybe<StemscopesAssessmentItemPayload>;
};

export type StemscopesAssessmentPoolInput = {
  isArchived?: InputMaybe<Scalars['Boolean']>;
  libraries: Array<StemscopesLibraryEnum>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  currentSchoolId: Scalars['String'];
};

export type StemscopesAssessmentPoolPayload = {
  __typename?: 'StemscopesAssessmentPoolPayload';
  list: Array<StemscopesAssessmentItemPayload>;
  paginationInfo: PaginationInfoPayload;
};

/** StemscopesAssignment Object Type */
export type StemscopesAssignment = {
  __typename?: 'StemscopesAssignment';
  /** @deprecated Has been deprecated, use Types::AssignmentSourceType instead */
  assessmentId?: Maybe<Scalars['String']>;
  assignmentId: Scalars['String'];
  assignmentListId: Scalars['String'];
  assignmentSource: AssignmentSource;
  calculator?: Maybe<Calculator>;
  canBePaused?: Maybe<Scalars['Boolean']>;
  classId: Scalars['String'];
  endDate: Scalars['ISO8601Date'];
  immediatelyShareResults?: Maybe<ImmediatelyShareResults>;
  labels?: Maybe<Array<Scalars['String']>>;
  lateTurnIn?: Maybe<Scalars['Boolean']>;
  learnosityReferenceId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  originLearnosityId?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  releaseFeedback?: Maybe<Scalars['Boolean']>;
  schoolId?: Maybe<Scalars['String']>;
  startDate: Scalars['ISO8601Date'];
  status: AssignmentStatus;
  studentAssignments?: Maybe<Array<StudentAssignment>>;
  studentIds?: Maybe<Array<Scalars['String']>>;
  subgroupIds?: Maybe<Array<Scalars['String']>>;
};

export type StemscopesCopyAssessmentInput = {
  id: Scalars['ID'];
  currentSchoolId: Scalars['String'];
};

export type StemscopesCreateAssessmentInput = {
  deliveries: Array<DeliveryEnum>;
  isArchived?: Scalars['Boolean'];
  isAssigned?: Scalars['Boolean'];
  isVisible?: Scalars['Boolean'];
  items?: InputMaybe<Array<Scalars['String']>>;
  library: StemscopesLibraryEnum;
  teams?: Array<Scalars['String']>;
  title: Scalars['String'];
};

export type StemscopesCreateAssessmentPackageBoxInput = {
  assessments?: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};

/** Attributes for creating stemscopes assignment */
export type StemscopesCreateInput = {
  assignmentSource: AssignmentSourceInput;
  calculator?: InputMaybe<CalculatorInput>;
  canBePaused?: InputMaybe<Scalars['Boolean']>;
  classOtherAssignments?: InputMaybe<Array<ClassOtherAssignment>>;
  classes: Array<AssignmentClassInput>;
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  immediatelyShareResults?: InputMaybe<ImmediatelyShareResults>;
  labels: Array<Scalars['String']>;
  lateTurnIn: Scalars['Boolean'];
  learnosityReferenceId: Scalars['String'];
  name: Scalars['String'];
  notes: Scalars['String'];
  releaseFeedback: Scalars['Boolean'];
  schoolId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
};

export type StemscopesDeleteAssessmentInput = {
  id: Scalars['ID'];
  currentSchoolId: Scalars['String'];
};

/** Attributes for editing stemscopes assignment */
export type StemscopesEditInput = {
  assignmentId: Scalars['String'];
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
};

export enum StemscopesLibraryEnum {
  Individual = 'INDIVIDUAL',
  Team = 'TEAM',
  District = 'DISTRICT',
  Campus = 'CAMPUS',
  Shared = 'SHARED',
  Benchmark = 'BENCHMARK',
}

export type StemscopesMedia = {
  __typename?: 'StemscopesMedia';
  area: StemscopesVideoAreaEnum;
  id: Scalars['String'];
  language: LanguageEnum;
  quality?: Maybe<VideoQualityEnum>;
  title: Scalars['String'];
  type: MediaTypeEnum;
  url?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  subtitlesSrtFormat: Scalars['Boolean'];
  uniquePackTitle?: Maybe<Scalars['String']>;
};

export type StemscopesMediaFilterInput = {
  title?: InputMaybe<Scalars['String']>;
  area?: InputMaybe<StemscopesVideoAreaEnum>;
};

export type StemscopesMediaPack = {
  __typename?: 'StemscopesMediaPack';
  id: Scalars['String'];
  title: Scalars['String'];
  area: StemscopesVideoAreaEnum;
  uniqueItemTitles?: Maybe<Array<Scalars['String']>>;
};

export type StemscopesMediaPackFilterInput = {
  title?: InputMaybe<Scalars['String']>;
  area?: InputMaybe<StemscopesVideoAreaEnum>;
};

export type StemscopesMediaPackPageConnection = {
  __typename?: 'StemscopesMediaPackPageConnection';
  collection: Array<StemscopesMediaPack>;
  paginationInfo?: Maybe<StemscopesMediaPackPaginationInfo>;
};

export type StemscopesMediaPackPaginationInfo = {
  __typename?: 'StemscopesMediaPackPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type StemscopesMediaPageConnection = {
  __typename?: 'StemscopesMediaPageConnection';
  collection: Array<StemscopesMedia>;
  paginationInfo?: Maybe<StemscopesMediaPaginationInfo>;
};

export type StemscopesMediaPaginationInfo = {
  __typename?: 'StemscopesMediaPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type StemscopesMediaPayload = {
  __typename?: 'StemscopesMediaPayload';
  media: StemscopesMedia;
};

export type StemscopesMediaPostObjectPayload = {
  __typename?: 'StemscopesMediaPostObjectPayload';
  subtitlesPostObject?: Maybe<MediaPostObject>;
  videoPostObject: MediaPostObject;
};

export type StemscopesQuestionPoolInput = {
  assessmentId?: InputMaybe<Scalars['String']>;
  curriculumAreas?: InputMaybe<Array<Scalars['String']>>;
  deliveries?: InputMaybe<Array<DeliveryEnum>>;
  editions?: InputMaybe<Array<Scalars['String']>>;
  excludeIds?: InputMaybe<Array<Scalars['String']>>;
  grades?: InputMaybe<Array<Scalars['String']>>;
  gradingTypes?: InputMaybe<Array<GradingTypeEnum>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  questionBanks?: InputMaybe<Array<QuestionBankEnum>>;
  questionTypes?: InputMaybe<Array<Scalars['String']>>;
  scopes?: InputMaybe<Array<Scalars['String']>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  standards?: InputMaybe<Array<Scalars['String']>>;
};

export type StemscopesShareAssessmentInput = {
  isViewOnly: Scalars['Boolean'];
  userIds: Array<Scalars['String']>;
  id: Scalars['ID'];
  currentSchoolId: Scalars['String'];
};

export type StemscopesUpdateAssessmentInput = {
  id: Scalars['ID'];
  deliveries: Array<DeliveryEnum>;
  isArchived?: Scalars['Boolean'];
  isAssigned?: Scalars['Boolean'];
  isVisible?: Scalars['Boolean'];
  items?: InputMaybe<Array<Scalars['String']>>;
  library: StemscopesLibraryEnum;
  teams?: Array<Scalars['String']>;
  title: Scalars['String'];
};

export enum StemscopesVideoAreaEnum {
  Science = 'Science',
  Math = 'Math',
}

export type Streaming = {
  __typename?: 'Streaming';
  contentGroups?: Maybe<Array<Scalars['String']>>;
  contentType: Scalars['String'];
  coverImage: Scalars['String'];
  curriculumArea?: Maybe<Scalars['String']>;
  grades?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  metadata: Scalars['JSON'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  studentView?: Maybe<Scalars['JSON']>;
  tags?: Maybe<Array<Scalars['String']>>;
  teacherView?: Maybe<Scalars['JSON']>;
  topic?: Maybe<Scalars['String']>;
};

export type StreamingContentTypes = {
  __typename?: 'StreamingContentTypes';
  contentTypes?: Maybe<Array<Scalars['String']>>;
};

export type StreamingCurriculumAreas = {
  __typename?: 'StreamingCurriculumAreas';
  curriculumAreas?: Maybe<Array<Scalars['String']>>;
};

export type StreamingFilter = {
  contentTypes?: InputMaybe<Array<Scalars['String']>>;
  topics?: InputMaybe<Array<Scalars['String']>>;
  curriculumAreas?: InputMaybe<Array<Scalars['String']>>;
  grades?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type StreamingGrades = {
  __typename?: 'StreamingGrades';
  grades?: Maybe<Array<Scalars['String']>>;
};

export type Streamings = {
  __typename?: 'Streamings';
  collection?: Maybe<Array<Streaming>>;
  pageInfo?: Maybe<PageInfo>;
};

export type StreamingTags = {
  __typename?: 'StreamingTags';
  tags?: Maybe<Array<Scalars['String']>>;
};

export type StreamingTopics = {
  __typename?: 'StreamingTopics';
  topics?: Maybe<Array<Scalars['String']>>;
};

export type StreamingVideo = {
  __typename?: 'StreamingVideo';
  duration?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  language: LanguageEnum;
  quality?: Maybe<VideoQualityEnum>;
  title: Scalars['String'];
  type: MediaTypeEnum;
  url: Scalars['String'];
  active: Scalars['Boolean'];
};

export type StreamingVideoFilterInput = {
  id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type StreamingVideoPageConnection = {
  __typename?: 'StreamingVideoPageConnection';
  collection: Array<StreamingVideo>;
  paginationInfo?: Maybe<StreamingVideoPaginationInfo>;
};

export type StreamingVideoPaginationInfo = {
  __typename?: 'StreamingVideoPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type StreamingVideoPayload = {
  __typename?: 'StreamingVideoPayload';
  video: StreamingVideo;
};

export type StringPageConnection = {
  __typename?: 'StringPageConnection';
  collection: Array<Scalars['String']>;
  paginationInfo?: Maybe<StringPaginationInfo>;
};

export type StringPaginationInfo = {
  __typename?: 'StringPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type Student = Node & {
  __typename?: 'Student';
  _id: Scalars['String'];
  classes?: Maybe<ClassPageConnection>;
  subgroups?: Maybe<StudentSubgroupPageConnection>;
  active: Scalars['Boolean'];
  authenticationToken?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdByUsername?: Maybe<Scalars['String']>;
  curriculumAreas?: Maybe<Scalars['Iterable']>;
  district?: Maybe<District>;
  /** @deprecated Used from Session User */
  districtUuid: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  externalSystemName?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gradeLevel?: Maybe<GradeLevelEnum>;
  id: Scalars['ID'];
  importId?: Maybe<Scalars['String']>;
  inactivityReason?: Maybe<UserInactivityReasonEnum>;
  language: LanguageEnum;
  lastName: Scalars['String'];
  lmsId?: Maybe<Scalars['String']>;
  manualMaintenance?: Maybe<Scalars['Boolean']>;
  middleName?: Maybe<Scalars['String']>;
  plainPassword?: Maybe<Scalars['String']>;
  preferences?: Maybe<Scalars['String']>;
  sisId?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  type: UserTypeEnum;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedByUsername?: Maybe<Scalars['String']>;
  userRoles?: Maybe<UserRolePageConnection>;
  username: Scalars['String'];
};

export type StudentClassesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Array<InputMaybe<ClassFilter_Order>>>;
  hasLmsId?: InputMaybe<Scalars['Boolean']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<StatusEnum>;
  studentsId?: InputMaybe<Scalars['String']>;
  teachersId?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<InputMaybe<ClassFilter>>>;
};

export type StudentSubgroupsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

export type StudentUserRolesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

/** Student Assignment Object Type */
export type StudentAssignment = {
  __typename?: 'StudentAssignment';
  answers?: Maybe<Scalars['JSON']>;
  archived?: Maybe<Scalars['Boolean']>;
  assessmentId?: Maybe<Scalars['String']>;
  assignmentId: Scalars['String'];
  assignmentSource?: Maybe<AssignmentSource>;
  classId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  endDate?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lateTurnIn: Scalars['Boolean'];
  learnosityReferenceId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originLearnosityId?: Maybe<Scalars['String']>;
  otherAssignmentScope?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  randomizeQuestionsOrder?: Maybe<Scalars['Boolean']>;
  releaseFeedback: Scalars['Boolean'];
  reopenedAt?: Maybe<Scalars['ISO8601DateTime']>;
  resetted?: Maybe<Scalars['Boolean']>;
  returnedAt?: Maybe<Scalars['ISO8601DateTime']>;
  shareResults?: Maybe<ShareResults>;
  startDate?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['ISO8601DateTime']>;
  status: StudentAssignmentStatus;
  studentId: Scalars['String'];
  studentNotes?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type StudentAssignmentIdUpdatePayload = {
  __typename?: 'StudentAssignmentIdUpdatePayload';
  status: Scalars['String'];
};

export type StudentAssignmentMock = {
  __typename?: 'StudentAssignmentMock';
  assignmentId: Scalars['String'];
  name: Scalars['String'];
  completedAt: Scalars['ISO8601DateTime'];
  grade: Scalars['Int'];
  startedAt: Scalars['ISO8601DateTime'];
  status: StudentAssignmentStatusEnumMock;
  submittedAt: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
  startDate: Scalars['ISO8601DateTime'];
  saId: Scalars['String'];
};

export type StudentAssignmentMockPageConnection = {
  __typename?: 'StudentAssignmentMockPageConnection';
  collection: Array<StudentAssignmentMock>;
  paginationInfo?: Maybe<StudentAssignmentMockPaginationInfo>;
};

export type StudentAssignmentMockPaginationInfo = {
  __typename?: 'StudentAssignmentMockPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type StudentAssignmentPayload = {
  __typename?: 'StudentAssignmentPayload';
  assignment_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  gdst?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Student Assignments collection object Type */
export type StudentAssignments = {
  __typename?: 'StudentAssignments';
  collection?: Maybe<Array<StudentAssignment>>;
  pageInfo?: Maybe<PageInfo>;
};

export type StudentAssignmentsFilter = {
  assignmentId?: InputMaybe<Scalars['String']>;
  classId?: InputMaybe<Scalars['String']>;
  otherAssignment?: InputMaybe<Scalars['Boolean']>;
  otherAssignmentScope?: InputMaybe<Scalars['String']>;
  scopeId?: InputMaybe<Scalars['String']>;
  studentId?: InputMaybe<Scalars['String']>;
  studentShareResults?: InputMaybe<Scalars['Boolean']>;
};

export enum StudentAssignmentStatus {
  Assigned = 'ASSIGNED',
  Completed = 'COMPLETED',
  Started = 'STARTED',
  Submitted = 'SUBMITTED',
}

export enum StudentAssignmentStatusEnumMock {
  Assigned = 'ASSIGNED',
  Completed = 'COMPLETED',
  Started = 'STARTED',
}

export type StudentAssignmentStatusPayload = {
  __typename?: 'StudentAssignmentStatusPayload';
  status: Scalars['String'];
};

export type StudentCertificateInput = {
  studentId?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type StudentCertificatePayload = {
  __typename?: 'StudentCertificatePayload';
  path?: Maybe<Scalars['String']>;
};

export type StudentCompletedTopicPayload = {
  __typename?: 'StudentCompletedTopicPayload';
  assignmentId: Scalars['String'];
  domainName: Scalars['String'];
  domainNo: Scalars['String'];
  gdst: Scalars['String'];
  status: Scalars['String'];
  topic: Scalars['String'];
};

/** Student Notebook Object Type */
export type StudentNotebook = {
  __typename?: 'StudentNotebook';
  classId: Scalars['String'];
  scopeIds?: Maybe<Array<Scalars['String']>>;
};

/** Student Notebooks collection Object Type */
export type StudentNotebooks = {
  __typename?: 'StudentNotebooks';
  collection?: Maybe<Array<StudentNotebook>>;
  pageInfo?: Maybe<PageInfo>;
};

/** Page connection for Student. */
export type StudentPageConnection = {
  __typename?: 'StudentPageConnection';
  collection?: Maybe<Array<Maybe<Student>>>;
  paginationInfo: StudentPaginationInfo;
};

/** Information about the pagination. */
export type StudentPaginationInfo = {
  __typename?: 'StudentPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type StudentPayload = {
  __typename?: 'StudentPayload';
  itemReferenceIds: Array<Scalars['String']>;
  learnosityActivityId: Scalars['String'];
  learnosityAssignmentId: Scalars['String'];
  sessionId: Scalars['String'];
  studentCompletedTopics: Array<StudentCompletedTopicPayload>;
  studentProgress: Array<StudentProgressPayload>;
  continueNextGrade: Scalars['Boolean'];
  isRedirect: Scalars['Boolean'];
  assessmentCount?: Maybe<Scalars['Int']>;
  startupCompleteDate?: Maybe<Scalars['String']>;
  startupIsReview: Scalars['Boolean'];
  startupStatus?: Maybe<Scalars['String']>;
  studentPathwayCount: Scalars['Int'];
};

export type StudentProgressPayload = {
  __typename?: 'StudentProgressPayload';
  code: Scalars['String'];
  domain: Scalars['String'];
  topic: Scalars['String'];
};

/** StudentResource Object Type */
export type StudentResource = {
  __typename?: 'StudentResource';
  classId?: Maybe<Scalars['String']>;
  learnosityReferenceId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originLearnosityId?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  resourceId: Scalars['String'];
  resourceSource?: Maybe<ResourceSource>;
  schoolId?: Maybe<Scalars['String']>;
  srGrade?: Maybe<Scalars['String']>;
  srId: Scalars['String'];
  srStatus: StudentResourceStatus;
  studentId: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

/** StudentResources Object Type */
export type StudentResources = {
  __typename?: 'StudentResources';
  collection?: Maybe<Array<StudentResource>>;
  defaultElements?: Maybe<Array<DefaultElement>>;
  pageInfo?: Maybe<PageInfo>;
};

export type StudentResourcesFilter = {
  classId?: InputMaybe<Scalars['String']>;
  scopeId?: InputMaybe<Scalars['String']>;
};

/** Params to save student resources filter */
export type StudentResourcesFilterInput = {
  gradeLevelsByGrade?: InputMaybe<GradeLevelsByGradeInput>;
  individual?: InputMaybe<Scalars['Boolean']>;
};

/** Student resources filter object */
export type StudentResourcesSettings = {
  __typename?: 'StudentResourcesSettings';
  gradeLevelsByGrade?: Maybe<GradeLevelsByGrade>;
  individual?: Maybe<Scalars['Boolean']>;
  schoolId?: Maybe<Scalars['String']>;
};

export enum StudentResourceStatus {
  Assigned = 'ASSIGNED',
  Completed = 'COMPLETED',
  Started = 'STARTED',
}

export type StudentResponsePayload = {
  __typename?: 'StudentResponsePayload';
  response: Scalars['String'];
  status: Scalars['String'];
};

/** Student resources filters collection */
export type StudentsResourcesSettings = {
  __typename?: 'StudentsResourcesSettings';
  collection?: Maybe<Array<StudentResourcesSettings>>;
};

export type StudentSubgroup = Node & {
  __typename?: 'StudentSubgroup';
  _id: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  students?: Maybe<StudentSubgroupPageConnection>;
  teachers?: Maybe<StudentSubgroupPageConnection>;
};

export type StudentSubgroupStudentsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

export type StudentSubgroupTeachersArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

/** Page connection for StudentSubgroup. */
export type StudentSubgroupPageConnection = {
  __typename?: 'StudentSubgroupPageConnection';
  collection?: Maybe<Array<Maybe<StudentSubgroup>>>;
  paginationInfo: StudentSubgroupPaginationInfo;
};

/** Information about the pagination. */
export type StudentSubgroupPaginationInfo = {
  __typename?: 'StudentSubgroupPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type SubjectPayload = {
  __typename?: 'SubjectPayload';
  id: Scalars['String'];
};

export enum SubPurposeEnum {
  StemscopesMath = 'STEMSCOPES_MATH',
  StemscopesScience = 'STEMSCOPES_SCIENCE',
}

export type Subscription = {
  __typename?: 'Subscription';
  id: Scalars['ID'];
  products: Array<Product>;
  dateStart: Scalars['DateTime'];
  dateEnd: Scalars['DateTime'];
  quantity: Scalars['Int'];
};

export type SubscriptionPackage = {
  __typename?: 'SubscriptionPackage';
  id: Scalars['ID'];
  /** Use only for merging data! */
  _id: Scalars['ID'];
  schools: Array<School>;
  scope: SubscriptionScopeEnum;
  subscriptions: Array<Subscription>;
};

export type SubscriptionPackageFilterInput = {
  districtId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  schoolIds?: InputMaybe<Array<Scalars['ID']>>;
  state?: InputMaybe<Scalars['String']>;
};

export type SubscriptionPackagePageConnection = {
  __typename?: 'SubscriptionPackagePageConnection';
  collection: Array<SubscriptionPackage>;
  paginationInfo?: Maybe<SubscriptionPackagePaginationInfo>;
};

export type SubscriptionPackagePaginationInfo = {
  __typename?: 'SubscriptionPackagePaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SubscriptionPackagePayload = {
  __typename?: 'SubscriptionPackagePayload';
  subscriptionPackage: SubscriptionPackage;
};

export type SubscriptionPageConnection = {
  __typename?: 'SubscriptionPageConnection';
  collection: Array<Subscription>;
  paginationInfo?: Maybe<SubscriptionPaginationInfo>;
};

export type SubscriptionPaginationInfo = {
  __typename?: 'SubscriptionPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SubscriptionPayload = {
  __typename?: 'SubscriptionPayload';
  subscription: Subscription;
};

export enum SubscriptionScopeEnum {
  School = 'School',
  District = 'District',
}

/** Success Object Type */
export type Success = {
  __typename?: 'Success';
  success?: Maybe<Scalars['Boolean']>;
};

export type SuccessPayload = {
  __typename?: 'SuccessPayload';
  isSuccess: Scalars['Boolean'];
};

export type SystemAdmin = {
  __typename?: 'SystemAdmin';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type SystemAdminPageConnection = {
  __typename?: 'SystemAdminPageConnection';
  collection: Array<SystemAdmin>;
  paginationInfo?: Maybe<SystemAdminPaginationInfo>;
};

export type SystemAdminPaginationInfo = {
  __typename?: 'SystemAdminPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type TagPayload = {
  __typename?: 'TagPayload';
  name: Scalars['String'];
  value: Scalars['String'];
};

export enum TccConsumerEnum {
  Canvas = 'CANVAS',
  Infinitecampus = 'INFINITECAMPUS',
  Itslearning = 'ITSLEARNING',
  Safarimontage = 'SAFARIMONTAGE',
  Schoology = 'SCHOOLOGY',
}

export type TccPackage = {
  __typename?: 'TccPackage';
  bucketName?: Maybe<Scalars['String']>;
  consumer?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  contentItems?: Maybe<Array<Scalars['String']>>;
  districtId?: Maybe<Scalars['String']>;
  generatedAt?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
};

export type TccPackages = {
  __typename?: 'TccPackages';
  collection: Array<TccPackage>;
  packageInfo: Scalars['String'];
};

export type TccPackagesCount = {
  __typename?: 'TccPackagesCount';
  collection: Array<Scalars['JSON']>;
};

export enum TccVersionEnum {
  Version_11 = 'VERSION_11',
  Version_12 = 'VERSION_12',
  Version_13 = 'VERSION_13',
}

export type Teacher = Node & {
  __typename?: 'Teacher';
  _id: Scalars['String'];
  id: Scalars['ID'];
  orderListStudents: Scalars['String'];
  active: Scalars['Boolean'];
  authenticationToken?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdByUsername?: Maybe<Scalars['String']>;
  curriculumAreas?: Maybe<Scalars['Iterable']>;
  district?: Maybe<District>;
  /** @deprecated Used from Session User */
  districtUuid: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  externalSystemName?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gradeLevel?: Maybe<GradeLevelEnum>;
  importId?: Maybe<Scalars['String']>;
  inactivityReason?: Maybe<UserInactivityReasonEnum>;
  language: LanguageEnum;
  lastName: Scalars['String'];
  lmsId?: Maybe<Scalars['String']>;
  manualMaintenance?: Maybe<Scalars['Boolean']>;
  middleName?: Maybe<Scalars['String']>;
  plainPassword?: Maybe<Scalars['String']>;
  preferences?: Maybe<Scalars['String']>;
  sisId?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  type: UserTypeEnum;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedByUsername?: Maybe<Scalars['String']>;
  userRoles?: Maybe<UserRolePageConnection>;
  username: Scalars['String'];
};

export type TeacherUserRolesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

export type TeacherAssignmentInput = {
  class: Scalars['String'];
  students: Array<Scalars['String']>;
  topicIds: Array<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type TeacherAssignmentListInput = {
  /** The list of ordering criteria. */
  orderBy: Array<OrderByInput>;
  /** The number of items per page. */
  pageCount: Scalars['Int'];
  /** The page number. */
  pageNo: Scalars['Int'];
  /** The list of student IDs. */
  students: Array<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type TeacherAssignmentListPayload = {
  __typename?: 'TeacherAssignmentListPayload';
  data: Array<AssignmentDataPayload>;
  total: Scalars['Int'];
};

export type TeacherAssignmentPayload = {
  __typename?: 'TeacherAssignmentPayload';
  response: Scalars['String'];
};

/** Attributes for creating teacher assignments */
export type TeacherAssignmentsCreateInput = {
  assignmentSource: AssignmentSourceInput;
  calculator?: InputMaybe<CalculatorInput>;
  canBePaused?: InputMaybe<Scalars['Boolean']>;
  classes: Array<AssignmentClassInput>;
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  immediatelyShareResults?: InputMaybe<ImmediatelyShareResults>;
  labels: Array<Scalars['String']>;
  lateTurnIn: Scalars['Boolean'];
  learnosityReferenceId: Scalars['String'];
  name: Scalars['String'];
  notes: Scalars['String'];
  randomizeQuestionsOrder?: InputMaybe<Scalars['Boolean']>;
  releaseFeedback: Scalars['Boolean'];
  schoolId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
};

export type TeacherDistrictAssignmentsFilter = {
  classId?: InputMaybe<Scalars['String']>;
  daId?: InputMaybe<Scalars['String']>;
  schoolId: Scalars['String'];
};

export type TeacherFeedback = {
  __typename?: 'TeacherFeedback';
  responseFeedbacks?: Maybe<Array<ResponseFeedback>>;
  sessionId: Scalars['String'];
  teacherId: Scalars['String'];
};

export type TeacherFilter = {
  schoolId?: InputMaybe<Scalars['String']>;
};

/** Page connection for Teacher. */
export type TeacherPageConnection = {
  __typename?: 'TeacherPageConnection';
  collection?: Maybe<Array<Maybe<Teacher>>>;
  paginationInfo: TeacherPaginationInfo;
};

/** Information about the pagination. */
export type TeacherPaginationInfo = {
  __typename?: 'TeacherPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type TeacherResource = {
  __typename?: 'TeacherResource';
  active: Scalars['Boolean'];
  contentGroups: Array<Scalars['String']>;
  contentLocation: Scalars['String'];
  created: Scalars['String'];
  displayName: Scalars['String'];
  editionName: Scalars['String'];
  elementTitles: Array<Scalars['String']>;
  id: Scalars['String'];
  internalName: Scalars['String'];
  lastUpdated: Scalars['String'];
  metadata: Scalars['JSON'];
  positionNumber: Scalars['Int'];
  projectId: Scalars['String'];
  projectType: Scalars['String'];
  sectionTitles: Array<Scalars['JSON']>;
  sections: Array<Scalars['JSON']>;
  state: Scalars['String'];
  version: Scalars['Int'];
};

export type TeacherResources = {
  __typename?: 'TeacherResources';
  collection?: Maybe<Array<SearchableTeacherResource>>;
  pageInfo?: Maybe<PageInfo>;
};

export type TeacherStudentFilter = {
  active?: InputMaybe<Scalars['Boolean']>;
  classIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  schoolId?: InputMaybe<Scalars['String']>;
  studentHasArchivedClasses?: InputMaybe<Scalars['Boolean']>;
  teachersId?: InputMaybe<Scalars['String']>;
};

export type Team = {
  __typename?: 'Team';
  archived: Scalars['Boolean'];
  context: TeamContextEnum;
  district?: Maybe<District>;
  id: Scalars['ID'];
  members: Array<User>;
  name: Scalars['String'];
  /** @deprecated Use creator */
  owner: User;
  creator: User;
  state?: Maybe<StateEnum>;
};

export enum TeamContextEnum {
  Calendar = 'calendar',
  StemscopesAssessment = 'stemscopesAssessment',
}

export type TeamPageConnection = {
  __typename?: 'TeamPageConnection';
  collection: Array<Team>;
  paginationInfo?: Maybe<TeamPaginationInfo>;
};

export type TeamPaginationInfo = {
  __typename?: 'TeamPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type TeamPayload = {
  __typename?: 'TeamPayload';
  team: Team;
};

/** ToggleVerifySchoolAges a WallAgeControl. */
export type ToggleVerifySchoolAgeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
  isVerified: Scalars['Boolean'];
  schoolId: Scalars['String'];
};

/** ToggleVerifySchoolAges a WallAgeControl. */
export type ToggleVerifySchoolAgePayload = {
  __typename?: 'toggleVerifySchoolAgePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  wallAgeControl?: Maybe<WallAgeControl>;
};

/** Toggles a WallPostLike. */
export type ToggleWallPostLikeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
  wallPostId: Scalars['String'];
};

/** Toggles a WallPostLike. */
export type ToggleWallPostLikePayload = {
  __typename?: 'toggleWallPostLikePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  wallPostLike?: Maybe<WallPostLike>;
};

export type ToMoveVideoInput = {
  groupIdentifier: Scalars['String'];
  tutorId?: InputMaybe<Scalars['String']>;
  videoIndex?: InputMaybe<Scalars['String']>;
};

export type Topics = {
  __typename?: 'Topics';
  topics: Array<Scalars['String']>;
};

export type Tutor = {
  __typename?: 'Tutor';
  bioText: Scalars['String'];
  bioVideo: Scalars['String'];
  depthScale: Scalars['Int'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  initialTag: LanguageTagEnum;
  languageTag: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  mainProfilePhoto: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  profilePhoto: Scalars['String'];
  videos: VideoPageConnection;
};

export type TutorVideosArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type TutorFilterInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type TutorPageConnection = {
  __typename?: 'TutorPageConnection';
  collection: Array<Tutor>;
  paginationInfo?: Maybe<TutorPaginationInfo>;
};

export type TutorPaginationInfo = {
  __typename?: 'TutorPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type TutorPayload = {
  __typename?: 'TutorPayload';
  tutor: Tutor;
};

export enum TypeEnum {
  /** Item Type */
  Items = 'items',
  /** Activity Type */
  Activities = 'activities',
}

export type TyQuestionContainerInput = {
  category: Scalars['String'];
  grade: Scalars['String'];
  unit: Scalars['String'];
};

/** Object of Post Input Request Type */
export type UnfilteredInputPostRequest = {
  /** Text to search posts by content, firstname or lastname */
  comment?: InputMaybe<Scalars['String']>;
  /** ID of selected school */
  currentSchoolId: Scalars['String'];
  /** Id of post */
  id?: InputMaybe<Scalars['String']>;
  /** Get hidden / unhidden posts */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** Offset */
  offset?: InputMaybe<Scalars['Int']>;
  /** Size */
  size?: InputMaybe<Scalars['Int']>;
  /** User Id of post author */
  userId?: InputMaybe<Scalars['String']>;
  /** Array of Wall Ids */
  wallIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UnionVideoPdf = Video | Pdf;

export type UnionVideoPdfPageConnection = {
  __typename?: 'UnionVideoPdfPageConnection';
  collection: Array<UnionVideoPdf>;
  paginationInfo?: Maybe<UnionVideoPdfPaginationInfo>;
};

export type UnionVideoPdfPaginationInfo = {
  __typename?: 'UnionVideoPdfPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type UpdateAccessPolicyInput = {
  applications?: InputMaybe<Array<ApplicationInput>>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<ScopedPermissionInput>>;
};

export type UpdateAdoptionAccountInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  beginDate?: InputMaybe<Scalars['DateTime']>;
  expirationDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<StateEnum>;
  productGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  productIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateAdoptionEventInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  beginDate?: InputMaybe<Scalars['DateTime']>;
  category?: InputMaybe<AdoptionEventCategoryEnum>;
  expirationDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  uiCustomization?: InputMaybe<Scalars['String']>;
  urlCode?: InputMaybe<Scalars['String']>;
  productGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  productIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateAdoptionReviewerInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  plainPassword?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<AdoptionReviewerUserRoleEnum>;
};

export type UpdateAssessmentInput = {
  edgexlInput?: InputMaybe<EdgexlUpdateAssessmentInput>;
  platformAdminInput?: InputMaybe<PlatformAdminUpdateAssessmentInput>;
  stemscopesInput?: InputMaybe<StemscopesUpdateAssessmentInput>;
};

/** Updates a Calendar. */
export type UpdateCalendarInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  firstDayOfSchool?: InputMaybe<Scalars['Iterable']>;
  gradingPeriods?: InputMaybe<Scalars['Iterable']>;
  holidays?: InputMaybe<Scalars['Iterable']>;
  id: Scalars['String'];
  lastDayOfSchool?: InputMaybe<Scalars['Iterable']>;
  name?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
  version: Scalars['Int'];
};

/** Updates a Calendar. */
export type UpdateCalendarPayload = {
  __typename?: 'updateCalendarPayload';
  calendar?: Maybe<Calendar>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Updates a Class. */
export type UpdateClassInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  classTeachers?: InputMaybe<Array<InputMaybe<UpdateClassTeacherNestedInput>>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  curriculumArea?: InputMaybe<CurriculumAreaEnum>;
  gradeOrCourse?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  lmsId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<StatusEnum>;
  subgroups?: InputMaybe<Array<InputMaybe<UpdateStudentSubgroupNestedInput>>>;
};

/** Updates a Class. */
export type UpdateClassPayload = {
  __typename?: 'updateClassPayload';
  class?: Maybe<Class>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Updates a ClassTeacher. */
export type UpdateClassTeacherInput = {
  _id?: InputMaybe<Scalars['String']>;
  accesses?: InputMaybe<Scalars['Iterable']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  mainTeacher?: InputMaybe<Scalars['Boolean']>;
  orderListStudents?: InputMaybe<Scalars['String']>;
  subgroups?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Updates a ClassTeacher. */
export type UpdateClassTeacherNestedInput = {
  _id?: InputMaybe<Scalars['String']>;
  accesses?: InputMaybe<Scalars['Iterable']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  mainTeacher?: InputMaybe<Scalars['Boolean']>;
  orderListStudents?: InputMaybe<Scalars['String']>;
  subgroups?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Updates a ClassTeacher. */
export type UpdateClassTeacherPayload = {
  __typename?: 'updateClassTeacherPayload';
  classTeacher?: Maybe<ClassTeacher>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateContentGroupInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  contents?: InputMaybe<Array<ContentIdInput>>;
  id: Scalars['ID'];
};

export type UpdateContentInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  projectId: Scalars['ID'];
};

/** Updates a District. */
export type UpdateDistrictInput = {
  city?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalSystem?: InputMaybe<Scalars['String']>;
  globalName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  localName?: InputMaybe<Scalars['String']>;
  mathNationId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<StateEnum>;
};

/** Updates a District. */
export type UpdateDistrictPayload = {
  __typename?: 'updateDistrictPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  district?: Maybe<District>;
};

/** Updates a DistrictSettingsLessonPlanner. */
export type UpdateDistrictSettingsLessonPlannerInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  teacherCanUseAiAssistedLessonPlan?: InputMaybe<Scalars['Boolean']>;
};

/** Updates a DistrictSettingsLessonPlanner. */
export type UpdateDistrictSettingsLessonPlannerPayload = {
  __typename?: 'updateDistrictSettingsLessonPlannerPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  districtSettingsLessonPlanner?: Maybe<UpdateDistrictSettingsLessonPlannerPayloadData>;
};

/** Updates a DistrictSettingsLessonPlanner. */
export type UpdateDistrictSettingsLessonPlannerPayloadData = Node & {
  __typename?: 'updateDistrictSettingsLessonPlannerPayloadData';
  id: Scalars['ID'];
  teacherCanUseAiAssistedLessonPlan?: Maybe<Scalars['Boolean']>;
};

/** Updates a DistrictSettingsRoster. */
export type UpdateDistrictSettingsRosterInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  teacherCanAddAdditionalTeacher?: InputMaybe<Scalars['Boolean']>;
  teacherCanCreateClasses?: InputMaybe<Scalars['Boolean']>;
  teacherCanEditClassRoster?: InputMaybe<Scalars['Boolean']>;
};

/** Updates a DistrictSettingsRoster. */
export type UpdateDistrictSettingsRosterPayload = {
  __typename?: 'updateDistrictSettingsRosterPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  districtSettingsRoster?: Maybe<UpdateDistrictSettingsRosterPayloadData>;
};

/** Updates a DistrictSettingsRoster. */
export type UpdateDistrictSettingsRosterPayloadData = Node & {
  __typename?: 'updateDistrictSettingsRosterPayloadData';
  id: Scalars['ID'];
  teacherCanAddAdditionalTeacher?: Maybe<Scalars['Boolean']>;
  teacherCanCreateClasses?: Maybe<Scalars['Boolean']>;
  teacherCanEditClassRoster?: Maybe<Scalars['Boolean']>;
};

/** Updates a DistrictSettingsUserManagement. */
export type UpdateDistrictSettingsUserManagementInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  teacherCanCreateStudents?: InputMaybe<Scalars['Boolean']>;
  teacherCanEditStudentPassword?: InputMaybe<Scalars['Boolean']>;
  teacherCanImpersonateStudents?: InputMaybe<Scalars['Boolean']>;
};

/** Updates a DistrictSettingsUserManagement. */
export type UpdateDistrictSettingsUserManagementPayload = {
  __typename?: 'updateDistrictSettingsUserManagementPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  districtSettingsUserManagement?: Maybe<UpdateDistrictSettingsUserManagementPayloadData>;
};

/** Updates a DistrictSettingsUserManagement. */
export type UpdateDistrictSettingsUserManagementPayloadData = Node & {
  __typename?: 'updateDistrictSettingsUserManagementPayloadData';
  id: Scalars['ID'];
  teacherCanCreateStudents?: Maybe<Scalars['Boolean']>;
  teacherCanEditStudentPassword?: Maybe<Scalars['Boolean']>;
  teacherCanImpersonateStudents?: Maybe<Scalars['Boolean']>;
};

export type UpdateEmailTemplateInput = {
  attachments?: InputMaybe<Array<InputMaybe<EmailTemplateAttachmentInput>>>;
  body?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  fromEmail?: InputMaybe<Scalars['String']>;
  fromName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  source?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EmailTemplateTypeEnum>;
};

/** Updates a Entry. */
export type UpdateEntryInput = {
  calendarId: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  startDate?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EntryTypeEnum>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
  version: Scalars['Int'];
};

/** Updates a Entry. */
export type UpdateEntryPayload = {
  __typename?: 'updateEntryPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  entry?: Maybe<Entry>;
};

export type UpdateImageCategoryInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ID']>;
};

export type UpdateImageFileInput = {
  id?: InputMaybe<Scalars['ID']>;
  originalFileName: Scalars['String'];
  path: Scalars['String'];
};

export type UpdateImageInput = {
  altText?: InputMaybe<Scalars['String']>;
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  files: Array<UpdateImageFileInput>;
  id: Scalars['ID'];
  keywordIds?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  tagAssociations?: InputMaybe<Array<ImageTagAssociationInput>>;
};

export type UpdateImageKeywordInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateImageTagInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateImageTagValueInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

/** Updates a PacingGuide. */
export type UpdatePacingGuideInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  selectedCalendar?: InputMaybe<Scalars['Iterable']>;
  startDate?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
  version: Scalars['Int'];
};

/** Updates a PacingGuide. */
export type UpdatePacingGuidePayload = {
  __typename?: 'updatePacingGuidePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  pacingGuide?: Maybe<PacingGuide>;
};

/** Updates a PacingGuideScope. */
export type UpdatePacingGuideScopeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  pacingGuideId: Scalars['String'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  scopes: Scalars['Iterable'];
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
  version: Scalars['Int'];
};

/** Updates a PacingGuideScope. */
export type UpdatePacingGuideScopePayload = {
  __typename?: 'updatePacingGuideScopePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  pacingGuideScope?: Maybe<PacingGuideScope>;
};

/** Updates a Post. */
export type UpdatePostInput = {
  answer?: InputMaybe<Scalars['Int']>;
  blocked?: InputMaybe<Scalars['Int']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
  documentKey?: InputMaybe<Scalars['String']>;
  documentType?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isHidden?: InputMaybe<Scalars['Boolean']>;
  isSticky?: InputMaybe<Scalars['Int']>;
  stared?: InputMaybe<Scalars['Int']>;
  welcome?: InputMaybe<Scalars['Int']>;
};

/** Updates a Post. */
export type UpdatePostPayload = {
  __typename?: 'updatePostPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
};

export type UpdatePreviewAccountInput = {
  activityPeriodEnd?: InputMaybe<Scalars['DateTime']>;
  activityPeriodStart?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  state?: InputMaybe<StateEnum>;
  productGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  productIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdatePreviewAccountTeacherInput = {
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
};

export type UpdateProductGroupInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  productIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateProductInput = {
  contentGroups?: InputMaybe<Array<Scalars['ID']>>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  productType?: InputMaybe<ProductTypeEnum>;
  scopeGroups?: InputMaybe<Array<Scalars['ID']>>;
  sku?: InputMaybe<Scalars['String']>;
};

export type UpdateReaderLibraryInput = {
  description?: InputMaybe<Scalars['String']>;
  enPdfFile?: InputMaybe<Scalars['Upload']>;
  esPdfFile?: InputMaybe<Scalars['Upload']>;
  title?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type UpdateRoleInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  policies?: InputMaybe<Array<Scalars['String']>>;
};

/** Updates a School. */
export type UpdateSchoolInput = {
  city?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  districtId?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalSystem?: InputMaybe<Scalars['String']>;
  globalName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  localName?: InputMaybe<Scalars['String']>;
  mathNationId?: InputMaybe<Scalars['String']>;
  sisId?: InputMaybe<Scalars['String']>;
};

/** Updates a School. */
export type UpdateSchoolPayload = {
  __typename?: 'updateSchoolPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  school?: Maybe<School>;
};

/** Updates a ScopePlan. */
export type UpdateScopePlanInput = {
  approachType?: InputMaybe<ScopePlanApproachEnum>;
  classSize?: InputMaybe<Scalars['Int']>;
  classTime?: InputMaybe<Scalars['Int']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  pacingGuideId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  scopes?: InputMaybe<Scalars['Iterable']>;
  scopesText?: InputMaybe<Scalars['String']>;
  teacherExp?: InputMaybe<Scalars['Int']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
  version: Scalars['Int'];
};

/** Updates a ScopePlan. */
export type UpdateScopePlanPayload = {
  __typename?: 'updateScopePlanPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  scopePlan?: Maybe<ScopePlan>;
};

/** Updates a ScopePlanScope. */
export type UpdateScopePlanScopeInput = {
  action: ScopePlanScopeElementActionTypeEnum;
  clientMutationId?: InputMaybe<Scalars['String']>;
  elements: Scalars['Iterable'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  scopeId: Scalars['String'];
  scopePlanId: Scalars['String'];
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
  version: Scalars['Int'];
};

/** Updates a ScopePlanScope. */
export type UpdateScopePlanScopePayload = {
  __typename?: 'updateScopePlanScopePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  scopePlanScope?: Maybe<ScopePlanScope>;
};

export type UpdateStemscopesMediaInput = {
  id: Scalars['ID'];
  active?: Scalars['Boolean'];
  subtitlesSrtFormat?: Scalars['Boolean'];
};

export type UpdateStemscopesMediaPackInput = {
  id: Scalars['ID'];
  items: Array<UpdateStemscopesMediaInput>;
};

export type UpdateStemscopesMediaPackPayload = {
  __typename?: 'UpdateStemscopesMediaPackPayload';
  pack: StemscopesMediaPack;
  items: Array<StemscopesMediaPostObjectPayload>;
};

export type UpdateStreamingVideoInput = {
  id: Scalars['ID'];
  duration?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  active?: Scalars['Boolean'];
};

/** Updates a StudentSubgroup. */
export type UpdateStudentSubgroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  teacherIds?: InputMaybe<Scalars['Iterable']>;
};

/** Updates a StudentSubgroup. */
export type UpdateStudentSubgroupNestedInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  teacherIds?: InputMaybe<Scalars['Iterable']>;
};

/** Updates a StudentSubgroup. */
export type UpdateStudentSubgroupPayload = {
  __typename?: 'updateStudentSubgroupPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  studentSubgroup?: Maybe<StudentSubgroup>;
};

export type UpdateSubscriptionInput = {
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  dateStart?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  productIds?: InputMaybe<Array<Scalars['ID']>>;
  quantity?: InputMaybe<Scalars['Int']>;
};

export type UpdateSubscriptionPackageInput = {
  id?: InputMaybe<Scalars['ID']>;
  districtId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<Scalars['ID']>>;
  scope?: InputMaybe<SubscriptionScopeEnum>;
  subscriptionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateTeamInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** User IDs */
  members?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateTutorInput = {
  bioText?: InputMaybe<Scalars['String']>;
  bioVideo?: InputMaybe<Scalars['Upload']>;
  depthScale?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  initialTag?: InputMaybe<LanguageTagEnum>;
  lastName?: InputMaybe<Scalars['String']>;
  mainProfilePhoto?: InputMaybe<Scalars['Upload']>;
  order?: InputMaybe<Scalars['Int']>;
  profilePhoto?: InputMaybe<Scalars['Upload']>;
};

/** UpdateUserActivitys a User. */
export type UpdateUserActivityInput = {
  active: Scalars['Boolean'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  userIds: Scalars['Iterable'];
};

/** UpdateUserActivitys a User. */
export type UpdateUserActivityPayload = {
  __typename?: 'updateUserActivityPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Updates a UserCalendar. */
export type UpdateUserCalendarInput = {
  assignedTo?: InputMaybe<Scalars['String']>;
  calendarId?: InputMaybe<Scalars['String']>;
  calendarTemplateId?: InputMaybe<Scalars['String']>;
  calendarType?: InputMaybe<CalendarTypeEnum>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  createTime?: InputMaybe<Scalars['String']>;
  creator?: InputMaybe<Scalars['String']>;
  editable?: InputMaybe<Scalars['Boolean']>;
  eventId?: InputMaybe<Scalars['String']>;
  eventTemplateId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isSelected?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  pacingGuideId?: InputMaybe<Scalars['String']>;
  pacingGuideTemplateId?: InputMaybe<Scalars['String']>;
  pacingGuideType?: InputMaybe<PacingGuideTypeEnum>;
  pk?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  scopePlanId?: InputMaybe<Scalars['String']>;
  scopePlanType?: InputMaybe<ScopePlanTypeEnum>;
  sharedBy?: InputMaybe<Scalars['String']>;
  sk?: InputMaybe<Scalars['String']>;
  updateTime?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<UsersTypeEnum>;
  version?: InputMaybe<Scalars['Int']>;
};

/** Updates a UserCalendar. */
export type UpdateUserCalendarPayload = {
  __typename?: 'updateUserCalendarPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  userCalendar?: Maybe<UserCalendar>;
};

/** Updates a User. */
export type UpdateUserInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  curriculumAreas?: InputMaybe<Scalars['Iterable']>;
  districtUuid?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalSystemName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gradeLevel?: InputMaybe<GradeLevelEnum>;
  id: Scalars['ID'];
  language?: InputMaybe<LanguageEnum>;
  lastName?: InputMaybe<Scalars['String']>;
  lmsId?: InputMaybe<Scalars['String']>;
  manualMaintenance?: InputMaybe<Scalars['Boolean']>;
  middleName?: InputMaybe<Scalars['String']>;
  plainPassword?: InputMaybe<Scalars['String']>;
  preferences?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  sisId?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  userRoles?: InputMaybe<Array<InputMaybe<UpdateUserRoleNestedInput>>>;
  userRolesExplicitDelete?: InputMaybe<
    Array<InputMaybe<UpdateUserRoleNestedInput>>
  >;
  username?: InputMaybe<Scalars['String']>;
};

/** Updates a User. */
export type UpdateUserPayload = {
  __typename?: 'updateUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Updates a UserRole. */
export type UpdateUserRoleInput = {
  allSchoolsAccess?: InputMaybe<Scalars['Boolean']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  roleId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
};

/** Updates a UserRole. */
export type UpdateUserRoleNestedInput = {
  _delete?: InputMaybe<Scalars['Boolean']>;
  allSchoolsAccess?: InputMaybe<Scalars['Boolean']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  roleId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
};

/** Updates a UserRole. */
export type UpdateUserRolePayload = {
  __typename?: 'updateUserRolePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  userRole?: Maybe<UserRole>;
};

/** Updates a UserSelection. */
export type UpdateUserSelectionInput = {
  calendarSettingsPopup?: InputMaybe<Scalars['Boolean']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  notificationCalendar?: InputMaybe<Scalars['Iterable']>;
  notificationPacingGuide?: InputMaybe<Scalars['Iterable']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  selectedCalendar?: InputMaybe<Scalars['Iterable']>;
  selectedPacingGuide?: InputMaybe<Scalars['Iterable']>;
  selectedScopePlan?: InputMaybe<Scalars['Iterable']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
  version?: InputMaybe<Scalars['Int']>;
  viewMode?: InputMaybe<ViewModeEnum>;
};

/** Updates a UserSelection. */
export type UpdateUserSelectionPayload = {
  __typename?: 'updateUserSelectionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  userSelection?: Maybe<UserSelection>;
};

export type UpdateWidgetInput = {
  x?: InputMaybe<Scalars['Int']>;
  y?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
  h?: InputMaybe<Scalars['Int']>;
  i: Scalars['String'];
  id: Scalars['String'];
  collapsed?: InputMaybe<Scalars['Boolean']>;
  expandedH?: InputMaybe<Scalars['Int']>;
  expandedW?: InputMaybe<Scalars['Int']>;
  minH?: InputMaybe<Scalars['Int']>;
  minW?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<WidgetType>;
  config?: InputMaybe<Scalars['JSONObject']>;
};

export type User = Node & {
  __typename?: 'User';
  id: Scalars['ID'];
  _id: Scalars['String'];
  active: Scalars['Boolean'];
  authenticationToken?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdByUsername?: Maybe<Scalars['String']>;
  curriculumAreas?: Maybe<Scalars['Iterable']>;
  district?: Maybe<District>;
  /** @deprecated Used from Session User */
  districtUuid: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  externalSystemName?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gradeLevel?: Maybe<GradeLevelEnum>;
  importId?: Maybe<Scalars['String']>;
  inactivityReason?: Maybe<UserInactivityReasonEnum>;
  language: LanguageEnum;
  lastName: Scalars['String'];
  lmsId?: Maybe<Scalars['String']>;
  manualMaintenance?: Maybe<Scalars['Boolean']>;
  middleName?: Maybe<Scalars['String']>;
  plainPassword?: Maybe<Scalars['String']>;
  preferences?: Maybe<Scalars['String']>;
  sisId?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  type: UserTypeEnum;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedByUsername?: Maybe<Scalars['String']>;
  userRoles?: Maybe<UserRolePageConnection>;
  username: Scalars['String'];
};

export type UserUserRolesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

export type UserCalendar = Node & {
  __typename?: 'UserCalendar';
  _id: Scalars['String'];
  assignedTo?: Maybe<Scalars['String']>;
  calendarId?: Maybe<Scalars['String']>;
  calendarTemplateId?: Maybe<Scalars['String']>;
  calendarType?: Maybe<CalendarTypeEnum>;
  calendars?: Maybe<CalendarPageConnection>;
  createTime?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  editable: Scalars['Boolean'];
  eventId?: Maybe<Scalars['String']>;
  eventTemplateId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  isSelected?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pacingGuideId?: Maybe<Scalars['String']>;
  pacingGuideTemplateId?: Maybe<Scalars['String']>;
  pacingGuideType?: Maybe<PacingGuideTypeEnum>;
  pk: Scalars['String'];
  schoolIds?: Maybe<Scalars['Iterable']>;
  scopePlanId?: Maybe<Scalars['String']>;
  scopePlanType?: Maybe<ScopePlanTypeEnum>;
  sharedBy?: Maybe<Scalars['String']>;
  sk: Scalars['String'];
  updateTime?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userRoleCode?: Maybe<Scalars['String']>;
  userSelection: UserSelection;
  userType?: Maybe<UsersTypeEnum>;
  version?: Maybe<Scalars['Int']>;
};

export type UserCalendarCalendarsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CalendarFilter_Order>>>;
  calendarId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  calendarType?: InputMaybe<CalendarTypeEnum>;
  teamId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Array<InputMaybe<CalendarFilter>>>;
};

export type UserCalendarFilter = {
  accessType?: InputMaybe<Scalars['String']>;
  calendarId?: InputMaybe<Scalars['String']>;
  calendarType?: InputMaybe<CalendarTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UserCalendarFilter_Order = {
  name?: InputMaybe<SortEnum>;
};

/** Page connection for UserCalendar. */
export type UserCalendarPageConnection = {
  __typename?: 'UserCalendarPageConnection';
  collection?: Maybe<Array<Maybe<UserCalendar>>>;
  paginationInfo: UserCalendarPaginationInfo;
};

/** Information about the pagination. */
export type UserCalendarPaginationInfo = {
  __typename?: 'UserCalendarPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type UserFilter = {
  active?: InputMaybe<Scalars['Boolean']>;
  classesArchived?: InputMaybe<Scalars['Boolean']>;
  classesCurriculumArea?: InputMaybe<Scalars['String']>;
  classesGradeOrCourse?: InputMaybe<Scalars['String']>;
  classesId?: InputMaybe<Scalars['String']>;
  classesLanguage?: InputMaybe<Scalars['String']>;
  classesName?: InputMaybe<Scalars['String']>;
  curriculumArea?: InputMaybe<CurriculumAreaEnum>;
  districtId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  exclude?: InputMaybe<Array<InputMaybe<UserFilter_Exclude>>>;
  excludeCurrent?: InputMaybe<Scalars['Boolean']>;
  externalSystemName?: InputMaybe<ExternalSystemNameEnum>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  fullNameOrUsername?: InputMaybe<Scalars['String']>;
  gradeLevel?: InputMaybe<GradeLevelFilterEnum>;
  highestRoleId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lastName?: InputMaybe<Scalars['String']>;
  lmsId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<UserFilter_Order>>>;
  rolesId?: InputMaybe<Scalars['String']>;
  schoolsId?: InputMaybe<Scalars['String']>;
  schoolsIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  sisId?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  subgroupsId?: InputMaybe<Scalars['String']>;
  subgroupsName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UserTypeEnum>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserFilter_Exclude = {
  id?: InputMaybe<Scalars['Iterable']>;
};

export type UserFilter_Order = {
  active?: InputMaybe<SortEnum>;
  email?: InputMaybe<SortEnum>;
  firstName?: InputMaybe<SortEnum>;
  gradeLevel?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
  lastName?: InputMaybe<SortEnum>;
  username?: InputMaybe<SortEnum>;
};

export enum UserInactivityReasonEnum {
  AdoptionAccountDeactivatedManually = 'ADOPTION_ACCOUNT_DEACTIVATED_MANUALLY',
  AdoptionAccountHasExpired = 'ADOPTION_ACCOUNT_HAS_EXPIRED',
  AdoptionAccountHasNoProducts = 'ADOPTION_ACCOUNT_HAS_NO_PRODUCTS',
  AdoptionAccountPeriodHasNotStarted = 'ADOPTION_ACCOUNT_PERIOD_HAS_NOT_STARTED',
  PreviewAccountHasExpired = 'PREVIEW_ACCOUNT_HAS_EXPIRED',
  PreviewAccountHasNoProducts = 'PREVIEW_ACCOUNT_HAS_NO_PRODUCTS',
  PreviewAccountPeriodHasNotStarted = 'PREVIEW_ACCOUNT_PERIOD_HAS_NOT_STARTED',
  PreviewAccountSelfRegistrationRequired = 'PREVIEW_ACCOUNT_SELF_REGISTRATION_REQUIRED',
}

/** Page connection for User. */
export type UserPageConnection = {
  __typename?: 'UserPageConnection';
  collection?: Maybe<Array<Maybe<User>>>;
  paginationInfo: UserPaginationInfo;
};

/** Information about the pagination. */
export type UserPaginationInfo = {
  __typename?: 'UserPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type UserPayload = {
  __typename?: 'UserPayload';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  id: Scalars['String'];
};

export type UserRole = Node & {
  __typename?: 'UserRole';
  allSchoolsAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  role: Role;
  /** @deprecated Use the Role property instead */
  roleId: Scalars['String'];
  schoolIds: Scalars['Iterable'];
};

/** Page connection for UserRole. */
export type UserRolePageConnection = {
  __typename?: 'UserRolePageConnection';
  collection?: Maybe<Array<Maybe<UserRole>>>;
  paginationInfo: UserRolePaginationInfo;
};

/** Information about the pagination. */
export type UserRolePaginationInfo = {
  __typename?: 'UserRolePaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type UserSelection = Node & {
  __typename?: 'UserSelection';
  _id: Scalars['String'];
  calendarSettingsPopup?: Maybe<Scalars['Boolean']>;
  createTime?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  notificationCalendar?: Maybe<Scalars['Iterable']>;
  notificationPacingGuide?: Maybe<Scalars['Iterable']>;
  pk: Scalars['String'];
  schoolIds?: Maybe<Scalars['Iterable']>;
  selectedCalendar?: Maybe<Scalars['Iterable']>;
  selectedPacingGuide?: Maybe<Scalars['Iterable']>;
  selectedScopePlan?: Maybe<Scalars['Iterable']>;
  sharedBy?: Maybe<Scalars['String']>;
  sk: Scalars['String'];
  updateTime?: Maybe<Scalars['String']>;
  userCalendars?: Maybe<UserCalendarPageConnection>;
  userId?: Maybe<Scalars['String']>;
  userRoleCode?: Maybe<Scalars['String']>;
  userType?: Maybe<UsersTypeEnum>;
  version?: Maybe<Scalars['Int']>;
  viewMode?: Maybe<ViewModeEnum>;
};

export type UserSelectionUserCalendarsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
  accessType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<UserCalendarFilter_Order>>>;
  calendarId?: InputMaybe<Scalars['String']>;
  calendarType?: InputMaybe<CalendarTypeEnum>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Array<InputMaybe<UserCalendarFilter>>>;
};

/** Page connection for UserSelection. */
export type UserSelectionPageConnection = {
  __typename?: 'UserSelectionPageConnection';
  collection?: Maybe<Array<Maybe<UserSelection>>>;
  paginationInfo: UserSelectionPaginationInfo;
};

/** Information about the pagination. */
export type UserSelectionPaginationInfo = {
  __typename?: 'UserSelectionPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

/** User type: Individual / Team */
export enum UsersTypeEnum {
  /** Individual Type */
  Individual = 'Individual',
  /** Team Type */
  Team = 'Team',
}

export enum UserTypeEnum {
  Admin = 'admin',
  Parent = 'parent',
  Staff = 'staff',
  Student = 'student',
  Other = 'other',
}

/** VerifyEmailss a Parent. */
export type VerifyEmailsParentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  districtId: Scalars['String'];
  emails: Array<Scalars['String']>;
};

/** VerifyEmailss a Parent. */
export type VerifyEmailsParentPayload = {
  __typename?: 'verifyEmailsParentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  parent?: Maybe<VerifyEmailsParentPayloadData>;
};

/** VerifyEmailss a Parent. */
export type VerifyEmailsParentPayloadData = Node & {
  __typename?: 'verifyEmailsParentPayloadData';
  id: Scalars['ID'];
  results: Scalars['Iterable'];
};

export type Video = {
  __typename?: 'Video';
  description?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  glossaryTerm?: Maybe<Scalars['String']>;
  groupIdentifier: Scalars['String'];
  id: Scalars['String'];
  linkedVideos: UnionVideoPdfPageConnection;
  mnIdentifier?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  processStatus: VideoProcessStatusEnum;
  questionList: Array<Scalars['String']>;
  spriteUrl?: Maybe<Scalars['String']>;
  streamUrl: Scalars['String'];
  subtitlesUrl?: Maybe<Scalars['String']>;
  thumbnailSize?: Maybe<Scalars['String']>;
  tutor?: Maybe<Tutor>;
  videoIndex?: Maybe<Scalars['String']>;
  videoUrl: Scalars['String'];
  active: Scalars['Boolean'];
  glossary: Scalars['Boolean'];
  k5: Scalars['Boolean'];
};

export type VideoLinkedVideosArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type VideoByGroupInput = {
  groupIdentifier: Scalars['String'];
  groupType: VideoGroupEnum;
  mediaType?: MediaTypeEnum;
  videoIndex?: InputMaybe<Scalars['String']>;
};

export type VideoFilterInput = {
  /** For all videos */
  active?: InputMaybe<Scalars['Boolean']>;
  /** For Coursework videos */
  category?: InputMaybe<Scalars['String']>;
  /** For OnRamp videos */
  course?: InputMaybe<Scalars['String']>;
  /** For OnRamp videos */
  domain?: InputMaybe<Scalars['String']>;
  /** For Coursework videos */
  excludeLesson?: InputMaybe<Scalars['String']>;
  /** For Coursework videos */
  glossary?: InputMaybe<Scalars['Boolean']>;
  /** For Coursework videos */
  glossaryTerm?: InputMaybe<Scalars['String']>;
  /** For Coursework videos */
  glossaryCondition?: GlossaryFilterConditionEnum;
  /** For Coursework videos */
  grade?: InputMaybe<Scalars['String']>;
  /** For all videos */
  id?: InputMaybe<Scalars['String']>;
  /** For Coursework videos */
  lesson?: InputMaybe<Scalars['String']>;
  /** For Coursework videos */
  mnComponent?: InputMaybe<Scalars['String']>;
  /** For all videos */
  projectType?: InputMaybe<Scalars['String']>;
  /** For all videos */
  purpose: VideoGroupEnum;
  /** For OnRamp videos */
  standard?: InputMaybe<Scalars['String']>;
  /** For OnRamp videos */
  topic?: InputMaybe<Scalars['String']>;
  /** For all videos */
  tutorIds?: InputMaybe<Array<Scalars['String']>>;
  /** For OnRamp videos */
  type?: InputMaybe<Scalars['String']>;
  /** For Coursework videos */
  unit?: InputMaybe<Scalars['String']>;
  /** For Coursework videos */
  videoIndex?: InputMaybe<Scalars['String']>;
  /** For Glossary videos */
  k5?: InputMaybe<Scalars['Boolean']>;
};

export enum VideoGroupEnum {
  ContentGroup = 'ContentGroup',
  OnRampGroup = 'OnRampGroup',
  QuestionGroup = 'QuestionGroup',
  WithoutGroup = 'WithoutGroup',
  StemscopesGroup = 'StemscopesGroup',
  StreamingGroup = 'StreamingGroup',
  GlossaryGroup = 'GlossaryGroup',
}

export type VideoPageConnection = {
  __typename?: 'VideoPageConnection';
  collection: Array<Video>;
  paginationInfo?: Maybe<VideoPaginationInfo>;
};

export type VideoPaginationInfo = {
  __typename?: 'VideoPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type VideoPayload = {
  __typename?: 'VideoPayload';
  video: UnionVideoPdf;
};

export type VideoPostObjectPayload = {
  __typename?: 'VideoPostObjectPayload';
  fileKey: Scalars['String'];
  formAttributes: Scalars['String'];
  formInputs: Scalars['String'];
  id: Scalars['String'];
};

export enum VideoProcessStatusEnum {
  Uploading = 'Uploading',
  Processing = 'Processing',
  Completed = 'Completed',
  Failed = 'Failed',
}

export enum VideoQualityEnum {
  Hd = 'HD',
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW',
  ExtraLow = 'EXTRA_LOW',
}

export type VideoSortInput = {
  glossaryTerm?: InputMaybe<SortEnum>;
};

/** ViewMode type: Day, Week or Month */
export enum ViewModeEnum {
  /** Day View Mode */
  Day = 'Day',
  /** Week View Mode */
  Week = 'Week',
  /** Month View Mode */
  Month = 'Month',
}

export type VisibilityPayload = {
  __typename?: 'VisibilityPayload';
  isVisible: Scalars['Boolean'];
};

export type WallAgeControl = Node & {
  __typename?: 'WallAgeControl';
  _id: Scalars['Int'];
  created: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isVerified: Scalars['Boolean'];
  modified: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['String']>;
  verifiedByTeacherId?: Maybe<Scalars['String']>;
};

export type WallControl = Node & {
  __typename?: 'WallControl';
  _id: Scalars['Int'];
  created: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  modified: Scalars['String'];
  name: Scalars['String'];
  stateCode: Scalars['String'];
  subjectCode: Scalars['String'];
  wallId: Scalars['String'];
};

export type WallList = {
  __typename?: 'wallList';
  displayName: Scalars['String'];
  id: Scalars['ID'];
};

export type WallListConnect = {
  __typename?: 'wallListConnect';
  collection: Array<WallList>;
};

export type WallPostConnect = {
  __typename?: 'wallPostConnect';
  collection: Array<WallPosts>;
};

export type WallPostLike = Node & {
  __typename?: 'WallPostLike';
  _id: Scalars['Int'];
  created: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  modified: Scalars['String'];
  userId: Scalars['String'];
  wallPost: Post;
};

export type WallPostPin = Node & {
  __typename?: 'WallPostPin';
  _id: Scalars['Int'];
  created: Scalars['String'];
  endTime: Scalars['String'];
  id: Scalars['ID'];
  modified: Scalars['String'];
  userId: Scalars['String'];
  wallPost?: Maybe<Post>;
};

export type WallPosts = Node & {
  __typename?: 'wallPosts';
  _id: Scalars['String'];
  id: Scalars['ID'];
  postId: Scalars['String'];
  wallId: Scalars['Int'];
  content: Scalars['String'];
  timeStamp: Scalars['Int'];
  isActive: Scalars['Boolean'];
  isStickPost: Scalars['Boolean'];
  isWelcomePost: Scalars['Boolean'];
  answered: Scalars['Boolean'];
  likesCount: Scalars['Int'];
  mediaKey: Scalars['String'];
  documentKey: Scalars['String'];
  equationEditorKey: Scalars['String'];
  gBoardKey: Scalars['String'];
  user: WallUser;
  likes?: Maybe<Array<Scalars['String']>>;
  replies?: Maybe<Array<WallReply>>;
  retired?: Maybe<Scalars['Int']>;
};

export type WallReply = {
  __typename?: 'wallReply';
  postId?: Maybe<Scalars['String']>;
  user?: Maybe<WallUser>;
  content?: Maybe<Scalars['String']>;
  documentKey?: Maybe<Scalars['String']>;
  mediaKey?: Maybe<Scalars['String']>;
  gBoardKey?: Maybe<Scalars['String']>;
  equationEditorKey?: Maybe<Scalars['String']>;
  likesCount?: Maybe<Scalars['Int']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  timeStamp?: Maybe<Scalars['Int']>;
};

export type WallUser = {
  __typename?: 'wallUser';
  username?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  state?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type Widget = {
  __typename?: 'Widget';
  x: Scalars['Int'];
  y: Scalars['Int'];
  w: Scalars['Int'];
  h: Scalars['Int'];
  i: Scalars['String'];
  id: Scalars['String'];
  collapsed: Scalars['Boolean'];
  expandedH: Scalars['Int'];
  expandedW: Scalars['Int'];
  minH: Scalars['Int'];
  minW: Scalars['Int'];
  type: WidgetType;
  config?: Maybe<Scalars['JSONObject']>;
};

export enum WidgetType {
  /** Calendar */
  Calendar = 'Calendar',
  /** Week at a Glance */
  WeekAtAGlance = 'WeekAtAGlance',
  /** Assignment */
  Assignment = 'Assignment',
  /** Report */
  Report = 'Report',
  /** Live View */
  LiveView = 'LiveView',
  /** To Do List */
  ToDoList = 'ToDoList',
  /** Countdown */
  Countdown = 'Countdown',
  /** Shortcut */
  Shortcut = 'Shortcut',
}

export type StemscopesQuestionItemPayload = {
  __typename?: 'StemscopesQuestionItemPayload';
  grading?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  standards: Array<Scalars['String']>;
  questionTypes: Array<Scalars['String']>;
  grades: Array<Scalars['String']>;
  curriculumAreas: Array<Scalars['String']>;
  scopes: Array<Scalars['String']>;
  editions: Array<Scalars['String']>;
};
