import { isDevMode } from '@lon/shared/constants';
import { clearStorage } from './clearStorage';
import { hasSavedState } from './getCurrentState';

export const logout = (opts?: { keepPreviousUrl?: boolean }): void => {
  const domain = isDevMode()
    ? process.env['NX_LOGIN_URL']?.split('/login')[0]
    : '';
  clearStorage({
    exceptions: ['district', 'state'],
  });
  window.location.href =
    opts?.keepPreviousUrl && hasSavedState()
      ? `${domain}/login?redirectTo=${window.location.href}`
      : `${domain}/login`;
};

export const logoutPreview = (opts?: { keepPreviousUrl?: boolean }): void => {
  const domain = isDevMode()
    ? process.env['NX_LOGIN_URL']?.split('/login/preview')[0]
    : '';
  clearStorage({
    exceptions: ['district', 'previewLoginState'],
  });
  window.location.href =
    opts?.keepPreviousUrl && hasSavedState()
      ? `${domain}/login/preview?redirectTo=${window.location.href}`
      : `${domain}/login/preview`;
};
