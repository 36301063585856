import ErrorMessage from '../../../../../messages/error-message';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthError } from '@lon/shared/types';
import { getLockedAccountCountdown } from '@lon/shared/utils';

interface Props {
  authError: AuthError | null;
}

const Error: FC<Props> = ({ authError }) => {
  const { t } = useTranslation();

  if (authError === AuthError.Locked) {
    return (
      <ErrorMessage
        errorTitle={t('errorMessage.accountLockedTitle')}
        errorBody={t('errorMessage.accountLockedBody', {
          minutes: getLockedAccountCountdown(),
        })}
      />
    );
  }
  if (authError === AuthError.InvalidCode) {
    return (
      <ErrorMessage
        errorTitle={t('errorMessage.invalidCodeTitle')}
        errorBody={t('errorMessage.invalidCodeBody')}
        bodyWidth="340px"
      />
    );
  }
  if (authError === AuthError.ExpiredCode) {
    return (
      <ErrorMessage
        errorTitle={t('errorMessage.expiredCodeTitle')}
        errorBody={t('errorMessage.expiredCodeBody')}
        bodyWidth="340px"
      />
    );
  }

  return null;
};

export default Error;
