import SelectDistrict from '../../../select-district';
import ContentWrapper from '../content-wrapper';
import { Badge, Box, Button, Flex, Text } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import states from 'states-us';
import { routes } from '@lon/login/configs';
import {
  Asterisk,
  PasswordInput,
  RequiredNotification,
  TextInput,
} from '@lon/shared/components';
import { useQueryParams } from '@lon/shared/hooks';
import { types } from './duck';
import { AuthorisationRequired, Controls, Error } from './components';
import styles from './LoginForm.module.css';

const LoginForm: React.FC<types.LogInProps> = ({
  isLoading,
  setAuthError,
  authError,
  isAuthorisationRequired,
  setIsAuthorisationRequired,
}) => {
  const { state } = useLocation();
  const [searchParams] = useSearchParams({});
  const stateSearchParam = searchParams.get('state');
  const { t } = useTranslation();
  const [resetPassword, setResetPassword] = useState(false);
  const navigate = useNavigate();
  const [authorisationStep, setAuthorisationStep] = useState(1);
  const [params, setParams] = useQueryParams();
  const formContext = useFormContext();
  const { redirectTo } = params;

  const stateValue = (
    stateSearchParam || redirectTo?.split('?state=')[1]?.slice(0, 2)
  )?.toUpperCase();

  let savedState = '';

  try {
    savedState = JSON.parse(localStorage.getItem('state') || '""');
  } catch (error) {
    console.error(error);
  }

  useEffect(() => {
    if (state?.districtId && state?.stateValue) {
      return;
    }

    const savedDistrict = JSON.parse(localStorage.getItem('district') || '{}');
    if (!isEmpty(savedDistrict) && savedState) {
      formContext.setValue(
        'district',
        {
          label: savedDistrict?.label,
          value: savedDistrict?.value,
        },
        { shouldDirty: true }
      );
    }
  }, []);

  const handleReset = () => {
    formContext.resetField('district');
    formContext.resetField('username');
    formContext.resetField('password');
  };

  const handleTryAgain = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (typeof setIsAuthorisationRequired === 'function') {
      setIsAuthorisationRequired(false);
    }
    setAuthorisationStep(1);
    formContext.resetField('username');
    formContext.resetField('password');
    setResetPassword(true);
    setAuthError(null);
  };

  const handleChangeState = () => {
    formContext.resetField('district');
    formContext.resetField('state');
    localStorage.removeItem('state');
    localStorage.removeItem('district');
    setParams({ state: '' });
    navigate(routes.root);
  };

  return (
    <Flex direction="column" gap={8} flexGrow={1}>
      <ContentWrapper alignItems="center">
        {authError ? (
          <Error authError={authError} />
        ) : isAuthorisationRequired ? (
          <AuthorisationRequired step={authorisationStep} />
        ) : (
          <Box width="100%">
            <Helmet>
              <title>{t('pageTitles.login')}</title>
            </Helmet>
            <Flex direction="column" gap={4}>
              <RequiredNotification
                color="primary.100"
                variant="s2"
                markColor="var(--orange-5)"
              />
              <SelectDistrict
                name="district"
                isRequired
                state={stateValue}
                className={styles.select}
                formLabelProps={{
                  requiredIndicator: <Asterisk color="var(--orange-5)" />,
                }}
                lengthToFetch={3}
                topRightLabel={
                  <Flex
                    gap={4}
                    flexDirection="row"
                    alignItems="center"
                    mb={2.5}
                  >
                    <Button
                      onClick={handleChangeState}
                      size="sm"
                      fontSize="mb"
                      color="white"
                      variant="link"
                      textDecoration="underline"
                    >
                      {t('login.changeButton')}
                    </Button>
                    <Badge variant="readOnlyBadge">
                      {
                        states.find(
                          (state) =>
                            state.abbreviation === (stateValue || savedState)
                        )?.name
                      }
                    </Badge>
                  </Flex>
                }
                aria-autocomplete="list"
                aria-label={t('login.districtLabel')}
              />
              <Flex
                direction={{ base: 'column', md: 'row' }}
                gap={{ base: '4', md: '6' }}
              >
                <TextInput
                  variant="dark"
                  autoComplete="username"
                  name="username"
                  placeholder={t('login.usernamePlaceholder')}
                  label={
                    <Text as="span" variant="n1" color="white">
                      {t('login.usernameLabel')}
                    </Text>
                  }
                  formLabelProps={{
                    requiredIndicator: <Asterisk color="var(--orange-5)" />,
                  }}
                  isRequired
                />
                <PasswordInput
                  variant="dark"
                  isRequired
                  name="password"
                  autoComplete={
                    resetPassword ? 'new-password' : 'current-password'
                  }
                  label={
                    <Text as="span" variant="n1" color="white">
                      {t('login.passwordLabel')}
                    </Text>
                  }
                  placeholder={t('login.passwordPlaceholder')}
                  _placeholder={{
                    fontStyle: 'italic',
                    color: 'primary.400',
                    opacity: 1,
                  }}
                  borderRadius="4px"
                  formLabelProps={{
                    requiredIndicator: <Asterisk color="var(--orange-5)" />,
                  }}
                />
              </Flex>
            </Flex>
          </Box>
        )}
      </ContentWrapper>
      <Controls
        isAuthorisationRequired={isAuthorisationRequired}
        setIsAuthorisationRequired={setIsAuthorisationRequired}
        authError={authError}
        setAuthError={setAuthError}
        handleTryAgain={handleTryAgain}
        handleReset={handleReset}
        isLoading={isLoading}
        setAuthorisationStep={setAuthorisationStep}
        authorisationStep={authorisationStep}
      />
    </Flex>
  );
};

export default LoginForm;
