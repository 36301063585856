import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import { CacheProvider } from '@emotion/react';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { apolloClient } from '@lon/login/utils';
import { initTranslationService } from '@lon/login/utils';
import { emotionCache } from '@lon/shared/constants';
import theme from './assets/styles/chakra-theme';
import Root from './pages/Root';

initTranslationService();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <CacheProvider value={emotionCache}>
      <ChakraProvider theme={theme}>
        <ApolloProvider client={apolloClient}>
          <Root />
        </ApolloProvider>
      </ChakraProvider>
    </CacheProvider>
  </StrictMode>
);
