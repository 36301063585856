import { Systems } from './types';
import { systems } from './constants';

export const getURL = (system: Systems, isAdmin?: boolean, isLti?: boolean) => {
  switch (system) {
    case Systems.old: {
      return systems[system].link;
    }
    case Systems.new: {
      return isLti
        ? `${systems[system].link}/lti/scopes`
        : isAdmin
        ? '/admin'
        : systems[system].link;
    }
    case Systems.math: {
      return isLti
        ? `${systems[system].link}/lti/coursework`
        : systems[system].link;
    }
    default: {
      return '#';
    }
  }
};
