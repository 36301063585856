import { Button, Flex } from '@chakra-ui/react';
import { omit } from 'lodash-es';
import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from '@lon/login/configs';
import { Icon } from '@lon/shared/components';
import { useQueryParams } from '@lon/shared/hooks';
import { AuthError } from '@lon/shared/types';
import { Props } from './duck/types';

const Controls: FC<Props> = ({
  isAuthorisationRequired,
  authError,
  setAuthError,
  handleTryAgain,
  handleReset,
  isLoading,
  setAuthorisationStep,
  authorisationStep,
  setIsAuthorisationRequired,
}) => {
  const [searchParams] = useSearchParams({});
  const stateSearchParam = searchParams.get('state');
  const { t } = useTranslation();
  const [params] = useQueryParams();
  const formContext = useFormContext();
  const { redirectTo } = params;
  const navigate = useNavigate();

  const district = useWatch({ name: 'district' });

  const stateValue = (
    stateSearchParam || redirectTo?.split('?state=')[1]?.slice(0, 2)
  )?.toUpperCase();

  const someFieldIsDirty =
    Object.keys(omit(formContext.formState.dirtyFields, 'state')).length > 0;

  if (authError === AuthError.Locked) {
    return (
      <Flex w="100%" justifyContent={'flex-end'}>
        <Button
          size="md"
          mt={{ xs: 9, sm: '48px', md: 0 }}
          mb={{ xs: 3, sm: 4, md: 0 }}
          variant="solidDark"
          onClick={handleTryAgain}
        >
          {t('login.lockedAccountButton')}
        </Button>
      </Flex>
    );
  }

  if (authError === AuthError.InvalidCode) {
    return (
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justify={authorisationStep === 1 ? 'flex-end' : 'space-between'}
        w="full"
        gap={6}
      >
        <Button
          size="md"
          variant="outlineDark"
          backgroundColor={{ background: 'transparent' }}
          onClick={(e) => {
            e.preventDefault();
            if (typeof setIsAuthorisationRequired === 'function') {
              setIsAuthorisationRequired(false);
            }
            setAuthError(null);
            setAuthorisationStep(1);
          }}
        >
          {t('login.cancelButton')}
        </Button>
        <Button
          leftIcon={<Icon name="arr-left-outlined" />}
          size="md"
          mt={{ xs: 9, sm: '48px', md: 0 }}
          mb={{ xs: 3, sm: 4, md: 0 }}
          variant="solidDark"
          onClick={(e) => {
            e.preventDefault();
            setAuthError(null);
          }}
        >
          {t('login.tryAgain')}
        </Button>
      </Flex>
    );
  }

  if (isAuthorisationRequired && authorisationStep === 1) {
    return (
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justify={authorisationStep === 1 ? 'flex-end' : 'space-between'}
        w="full"
        gap={6}
      >
        <Button
          rightIcon={<Icon name="ArrowRight" />}
          size="md"
          mt={{ xs: 9, sm: '48px', md: 0 }}
          mb={{ xs: 3, sm: 4, md: 0 }}
          variant="solidDark"
          onClick={(e) => {
            e.preventDefault();
            setAuthorisationStep(2);
          }}
        >
          {t('login.verifyCode')}
        </Button>
      </Flex>
    );
  }

  if (isAuthorisationRequired && authorisationStep === 2) {
    return (
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justify={'space-between'}
        w="full"
        gap={6}
      >
        <Button
          size="md"
          variant="outlineDark"
          backgroundColor={{ background: 'transparent' }}
          onClick={() => {
            if (typeof setIsAuthorisationRequired === 'function') {
              setIsAuthorisationRequired(false);
            }
            setAuthorisationStep(1);
          }}
        >
          {t('login.cancelButton')}
        </Button>
        <Button
          size="md"
          mt={{ xs: 9, sm: '48px', md: 0 }}
          mb={{ xs: 3, sm: 4, md: 0 }}
          variant="solidDark"
          type="submit"
          isLoading={isLoading}
        >
          {t('login.submitButton')}
        </Button>
      </Flex>
    );
  }

  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      justify="space-between"
      w="full"
      gap={6}
    >
      <Flex flexDir="row" align="center" gap={6}>
        <Button
          isDisabled={!someFieldIsDirty && !district}
          size="md"
          variant="outlineDark"
          backgroundColor={{ background: 'transparent' }}
          onClick={handleReset}
        >
          {t('login.resetButton')}
        </Button>
        <Button
          isDisabled={!district}
          variant="ghostDark"
          onClick={() => {
            navigate(routes.login.forgotPassword, {
              state: { stateValue: stateValue?.toLowerCase() },
            });
          }}
        >
          {t('login.forgotPassword')}
        </Button>
      </Flex>

      <Button
        type="submit"
        size="md"
        variant="solidDark"
        isLoading={isLoading}
        isDisabled={!district}
      >
        {t('login.loginButton')}
      </Button>
    </Flex>
  );
};

export default Controls;
