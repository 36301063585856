import { Html5Qrcode } from 'html5-qrcode';
import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { ErrorReducer } from './types';

type ErrorActions = ActionType<typeof actions>;

const initialState: {
  err: string;
  qrInstance: Html5Qrcode | null;
} = {
  err: '',
  qrInstance: null,
};

const reducer = createReducer<ErrorReducer, ErrorActions>(initialState)
  .handleAction(actions.setError, (state, { payload }) => ({
    ...state,
    err: payload.err,
  }))
  .handleAction(actions.setQrInstance, (state, { payload }) => ({
    ...state,
    qrInstance: payload.qrInstance,
  }));

export default reducer;
