import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  List,
  ListItem,
  Radio,
  Text,
  VStack,
} from '@chakra-ui/react';
import { getBadges } from 'libs/shared/modules/user-nav/src/components/application-switcher/duck/utils';
import { useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ApplicationEnum } from '@lon/shared/enums';
import { useAuth } from '@lon/shared/hooks';
import { useGetActiveContentGroupsWithSchoolsQuery } from '@lon/shared/requests';
import { DefaultLocationProps } from './duck';
import { Skeleton } from './components';

const excludedContentApplications = [
  ApplicationEnum.ADMIN,
  ApplicationEnum.PLATFORM_ADMIN,
  ApplicationEnum.CAMPUS_LEADER_SUIT,
  ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT,
  ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT,
];

const DefaultLocation: React.FC<DefaultLocationProps> = ({
  applications,
  uniqueAclApplication,
  loading,
}) => {
  const { t } = useTranslation();
  const activeItemRef = useRef<HTMLLIElement>(null);
  const [{ user }] = useAuth();
  const form = useFormContext();
  const [defaultApplication, defaultSchool] = useWatch({
    name: ['defaultApplication', 'defaultSchool'],
  });
  const isSingleApplicationAvailable = applications.length === 1;
  const {
    data: activeContentGroupsWithSchools,
    loading: activeContentGroupsWithSchoolsLoading,
  } = useGetActiveContentGroupsWithSchoolsQuery();

  const value = [
    ApplicationEnum.ADMIN,
    ApplicationEnum.PLATFORM_ADMIN,
    ApplicationEnum.CAMPUS_LEADER_SUIT,
    ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT,
    ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT,
    ApplicationEnum.MATHNATION_SUIT,
  ].includes(defaultApplication as ApplicationEnum)
    ? defaultApplication
    : defaultSchool;

  const badges = getBadges({
    t,
    userType: user.type,
  });

  const handleChange = (value: string, schoolId?: string) => {
    const selectedApplication = applications?.find(
      (application) => application.value === value
    );

    const currentApplication = selectedApplication?.application;
    const selectedSchool = [
      ApplicationEnum.STUDENT_SUIT,
      ApplicationEnum.TEACHER_SUIT,
    ].includes(currentApplication as ApplicationEnum)
      ? value
      : schoolId;

    form.setValue('defaultApplication', currentApplication, {
      shouldDirty: true,
    });

    form.setValue('defaultSchool', selectedSchool ? selectedSchool : '', {
      shouldDirty: true,
    });
  };

  const checkSchoolHasContentGroups = (schoolId: string, isMn = false) => {
    const activeGroupsForSchool =
      activeContentGroupsWithSchools?.activeContentGroupsWithSchools?.find(
        (group) => group.schoolId === schoolId
      );

    if (!activeGroupsForSchool) {
      return false;
    }

    return activeGroupsForSchool.contentGroups?.some((activeGroup) =>
      isMn ? activeGroup.startsWith('MN-') : !activeGroup.startsWith('MN-')
    );
  };

  return (
    <>
      <Divider />
      <Box px={{ base: 6, lg: '150px' }}>
        <Flex direction="column" gap={2} pb={6}>
          <Text as="span" textTransform="uppercase">
            {t('userSettings.credentials.assignedSchoolsAndRoles')}
          </Text>
          <Text as="span" variant="s3" whiteSpace="pre-wrap">
            {t('userSettings.credentials.defaultLocationDescription')}
          </Text>
        </Flex>
        <Flex
          direction="column"
          borderWidth="1px"
          borderColor="secondary.200"
          borderRadius="6px"
          py={isSingleApplicationAvailable ? 0 : 4}
          gap="4"
        >
          {isSingleApplicationAvailable ? null : (
            <Text variant="n1" px="6">
              {t('userSettings.credentials.defaultLocation')}
            </Text>
          )}
          {loading || activeContentGroupsWithSchoolsLoading ? (
            <Skeleton amount={4} />
          ) : (
            <List>
              {applications?.map((application, appIndex) => {
                if (
                  application.application === ApplicationEnum.MATHNATION_SUIT
                ) {
                  return uniqueAclApplication.map((uniqueData, index) => {
                    if (!checkSchoolHasContentGroups(uniqueData.value, true)) {
                      return null;
                    }

                    return (
                      <ListItem
                        key={
                          application.value +
                          application.application +
                          uniqueData.value +
                          index
                        }
                        p="0"
                        border="unset"
                        ref={
                          defaultApplication ===
                            ApplicationEnum.MATHNATION_SUIT &&
                          defaultSchool === uniqueData.value
                            ? activeItemRef
                            : undefined
                        }
                        onClick={() =>
                          handleChange(application.value, uniqueData.value)
                        }
                      >
                        <Button
                          variant="applicationButton"
                          width="full"
                          height="unset"
                          bgColor={
                            defaultApplication ===
                              ApplicationEnum.MATHNATION_SUIT &&
                            defaultSchool === uniqueData.value
                              ? 'secondary.50'
                              : 'transparent'
                          }
                          borderRadius={
                            isSingleApplicationAvailable ? '6px' : 0
                          }
                        >
                          <VStack
                            px={6}
                            paddingTop={2}
                            paddingBottom={3.5}
                            flex={1}
                            alignItems="flex-start"
                          >
                            {isSingleApplicationAvailable ? (
                              <Flex>
                                <Text as="span" variant="s2">
                                  {application.label}
                                </Text>
                                <Text as="span" variant="s2">
                                  {uniqueData.label}
                                </Text>
                              </Flex>
                            ) : (
                              <Radio
                                inputProps={{
                                  tabIndex: -1,
                                }}
                                isChecked={
                                  defaultApplication ===
                                    ApplicationEnum.MATHNATION_SUIT &&
                                  defaultSchool === uniqueData.value
                                }
                                spacing={4}
                              >
                                <Text as="span" variant="s2">
                                  {application.label}
                                </Text>
                                <Text as="span" variant="s2">
                                  {uniqueData.label}
                                </Text>
                              </Radio>
                            )}
                            <Flex px={isSingleApplicationAvailable ? 0 : 8}>
                              <Badge
                                variant={
                                  (badges as any)[application.application]
                                    ?.variant
                                }
                                size="xs"
                              >
                                {(badges as any)[application.application]?.name}
                              </Badge>
                            </Flex>
                          </VStack>
                        </Button>
                      </ListItem>
                    );
                  });
                } else {
                  if (
                    !excludedContentApplications.includes(
                      application.value as ApplicationEnum
                    ) &&
                    !checkSchoolHasContentGroups(application.value)
                  ) {
                    return null;
                  }

                  return (
                    <ListItem
                      key={
                        application.value + application.application + appIndex
                      }
                      ref={
                        value === application.value ? activeItemRef : undefined
                      }
                      p="0"
                      border="unset"
                      onClick={() => handleChange(application.value)}
                    >
                      <Button
                        variant="applicationButton"
                        width="full"
                        height="unset"
                        bgColor={
                          value === application.value
                            ? 'secondary.50'
                            : 'transparent'
                        }
                        borderRadius={isSingleApplicationAvailable ? '6px' : 0}
                      >
                        <VStack
                          px={6}
                          paddingTop={2}
                          paddingBottom={3.5}
                          flex={1}
                          alignItems="flex-start"
                        >
                          {isSingleApplicationAvailable ? (
                            <Text as="span" variant="s2">
                              {application?.label}
                            </Text>
                          ) : (
                            <Radio
                              inputProps={{
                                tabIndex: -1,
                              }}
                              isChecked={value === application.value}
                              spacing={4}
                            >
                              <Text as="span" variant="s2">
                                {application?.label}
                              </Text>
                            </Radio>
                          )}
                          <Flex
                            px={isSingleApplicationAvailable ? 0 : 8}
                            direction="column"
                            alignItems="flex-start"
                            gap={1}
                          >
                            <Badge
                              variant={
                                (badges as any)[application.application]
                                  ?.variant
                              }
                              size="xs"
                            >
                              {(badges as any)[application.application]?.name}
                            </Badge>
                          </Flex>
                        </VStack>
                      </Button>
                    </ListItem>
                  );
                }
              })}
            </List>
          )}
        </Flex>
      </Box>
    </>
  );
};

export default DefaultLocation;
