import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { enConfig } from '@lon/login/assets';

export const initTranslationService = () => {
  i18next.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    resources: {
      en: { translation: enConfig },
    },
  });
};
