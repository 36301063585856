import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import fetch from 'cross-fetch';

const httpLink = createHttpLink({
  uri:
    process.env['NX_GRAPHQL_API_URL'] ||
    `${window.location.origin}/api/graphql`,
  fetch: process.env.NODE_ENV === 'test' ? fetch : undefined,
});

const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export default apolloClient;
