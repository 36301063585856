import { Flex, Text } from '@chakra-ui/react';
import { AiFillCheckCircle } from '@react-icons/all-files/ai/AiFillCheckCircle';
import React from 'react';
import { types } from './duck';

const SuccessMessage: React.FC<types.SuccessMessageProps> = ({
  title,
  textPartOne,
  textPartTwo,
}) => {
  return (
    <Flex
      justifyContent="center"
      flexDir="column"
      alignItems="center"
      w="100%"
      h="auto"
      bg="primary.700"
      px={6}
      py={8}
      textAlign="center"
    >
      <AiFillCheckCircle fontSize="1.75rem" color="#97DD45" />
      <Text variant="s1" color="#97DD45" mt={2.5} mb={4}>
        {title}
      </Text>
      <Text variant="s2" color="white" mb={2}>
        {textPartOne}
      </Text>
      <Text variant="s2" color="white">
        {textPartTwo}
      </Text>
    </Flex>
  );
};

export default SuccessMessage;
