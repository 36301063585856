import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Asterisk, AsyncSelect } from '@lon/shared/components';
import {
  useGetPublicDistrictLazyQuery,
  useGetPublicDistrictsLazyQuery,
} from '@lon/shared/requests';
import { hooks, types } from './duck';

const SelectDistrict: React.FC<types.SelectDistrictProps> = ({
  lengthToFetch = 2,
  state,
  name,
  isDisabled,
  testId,
  variant,
  ...rest
}) => {
  const { state: routerState } = useLocation();
  const [search, setSearch] = React.useState('');
  const [fetch, { loading }] = useGetPublicDistrictsLazyQuery();
  const [fetchDistrict] = useGetPublicDistrictLazyQuery();
  const { t } = useTranslation();
  const formContext = useFormContext();

  useEffect(() => {
    if (routerState?.districtId) {
      fetchDistrict({
        variables: {
          id: routerState.districtId,
        },
      }).then((res) => {
        const district = res?.data?.publicDistrict;
        formContext.setValue(
          'district',
          {
            label: district?.globalName,
            value: district?.id,
          },
          { shouldDirty: true }
        );
      });
    }
  }, []);

  const loadOptions = hooks.useDebounce((value, callback) => {
    fetch({
      variables: {
        name: value.trim(),
        state,
        itemsPerPage: 999,
        page: 1,
      },
    }).then((res) => {
      const districts = res.data?.newDistricts?.collection?.filter(Boolean);
      const districtNames =
        districts?.map((district) => district?.globalName) || [];
      callback(
        districts?.map((district) => ({
          label:
            districtNames.filter((name) => name === district?.globalName)
              ?.length > 1
              ? `${district?.globalName} (${district?.city || 'none'})`
              : district?.globalName,
          value: district!.id || '',
        })) || []
      );
    });
  });

  return (
    <AsyncSelect
      {...rest}
      name={name}
      label={t('login.districtLabel')}
      menuIsOpen={search.trim().length >= lengthToFetch}
      isRequired
      isLoading={loading}
      isDisabled={isDisabled}
      formLabelProps={{
        color: 'white !important',
        requiredIndicator: <Asterisk color="var(--orange-5)" />,
      }}
      cacheOptions
      loadOptions={(value: any, callback: any) => {
        if (value.trim().length < lengthToFetch) {
          return callback([]);
        }
        loadOptions(value, callback);
      }}
      inputValue={search}
      showClearIndicator
      withoutDropdownIndicator
      placeholder={t('login.districtPlaceholder')}
      onInputChange={setSearch}
    />
  );
};

export default SelectDistrict;
