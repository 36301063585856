import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Asterisk, TextInput } from '@lon/shared/components';

const CheckCode = () => {
  const { t } = useTranslation();

  return (
    <Flex
      justifyContent="center"
      flexDir="column"
      alignItems="center"
      w="100%"
      h="100%"
      px={6}
      py={8}
      textAlign="center"
    >
      <TextInput
        variant="dark"
        name="mfaCode"
        placeholder={t('mfa.codePlaceholder')}
        label={
          <Text as="span" variant="n1" color="white">
            {t('mfa.codeLabel')}
          </Text>
        }
        formLabelProps={{
          requiredIndicator: <Asterisk color="var(--orange-5)" />,
        }}
        isRequired
      />
    </Flex>
  );
};

export default CheckCode;
