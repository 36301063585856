import { Flex } from '@chakra-ui/react';
import React from 'react';
import { types } from './duck';

const ContentWrapper: React.FC<types.ContentWrapperProps> = ({
  children,
  alignItems,
  ...props
}) => {
  return (
    <Flex
      justifyContent="center"
      alignItems={alignItems}
      w="100%"
      h={{ base: 'auto' }}
      position="relative"
      gap={8}
      flexGrow={1}
      flexDirection="column"
      {...props}
    >
      {children}
    </Flex>
  );
};

export default ContentWrapper;
