import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import qrCodeReducer from './qr-code';

const persistConfig = {
  key: 'login',
  storage,
  whitelist: ['filters'],
  blacklist: ['auth'],
};

const rootReducer = combineReducers({
  qrCode: qrCodeReducer,
});

export default persistReducer(persistConfig, rootReducer);
