import {
  Box,
  Flex,
  HStack,
  Image,
  Spinner,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  ContentGroup,
  useGetActiveContentGroupsQuery,
  useGetSystemSettingsQuery,
} from '@lon/shared/requests';
import { Systems, getURL, systems } from './duck';
import { Links } from './components';

const SystemSelect: React.FC<{ isLti?: boolean }> = ({ isLti }) => {
  const { t } = useTranslation();
  const { data: systemsData, loading } = useGetSystemSettingsQuery({});
  const { data: contentGroupsData, loading: contentGroupsLoading } =
    useGetActiveContentGroupsQuery({
      onCompleted: (data) => {
        const suites = processContentGroups(
          data?.activeContentGroups as ContentGroup[]
        );
        if (suites?.length === 1) {
          window.location.href = getURL(
            suites?.[0] as Systems,
            !!systemsData?.aclApplications?.find(
              ({ name }) => name === 'Admin'
            ),
            isLti
          );
        }
      },
      skip: !systemsData,
    });

  const processContentGroups = (contentGroupsData: Array<ContentGroup>) => {
    const ids = contentGroupsData?.map((group) => group.id);

    if (!ids?.length) {
      return [];
    }

    const allMN = ids?.every((id) => id?.startsWith('MN'));
    const noneMN = ids?.every((id) => !id?.startsWith('MN'));

    if (allMN) {
      return [Systems.math];
    } else if (noneMN) {
      return [Systems.new];
    } else {
      return [Systems.new, Systems.math];
    }
  };

  const isAdmin = useMemo(() => {
    return !!systemsData?.aclApplications?.find(({ name }) => name === 'Admin');
  }, [systemsData?.aclApplications]);

  return loading || contentGroupsLoading ? (
    <Flex
      alignItems="center"
      justifyContent="center"
      position="relative"
      w="full"
      h="full"
    >
      <Spinner />
    </Flex>
  ) : (
    <Flex
      direction="column"
      as="main"
      alignItems="center"
      justifyContent="center"
      position="relative"
      w="full"
      h="full"
      gap={6}
      px={6}
    >
      <Image
        position="absolute"
        top="0"
        bottom="0"
        right="0"
        left="0"
        objectFit={{ base: 'fill', md: 'cover' }}
        h="100%"
        w="100%"
        src="login/assets/main-background.webp"
        alt=""
        role="presentation"
      />
      <Box
        zIndex={1}
        w={{ base: 'full', sm: 'auto' }}
        maxW={{ base: 'auto', md: '690px', lg: '800px' }}
        color="primary.800"
        background="linear-gradient(180deg, rgba(248, 249, 252, 0.78) 0%, rgba(248, 249, 252, 0.47) 100%)"
        boxShadow="0px 3px 20px 15px #2B364633"
        borderRadius="30px"
        p="15px"
      >
        <Box
          w="full"
          h="full"
          borderRadius="20px"
          background="linear-gradient(180deg, #FFFFFF 57.73%, rgba(255, 255, 255, 0.72) 100%)"
        >
          {systemsData?.applicationSettings?.value && (
            <Flex
              px={8}
              minH="76px"
              justify="center"
              align="center"
              borderBottomWidth="1px"
              borderColor="secondary.200"
            >
              <Text variant="h4" textAlign="center">
                {t('systemSelect.welcome', {
                  name: systemsData?.applicationSettings?.value?.firstName,
                  lastName: systemsData?.applicationSettings?.value?.lastName,
                })}
              </Text>
            </Flex>
          )}
          <Flex
            direction="column"
            align="center"
            pt={{ base: 8, lg: 16 }}
            pb={{ base: 6, lg: 10 }}
            px={8}
          >
            <Image
              height={{ base: '55px', lg: '65px' }}
              width={{ base: '55px', lg: '65px' }}
              src="login/assets/acceleratelearning-icon.svg"
              alt=""
              mb={4}
            />
            <Image
              height={{ base: '38px', lg: '44px' }}
              width={{ base: '250px', lg: '290px' }}
              src="login/assets/acceleratelearning-login.svg"
              alt=""
              mb={2}
            />
            {processContentGroups(
              contentGroupsData?.activeContentGroups as ContentGroup[]
            )?.length ? (
              <Text variant="n3" textTransform="uppercase">
                {t('systemSelect.selectApp')}
              </Text>
            ) : (
              <></>
            )}
          </Flex>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            gap={6}
            justify="center"
            align="center"
            px={8}
            pb={16}
          >
            {!processContentGroups(
              contentGroupsData?.activeContentGroups as ContentGroup[]
            )?.length && (
              <Text color="danger.500">{t('systemSelect.error')}</Text>
            )}
            {processContentGroups(
              contentGroupsData?.activeContentGroups as ContentGroup[]
            )?.map((system: string) => (
              <Tooltip
                label={t('systemSelect.open', {
                  name: t(systems[system as Systems].name),
                })}
                variant="dark"
                openDelay={400}
              >
                <Flex
                  as={Link}
                  to={getURL(system as Systems, isAdmin, isLti)}
                  _hover={{
                    backgroundColor: 'info.100',
                  }}
                  boxSizing="border-box"
                  _focusVisible={{
                    borderWidth: '1px',
                    outline: 'none',
                    boxShadow: '0px 0px 0px 4px #97E2FF',
                    borderColor: 'primary.800',
                  }}
                  reloadDocument
                  backgroundColor="white"
                  cursor="pointer"
                  key={system}
                  w={{ base: '230px', md: '182px', lg: '220px' }}
                  h={{ base: '111px', md: '140px', lg: '181px' }}
                  direction="column"
                  borderRadius="20px"
                  boxShadow="0px 5px 20px -2px #2B364612"
                >
                  <Flex
                    justify="center"
                    align="center"
                    w="full"
                    h={{ base: '69px', md: '98px', lg: '123px' }}
                    px={{ base: 6, lg: 8 }}
                    pt={{ base: 6, lg: 8 }}
                    pb={{ base: 4, lg: 6 }}
                    borderBottomWidth="1px"
                    borderColor="secondary.200"
                  >
                    <Image
                      src={systems[system as Systems].logo}
                      w={{ base: '120px', md: '150px' }}
                      h={{ base: '38px', md: '50px' }}
                    />
                  </Flex>
                  <HStack
                    w="full"
                    h={{ base: '42px', lg: '58px' }}
                    justify="center"
                    align="center"
                  >
                    <Text variant="s2">
                      {t(systems[system as Systems].name)}
                    </Text>
                  </HStack>
                </Flex>
              </Tooltip>
            ))}
          </Flex>
        </Box>
      </Box>
      <Links />
    </Flex>
  );
};

export default SystemSelect;
