import { TFunction } from 'react-i18next';

export const skipLinks = (t: TFunction) => [
  {
    title: t('login.skipToNavigation'),
    href: '#navigation',
  },
  {
    title: t('login.skipToLogin'),
    href: '#login',
  },
  {
    title: t('login.skipToFooter'),
    href: '#footer',
  },
];
