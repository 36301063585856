import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import reducers from './reducers';

export * from './qr-code';
export * from './types';

const store = createStore(reducers, composeWithDevTools());
const persistor = persistStore(store);

export { store, persistor };
export default store;
