import { useCurrentRouter } from '../routers';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '@lon/login/configs';

const Root: React.FC = () => {
  const router = useCurrentRouter();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HelmetProvider>
          <React.Suspense fallback={null}>
            <RouterProvider router={router} />
          </React.Suspense>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  );
};

export default Root;
