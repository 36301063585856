import { Flex } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useToast } from '@lon/shared/components';
import { useQueryParams } from '@lon/shared/hooks';
import { useApplicationSettingsLazyQuery } from '@lon/shared/requests';
import { AuthError } from '@lon/shared/types';
import { loginUtils } from './duck';
import { LoginForm, State } from './components';

export const Login = () => {
  const [isMFARequired, setIsMFARequired] = React.useState(false);
  const { validationSchema, defaultValues } =
    loginUtils.getValidation(isMFARequired);
  const [searchParams] = useSearchParams({});
  const [params] = useQueryParams();
  const stateSearchParam = searchParams.get('state');
  const [getApplicationSettings] = useApplicationSettingsLazyQuery();

  const formProviderProps = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
    mode: 'onSubmit',
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [authError, setAuthError] = React.useState<AuthError | null>(null);
  const toast = useToast();
  const { state } = useLocation();
  const { redirectTo } = params;

  return (
    <FormProvider {...formProviderProps}>
      <Flex
        as="form"
        direction="column"
        data-testid="login-form"
        noValidate
        w="100%"
        role="tabpanel"
        id="tab-0-panel"
        aria-labelledby="tab-0"
        onSubmit={formProviderProps.handleSubmit(
          loginUtils.handleLoginSubmit({
            toast,
            setIsLoading,
            setAuthError,
            setIsMFARequired,
            isMFARequired,
            isAdminFirstLogin: state?.districtActivation,
            stateValue: stateSearchParam?.toUpperCase(),
            getApplicationSettings,
          }),
          (errors, event) => {
            console.log(errors);
          }
        )}
      >
        {stateSearchParam || redirectTo ? (
          <LoginForm
            authError={authError}
            setAuthError={setAuthError}
            isAuthorisationRequired={isMFARequired}
            setIsAuthorisationRequired={setIsMFARequired}
            isLoading={isLoading}
          />
        ) : (
          <State errorTitle={state?.errorTitle} errorBody={state?.errorBody} />
        )}
      </Flex>
    </FormProvider>
  );
};
