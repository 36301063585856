import { initTranslationService } from '../i18n';
import { MockedProvider } from '@apollo/client/testing';
import { render } from '@testing-library/react';
import { createMemoryHistory } from 'history';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '@lon/login/configs';
import { types } from './duck';

initTranslationService();

export const Wrapper: React.FC<types.WrapperProps> = ({
  children,
  mocks,
  history,
}: types.WrapperProps) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HelmetProvider>
        <Router location={history.location} navigator={history}>
          <MockedProvider mocks={mocks} addTypename={false}>
            {children}
          </MockedProvider>
        </Router>
      </HelmetProvider>
    </PersistGate>
  </Provider>
);

export const renderWithProvider = (
  ui: JSX.Element,
  options?: types.Options
) => {
  const { route = '/' } = options || {};
  const history = createMemoryHistory({ initialEntries: [route] });

  return {
    history,
    ...(render(ui, {
      wrapper: (props) => (
        <Wrapper {...props} mocks={options?.mocks} history={history} />
      ),
      ...options,
    }) || {}),
  };
};

export * from '@testing-library/react';

export { renderWithProvider as render };
