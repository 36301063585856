import { Flex, Image } from '@chakra-ui/react';
import React from 'react';
import { AsyncOutlet } from '@lon/shared/components';
import styles from './Main.module.css';

const Main: React.FC = () => {
  return (
    <Flex
      as="main"
      alignItems="center"
      justifyContent="center"
      position="relative"
      className={styles.wrapper}
    >
      <Image
        position="absolute"
        top="0"
        bottom="0"
        right="0"
        left="0"
        h="100%"
        w="100%"
        src="login/assets/main-background.webp"
        alt=""
        role="presentation"
      />
      <AsyncOutlet />
    </Flex>
  );
};

export default Main;
