import { Flex, Text } from '@chakra-ui/react';
import { AiFillCloseCircle } from '@react-icons/all-files/ai/AiFillCloseCircle';
import React from 'react';
import { types } from './duck';

const ErrorMessage: React.FC<types.ErrorMessageProps> = ({
  errorTitle,
  errorBody,
  bodyWidth,
}) => {
  return (
    <Flex
      justifyContent="center"
      flexDir="column"
      alignItems="center"
      w="100%"
      h="auto"
      bg="primary.700"
      px={6}
      py={8}
      textAlign="center"
      flexGrow={1}
    >
      <AiFillCloseCircle fontSize="1.75rem" color="#FFC2C7" />
      <Text variant="s1" color="#FFC2C7" mt={2} mb={4}>
        {errorTitle}
      </Text>
      <Text
        variant="s2"
        color="white"
        mx={{ base: 7, md: '90px' }}
        maxW={bodyWidth ? bodyWidth : 'unset'}
      >
        {errorBody}
      </Text>
    </Flex>
  );
};

export default ErrorMessage;
