import CheckCode from '../check-code';
import { Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lon/shared/components';
import { Props } from './duck/types';

const InfoMessage: FC<Props> = ({ step }) => {
  const { t } = useTranslation();

  return step === 1 ? (
    <Flex
      justifyContent="center"
      flexDir="column"
      alignItems="center"
      w="100%"
      h="100%"
      bg="primary.700"
      px={6}
      py={8}
      textAlign="center"
    >
      <Icon name="check-contained" />
      <Text variant="n3" mt={2} color="#97DD45">
        {t('mfa.title')}
      </Text>
      <Text variant="n3" color="white" maxW="552px" mt={4}>
        {t('mfa.subtitle')}
      </Text>
    </Flex>
  ) : step === 2 ? (
    <CheckCode />
  ) : null;
};

export default InfoMessage;
