//@ts-ignore
import chmln from '@chamaeleonidae/chmln';
import { useEffect, useRef } from 'react';
import { chamToken } from './constants';

export const useChameleon = () => {
  const chameleonRef = useRef(false);

  useEffect(() => {
    if (!chameleonRef.current) {
      chameleonRef.current = true;

      chmln.init(chamToken, { fastUrl: 'https://fast.chameleon.io/' });

      //Unknown params, as the user has not logged in yet.
      chmln.identify('xxx-unknown', {
        email: 'GuestEmail@123.com',
        name: 'Guest User',
        role: 'Guest',
      });
    }
  }, []);
};
