import { Box, Flex, Link } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UnauthorizedFooter, UnauthorizedHeader } from '@lon/shared/components';
import { useGoogleAnalytics } from '@lon/shared/hooks';
import { skipLinks } from './duck';
import { useChameleon } from './duck/hooks';
import { Main } from './components';

const App: React.FC = () => {
  const { t } = useTranslation();
  useGoogleAnalytics('login');
  useChameleon();

  return (
    <Flex
      direction="column"
      minH="full"
      minW="var(--page-width-min)"
      bgColor="white"
      position="relative"
    >
      <Box>
        {skipLinks(t).map((link: { href: string; title: string }) => (
          <Link
            key={link.title}
            href={link.href}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = `${window.location.pathname}${window.location.search}${link.href}`;
            }}
            position="absolute"
            left="5"
            top="-10"
            backgroundColor="white"
            p={2}
            transition="all 0.3s ease-in-out"
            _focus={{ top: 1 }}
          >
            {link.title}
          </Link>
        ))}
      </Box>
      <UnauthorizedHeader />
      <Main />
      <UnauthorizedFooter />
    </Flex>
  );
};

export default App;
