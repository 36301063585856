import { routes } from '@lon/login/configs';
import { Systems } from './types';

export const systems = {
  [Systems.new]: {
    logo: 'login/assets/stemscopes-logo.svg',
    name: 'systemSelect.new',
    link: '/suite',
  },
  [Systems.old]: {
    logo: 'login/assets/stemscopes-logo.svg',
    name: 'systemSelect.old',
    link: routes.login.stemOld,
  },
  [Systems.math]: {
    logo: 'login/assets/mathnation-logo.svg',
    name: 'systemSelect.math',
    link: '/mathnation',
  },
};
