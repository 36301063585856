import ErrorMessage from '../../../messages/error-message';
import ContentWrapper from '../content-wrapper';
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from '@lon/login/configs';
import { FormSelect } from '@lon/shared/components';
import { stateList } from '@lon/shared/utils';
import { types } from './duck';

const State: React.FC<types.StateProps> = ({ errorBody, errorTitle }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const state = useWatch({ name: 'state' });
  const [searchParams, setSearchParams] = useSearchParams();

  const stateFullName = stateList()?.find((v) => v.value === state)?.label;

  const handleRedirect = () => {
    if (state) {
      errorTitle
        ? navigate(routes.login.forgotPassword)
        : setSearchParams({ ...searchParams, state: state.toLowerCase() });
    }
  };

  useEffect(handleRedirect, [state]);
  return (
    <>
      <Helmet>
        <title>{t('pageTitles.login')}</title>
      </Helmet>
      <ContentWrapper alignItems="center" flexDirection="column" gap={0}>
        {!errorTitle ? (
          <>
            <Text
              variant="h6"
              fontWeight={400}
              color="white"
              textTransform="uppercase"
              id="state-caption"
            >
              {t('login.stateLabel')}
            </Text>
            <Box
              position="absolute"
              top="40%"
              bg="white"
              zIndex={10}
              w="240px"
              borderWidth="1px"
              borderColor="white"
              borderRadius="4px"
            >
              <FormSelect
                maxMenuHeight={235}
                isRequired
                name="state"
                testId="state"
                placeholder={t('login.statePlaceholder')}
                options={stateList()}
                variant="dark"
                returnObject={false}
                filterOption={(input, option) =>
                  input?.label?.toLowerCase()?.startsWith(option?.toLowerCase())
                }
                aria-label={
                  stateFullName
                    ? `${t('login.stateLabel')} ${t('login.selectedState', {
                        entity: stateFullName,
                      })} `
                    : t('login.stateLabel')
                }
              />
            </Box>
            <Image
              height="264px"
              top={7}
              width="448px"
              src="login/assets/usa-map.svg"
              alt=""
              role="presentation"
            />
          </>
        ) : (
          <Flex w="100%" height="100%" direction="column" gap={8}>
            <ErrorMessage errorTitle={errorTitle} errorBody={errorBody} />
            <Flex
              direction={{ base: 'column', md: 'row' }}
              justify="end"
              w="full"
              gap={6}
              onClick={() => navigate(routes.login.forgotPassword)}
            >
              <Button type="submit" size="md" variant="solidDark">
                {t('login.requestNewLink')}
              </Button>
            </Flex>
          </Flex>
        )}
      </ContentWrapper>
    </>
  );
};

export default State;
