import { Flex, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { prodStemDomain } from '@lon/login/constants';
import { isProdCluster, stemDomain } from '@lon/shared/constants';

const Links = () => {
  const { t } = useTranslation();

  return (
    <Flex
      zIndex={1}
      color="white"
      gap={{ base: 2, md: 6 }}
      direction={{ base: 'column', md: 'row' }}
      align="center"
    >
      <Flex gap={6} direction="row">
        <Text
          variant="su4"
          aria-hidden="true"
          fontSize="sm"
          lineHeight="short"
          fontWeight={500}
          textDecoration="none"
        >
          &copy; 2024{' '}
        </Text>
        <Link
          href={isProdCluster() ? prodStemDomain : `${stemDomain}/home.php`}
          isExternal
          aria-label={t('systemSelect.link')}
          textDecoration="underline"
          fontSize="sm"
          lineHeight="short"
          fontWeight={500}
        >
          {t('systemSelect.link')}
        </Link>
      </Flex>
      <Flex
        gap={{ base: 2, sm: 6 }}
        direction={{ base: 'column', sm: 'row' }}
        align="center"
      >
        <Link
          href={
            isProdCluster() ? `${prodStemDomain}/terms` : `${stemDomain}/terms`
          }
          isExternal
          textDecoration="underline"
          fontSize="sm"
          lineHeight="short"
          fontWeight={500}
          aria-label={t('systemSelect.terms')}
        >
          {t('systemSelect.terms')}
        </Link>
        <Link
          href="#"
          textDecoration="underline"
          fontSize="sm"
          lineHeight="short"
          fontWeight={500}
          aria-label={t('systemSelect.support')}
        >
          {t('systemSelect.support')}
        </Link>
      </Flex>
    </Flex>
  );
};

export default Links;
