import axios from 'axios';
import { t } from 'i18next';
import { object, string } from 'yup';
import { ERROR_MESSAGES } from '@lon/shared/constants';
import { AuthError } from '@lon/shared/types';
import {
  parseJSON,
  redirect,
  redirectToPriorityApplication,
} from '@lon/shared/utils';
import { ErrorResponse, SubmitLogin, SuccessResponse } from './types';

const getSchema = (isMFARequired?: boolean) =>
  object({
    district: object()
      .shape({
        value: string(),
        label: string(),
      })
      .nullable()
      .required(
        t('validationMessages.requiredField', {
          fieldName: 'District',
        })
      )
      .default(null),
    username: string()
      .trim()
      .required(
        t('validationMessages.requiredField', {
          fieldName: 'Username',
        })
      )
      .default(''),
    password: string()
      .trim()
      .required(
        t('validationMessages.requiredField', {
          fieldName: 'Password',
        })
      )
      .default(''),
    ...(isMFARequired && {
      mfaCode: string()
        .trim()
        .required(
          t('validationMessages.requiredField', {
            fieldName: 'Verification Code',
          })
        ),
    }),
  });

export const getValidation = (isMFARequired?: boolean) => ({
  validationSchema: getSchema(isMFARequired),
  defaultValues: getSchema(isMFARequired).cast({}),
});

export const handleLoginSubmit: SubmitLogin = ({
  toast,
  setIsLoading,
  setAuthError,
  setRegistrationRequired,
  setIsMFARequired,
  stateValue: state,
  isMFARequired,
  isAdminFirstLogin,
  preview,
  getApplicationSettings,
}) => {
  const params = new URLSearchParams();

  return async (values) => {
    setIsLoading(true);

    Object.entries(values).forEach(([key, value]) => {
      if (key === 'district') {
        params.append('district', values?.district?.value);
        localStorage.setItem('district', JSON.stringify(value));
      }
      if (key === 'mfaCode' && values.mfaCode) {
        params.append('code', values.mfaCode);
      }
      if (key === 'password' || key === 'username')
        params.append(key, value as string);
    });

    params.append('application', 'STEMscopes');

    try {
      const {
        data: { data },
      } = await axios.post<SuccessResponse>(
        `${process.env.NX_REACT_APP_LOGIN_API}${isMFARequired ? '/mfa' : ''}`,
        params
      );

      localStorage.setItem(
        'availableSuites',
        JSON.stringify(data?.applicationsNames)
      );

      localStorage.removeItem('impersonate');

      if (isAdminFirstLogin) {
        redirect('/admin/wizard/welcome');
      } else {
        const applicationSettings =
          getApplicationSettings &&
          (await getApplicationSettings({
            fetchPolicy: 'no-cache',
          }));

        const preferences = parseJSON(
          applicationSettings?.data?.applicationSettings?.value?.preferences
        ) as any;
        const defaultApplication = preferences?.defaultApplication;

        redirectToPriorityApplication(
          (data?.user?.userId as any).value,
          defaultApplication
        );
      }

      setIsLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const backendError = error as ErrorResponse;
        if (error.response.status < 500 && error.response.status !== 0) {
          const { code = 'GENERAL_ERROR' } =
            backendError?.response?.data.error || {};
          const message = ERROR_MESSAGES[code];
          if (
            backendError?.response?.data?.error?.code ===
            'PREVIEW_ACCOUNT_SELF_REGISTRATION_REQUIRED'
          ) {
            if (typeof setRegistrationRequired === 'function') {
              setRegistrationRequired({
                isRequired: true,
                token: `${backendError.response.data.error.parameters?.previewAccountRegistrationToken}`,
              });
            }
          } else if (
            backendError?.response?.data?.error?.code === 'INVALID_MFA_CODE'
          ) {
            if (typeof setAuthError === 'function') {
              setAuthError(AuthError.InvalidCode);
            }
          } else if (
            backendError?.response?.data?.error?.code === 'MFA_CODE_EXPIRED'
          ) {
            if (typeof setAuthError === 'function') {
              setAuthError(AuthError.ExpiredCode);
            }
          } else if (
            backendError?.response?.data?.error?.code === 'MFA_CODE_REQUIRED'
          ) {
            if (typeof setIsMFARequired === 'function') {
              setIsMFARequired(true);
            }
          } else if (
            backendError?.response?.data?.error?.code === 'LOGIN_LOCKED'
          ) {
            sessionStorage.setItem(
              'lockedAccountCountdown',
              // String(backendError?.response?.data?.error?.parameters.countdown)
              '300'
            );
            setAuthError(AuthError.Locked);
          } else {
            toast({
              title: message?.trim(),
              variant: 'error-light',
              isClosable: true,
            });
          }
          setIsLoading(false);
          return;
        }
        setIsLoading(false);
      }
      setIsLoading(false);
      toast({
        title: ERROR_MESSAGES.GENERAL_ERROR,
        variant: 'error-light',
        isClosable: true,
      });
    }
  };
};
